export const transformToNull = <T>(val?: T): T | null => (val == undefined ? null : val);
export const emptyToNull = <T extends string>(val: T | null): T | null => (val === '' ? null : val);

export const nullToEmptyString = <T extends string>(val: T | string): T | string => (val === null ? '' : val);

export const commaToDot = <T extends number>(_: T | null, val: T): T | null => {
  const valAsString = val?.toString();
  return valAsString?.match(/,/g) ? (parseFloat(valAsString.replace(',', '.')) as T) : _;
};

export const emptyStringToUndefined = (
  value: string | number,
  originalValue: string | number,
): undefined | number | string => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return undefined;
  }
  return value;
};
