/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NotificationResponse } from '../models/NotificationResponse';
import type { UpdateNotificationRequest } from '../models/UpdateNotificationRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class NotificationSettingsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns NotificationResponse
   * @throws ApiError
   */
  public getNotificationList(): CancelablePromise<Array<NotificationResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/notification-settings',
    });
  }

  /**
   * @returns NotificationResponse
   * @throws ApiError
   */
  public getNotification({ id }: { id: number }): CancelablePromise<NotificationResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/notification-settings/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns NotificationResponse
   * @throws ApiError
   */
  public updateNotification({
    id,
    requestBody,
  }: {
    id: number;
    requestBody: UpdateNotificationRequest;
  }): CancelablePromise<NotificationResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/notification-settings/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
