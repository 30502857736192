import { string, object, SchemaOf, bool } from 'yup';
import { emptyToNull } from 'src/utils/schemaUtils';
import { inputRequired } from 'src/validation/validationMessages';
import { CreateContactRequest } from 'src/api';

export type CreateContactFormValues = CreateContactRequest;

const CreateContactFormSchema: SchemaOf<CreateContactFormValues> = object().shape({
  vat: string().nullable(true).defined().default(null).transform(emptyToNull),
  companyName: string().required(inputRequired),
  email: string().email().required(inputRequired),
  phoneNumber: string().required(inputRequired),
  city: string().required(inputRequired),
  street: string().required(inputRequired),
  zip: string().required(inputRequired),
  in: string().required(inputRequired),
  notification: bool().defined().default(false),
});
export default CreateContactFormSchema;
