import React, { ReactNode, useState } from 'react';
import SortUpIcon from 'src/components/Icons/SortUpIcon';
import SortDownIcon from 'src/components/Icons/SortDownIcon';
import { SortType } from '../ContactDetailPage';
import { clone } from 'ramda';
import SortingTextfield from 'src/components/FilterTextfield';
import { Controller, useForm } from 'react-hook-form';
import { noop } from 'src/utils/commonUtils';
import { ContactServiceListsResponse } from 'src/api';

type SortSectionType = {
  sortItems: ContactServiceListsResponse[];
  handleSort: (sortItems: ContactServiceListsResponse[], sortObj: SortType) => void;
  path: string[];
  type: 'string' | 'number';
  children?: ReactNode;
};

type Direction = 'asc' | 'desc';

const SortSection: React.FC<SortSectionType> = ({ sortItems, handleSort, path, type, children }) => {
  const [direction, setDirection] = useState<Direction>('asc');
  return (
    <button
      onClick={() => {
        setDirection(direction === 'asc' ? 'desc' : 'asc');
        handleSort(clone(sortItems), { path, direction, type });
      }}
    >
      <div className='flex'>
        {children}
        {direction === 'asc' ? (
          <div className='pt-1'>
            <SortUpIcon />
          </div>
        ) : (
          <div className='pt-1'>
            <SortDownIcon />
          </div>
        )}
      </div>
    </button>
  );
};

type ServiceHeaderProps = {
  sortItems: ContactServiceListsResponse[];
  handleSort: (sortHistory: ContactServiceListsResponse[], sortObj: SortType) => void;
  isFilterOpen: boolean;
  handleFilter: (queryString: ServiceHeaderFilter) => void;
};

export type ServiceHeaderFilter = {
  datetime: string;
  branchName: string;
  employeeName: string;
  productName: string;
};

const serviceHeaderFilterDefaultValues = {
  datetime: '',
  branchName: '',
  employeeName: '',
  productName: '',
};
const HistoryTableHeader: React.FC<ServiceHeaderProps> = ({ sortItems, handleSort, isFilterOpen, handleFilter }) => {
  const { control, handleSubmit, getValues, reset } = useForm<ServiceHeaderFilter>({
    defaultValues: serviceHeaderFilterDefaultValues,
  });

  const formHandleBlur = (values: ServiceHeaderFilter): void => {
    handleFilter(values);
  };

  React.useEffect(() => {
    reset();
    handleFilter(serviceHeaderFilterDefaultValues);
  }, [isFilterOpen]);
  return (
    <form onSubmit={handleSubmit(noop)}>
      <div className='flex'>
        <div
          className={`border-l border-t border-b border-border w-3/5 text-center bg-white ${
            isFilterOpen ? 'h-20' : 'h-10'
          } rounded-tl-lg  pl-4 pt-1`}
        >
          <div className='mr-8 flex'>
            <div className='pt-1'>
              <div className='flex ntb:ml-2 lg:mr-2'>
                <SortSection sortItems={sortItems} handleSort={handleSort} path={['datetime']} type='string'>
                  Datum
                </SortSection>
              </div>
              {isFilterOpen && (
                <Controller
                  name='datetime'
                  control={control}
                  render={({ field }) => {
                    const onBlur = (): void => {
                      field.onBlur();
                      formHandleBlur(getValues());
                    };
                    const handleKeyDown = (event: { key: string }): void => {
                      if (event.key === 'Enter') {
                        formHandleBlur(getValues());
                      }
                    };
                    return <SortingTextfield {...field} onBlur={onBlur} onKeyDown={handleKeyDown} />;
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <div
          className={`border-t border-b border-border w-full text-left bg-white flex-col ${
            isFilterOpen ? 'h-20' : 'h-10'
          } pt-2  pl-2`}
        >
          <SortSection sortItems={sortItems} handleSort={handleSort} path={['branch']} type='string'>
            Pobočky
          </SortSection>
          {isFilterOpen && (
            <Controller
              name='branchName'
              control={control}
              render={({ field }) => {
                const onBlur = (): void => {
                  field.onBlur();
                  formHandleBlur(getValues());
                };
                const handleKeyDown = (event: { key: string }): void => {
                  if (event.key === 'Enter') {
                    formHandleBlur(getValues());
                  }
                };
                return <SortingTextfield {...field} onBlur={onBlur} onKeyDown={handleKeyDown} />;
              }}
            />
          )}
        </div>

        <div
          className={`border-t border-b  pt-2 border-border w-full text-left bg-white ${isFilterOpen ? 'h-20' : 'h-10'}
        pl-2 flex-col `}
        >
          <SortSection sortItems={sortItems} handleSort={handleSort} path={['employee']} type='string'>
            Osoba
          </SortSection>
          {isFilterOpen && (
            <Controller
              name='employeeName'
              control={control}
              render={({ field }) => {
                const onBlur = (): void => {
                  field.onBlur();
                  formHandleBlur(getValues());
                };
                const handleKeyDown = (event: { key: string }): void => {
                  if (event.key === 'Enter') {
                    formHandleBlur(getValues());
                  }
                };
                return <SortingTextfield {...field} onBlur={onBlur} onKeyDown={handleKeyDown} />;
              }}
            />
          )}
        </div>

        <div
          className={`border-t border-b  border-border w-3/5 text-left bg-white  ${
            isFilterOpen ? 'h-20' : 'h-10'
          } flex-col pt-2`}
        >
          <SortSection sortItems={sortItems} handleSort={handleSort} path={['product']} type='string'>
            Produkt
          </SortSection>
          {isFilterOpen && (
            <Controller
              name='productName'
              control={control}
              render={({ field }) => {
                const onBlur = (): void => {
                  field.onBlur();
                  formHandleBlur(getValues());
                };
                const handleKeyDown = (event: { key: string }): void => {
                  if (event.key === 'Enter') {
                    formHandleBlur(getValues());
                  }
                };
                return <SortingTextfield {...field} onBlur={onBlur} onKeyDown={handleKeyDown} />;
              }}
            />
          )}
        </div>

        <div
          className={`border-t border-b border-r flex justify-center rounded-tr-lg border-border w-9/12 bg-white  ${
            isFilterOpen ? 'h-20' : 'h-10'
          }  `}
        >
          <SortSection sortItems={sortItems} handleSort={handleSort} path={['serviceList']} type='string'>
            Servisní list
          </SortSection>
        </div>
      </div>
    </form>
  );
};

export default HistoryTableHeader;
