import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Route, Routes } from 'react-router-dom';
import { useOldApiClient } from 'src/api';
import MaterialForm from 'src/pages/MaterialPage/MaterialForm';
import { MaterialResponse } from 'src/api/types/material/MaterialResponse';
import MaterialPreview from 'src/pages/MaterialPage/MaterialPreview';

const MaterialFetch: React.FC = () => {
  const { materialId } = useParams();
  const Client = useOldApiClient();
  const [dataInForm, setDataInForm] = useState<MaterialResponse & { id: number }>();

  const getDataToForm = async (): Promise<void> => {
    if (materialId) {
      const response = await Client.get(`/material/${materialId}`);
      setDataInForm(response.data);
    }
  };

  useEffect(() => {
    getDataToForm();
  }, [materialId]);

  return (
    <>
      <Routes>
        <Route path='/preview' element={<MaterialPreview data={dataInForm} />} />
        <Route
          path='/edit'
          element={
            dataInForm ? (
              <MaterialForm
                materialId={dataInForm.id}
                defaultValues={{
                  materialName: dataInForm.materialName,
                  materialValue: dataInForm.materialValue,
                  materialCode: dataInForm.materialCode,
                }}
              />
            ) : null
          }
        />
      </Routes>
    </>
  );
};

export default MaterialFetch;
