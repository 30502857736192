import React, { ReactNode } from 'react';

interface ServiceFormDataRowProps {
  label: string;
  children?: ReactNode;
}

const ServiceFormDataRow: React.FC<ServiceFormDataRowProps> = ({ children, label }) => (
  <div className='grid grid-cols-2 mb-4'>
    <div className='font-bold text-text ml-4 mr-12'>{label}</div>
    {children}
  </div>
);

export default ServiceFormDataRow;
