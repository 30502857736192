import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ContactResponse } from 'src/api/types/contact/ContactResponse';
import EditButton from 'src/components/EditButton';
import HorizontalRow from 'src/components/HorizontalRow';
import RowButton from '../../../components/RowButton';
import ContactFormCard from '../ContactFormCard';

interface PreviewContactFormProps {
  data?: ContactResponse & { id: number };
}
const PreviewContactForm: React.FC<PreviewContactFormProps> = ({ data }) => {
  const { contactId } = useParams();
  const navigate = useNavigate();

  return (
    <div className='flex-col w-2/4 md:w-full md:pl-3 ml-4'>
      <div className='flex w-11/12'>
        <div className='grid grid-row-2 mb-4 w-full'>
          <div className='flex w-full'>
            <div className='mt-2 text-4xl text-text font-bold flex w-full'>Zákazník</div>

            <div className='flex flex-row items-center pt-2'>
              <EditButton onClick={() => navigate('/contact')}>Zpět</EditButton>
              <RowButton onClick={() => navigate(`/contact/${contactId}/edit`)}>Upravit</RowButton>
            </div>
          </div>
        </div>
      </div>
      <form className='w-11/12'>
        <ContactFormCard>
          <div className='grid grid-cols-2 mb-4 mt-2'>
            <div className='font-bold text-text ml-4 mr-1'>Název společnosti</div>
            <div className='text-lightGrey'>{data?.companyName}</div>
          </div>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4 mt-2'>
            <div className='font-bold text-text ml-4 mr-1'>IČO</div>
            <div className='text-lightGrey'>{data?.in}</div>
          </div>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4 mt-2'>
            <div className='font-bold text-text ml-4 mr-1'>DIČ</div>
            <div className='text-lightGrey'>{data?.vat}</div>
          </div>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4 mt-2'>
            <div className='font-bold text-text ml-4 mr-1'>Email</div>
            <div className='text-lightGrey'>{data?.email}</div>
          </div>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4 mt-2'>
            <div className='font-bold text-text ml-4 mr-1'>Telefonní číslo</div>
            <div className='text-lightGrey'>{data?.phoneNumber}</div>
          </div>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4 mt-2'>
            <div className='font-bold text-text ml-4 mr-1'>Město</div>
            <div className='text-lightGrey'>{data?.city}</div>
          </div>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4 mt-2'>
            <div className='font-bold text-text ml-4 mr-1'>Ulice</div>
            <div className='text-lightGrey'>{data?.street}</div>
          </div>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4 mt-2'>
            <div className='font-bold text-text ml-4 mr-1'>PSČ</div>
            <div className='text-lightGrey'>{data?.zip}</div>
          </div>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4 mt-2'>
            <div className='font-bold text-text ml-4 mr-1'>Zasílání notifikací</div>
            <div className='text-lightGrey'>{data?.notification === true ? 'ano' : 'ne'}</div>
          </div>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4 mt-2'>
            <div className='font-bold text-text ml-4 mr-1'>Neaktivní společnost</div>
            <div className='text-lightGrey'>{data?.deletedAt === null ? 'ne' : 'ano'}</div>
          </div>
        </ContactFormCard>
        <div className='flex justify-end mt-4'>
          <RowButton onClick={() => navigate(`/contact-detail/${contactId}`)}>Detail zákazníka</RowButton>
        </div>
      </form>
    </div>
  );
};

export default PreviewContactForm;
