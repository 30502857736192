import React from 'react';
import { NotificationResponse } from 'src/api';
import ServiceNotification from 'src/components/ServiceNotification';

type NotificationProps = {
  notifications: NotificationResponse[];
};

const NotificationCard: React.FC<NotificationProps> = ({ notifications }) => {
  return (
    <>
      {notifications.map((notification, index) => (
        <ServiceNotification
          key={notification.id}
          notification={notification}
          isLastItem={notifications.length - 1 === index}
        />
      ))}
    </>
  );
};

export default NotificationCard;
