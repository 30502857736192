import React, { ReactNode } from 'react';

type UserFormCardProps = {
  children?: ReactNode;
};

const UserFormCard: React.FC<UserFormCardProps> = ({ children }) => (
  <div className='h-3/4 rounded-md shadow-md bg-white border-border border-inherit border'>{children}</div>
);

export default UserFormCard;
