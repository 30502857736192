/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateRoutineServicesRequest } from '../models/CreateRoutineServicesRequest';
import type { RoutineServicesResponse } from '../models/RoutineServicesResponse';
import type { UpdateRoutineServicesRequest } from '../models/UpdateRoutineServicesRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RoutineServicesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns RoutineServicesResponse
   * @throws ApiError
   */
  public getRoutineServicesList({
    rs,
    withDeleted,
  }: {
    /**
     * Routine service name
     */
    rs?: string;
    /**
     * With deleted
     */
    withDeleted?: boolean;
  }): CancelablePromise<Array<RoutineServicesResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/routine-services',
      query: {
        rs: rs,
        withDeleted: withDeleted,
      },
    });
  }

  /**
   * @returns RoutineServicesResponse
   * @throws ApiError
   */
  public createRoutineServices({
    requestBody,
  }: {
    requestBody: CreateRoutineServicesRequest;
  }): CancelablePromise<RoutineServicesResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/routine-services',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns RoutineServicesResponse
   * @throws ApiError
   */
  public getRoutineServices({ id }: { id: number }): CancelablePromise<RoutineServicesResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/routine-services/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns RoutineServicesResponse
   * @throws ApiError
   */
  public deleteRoutineServices({ id }: { id: number }): CancelablePromise<RoutineServicesResponse> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/routine-services/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns RoutineServicesResponse
   * @throws ApiError
   */
  public updateRoutineServices({
    id,
    requestBody,
  }: {
    id: number;
    requestBody: UpdateRoutineServicesRequest;
  }): CancelablePromise<RoutineServicesResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/routine-services/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
