import { AxiosError } from 'axios';
import { yupResolver } from '@hookform/resolvers/yup';
import { format, getMinutes, isValid, parse, parseISO, set } from 'date-fns';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Controller, DefaultValues, FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useOldApiClient } from 'src/api';
import DatePicker from 'src/components/DatePicker';
import HorizontalRow from 'src/components/HorizontalRow';
import Select from 'src/components/Select';
import TextField from 'src/components/Textfield';
import DescriptionField from 'src/components/DescriptionField';
import { useTechnicians } from 'src/store/technicianContext';
import { useStatuses } from 'src/store/statusContext';
import EditButton from '../../../components/EditButton';
import RowButton from '../../../components/RowButton';
import ServiceFormCard from '../ServiceFormCard';
import serviceFormSchema, { ServiceFormValues } from './schema';
import { StatusWatcher } from './StatusWatcher';
import { TIME_SELECT_VALUES } from './constants';
import Modal from 'src/components/Modal';
import ClearIcon from 'src/components/Icons/ClearIcon';
import ServiceFormDataRow from 'src/components/ServiceFormDataRow';
import { useBranches } from 'src/store/branchesContext';
import { EmployeeWatcher } from './EmployeeWatcher';
import { BranchesWatcher } from './BranchesWatcher';
import { ContactWatcher } from './ContactWatcher';
import { useEmployees } from 'src/store/employeesContext';
import { Select as AutocompleteSelect } from '../../../components/SelectV2';
import { useContacts } from 'src/store/contactsContext';
import { useProducts } from 'src/store/productsContext';
import { ProductsWatcher } from './ProductsWatcher';
import { useCreateOrderMutation, useUpdateOrderMutation } from 'src/api/hooks/useOrders';
import { useRoutineServices } from 'src/api/hooks/useRoutineServices';

interface ServiceFormProps {
  data: DefaultValues<ServiceFormValues>;
  serviceNumber?: number | undefined;
}

const ServiceForm: React.FC<ServiceFormProps> = ({ data, serviceNumber }) => {
  const Client = useOldApiClient();
  const { technicians } = useTechnicians();
  const { findLabelByName } = useStatuses();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { branches } = useBranches();
  const { employees } = useEmployees();
  const { contacts, setContacts } = useContacts();
  const { products } = useProducts();

  const updateOrder = useUpdateOrderMutation();
  const createOrder = useCreateOrderMutation();
  const routineServices = useRoutineServices();

  const handleCloseForm = (): void => {
    navigate('/');
  };

  const form = useForm<ServiceFormValues>({
    resolver: yupResolver(serviceFormSchema),
    defaultValues: data || {
      quarantee: true,
      status: 'NEW',
      company: { notification: false },
    },
  });

  const { handleSubmit, control, setValue } = form;

  const onSubmit: SubmitHandler<ServiceFormValues> = async (data) => {
    try {
      if (serviceNumber) {
        await updateOrder.mutateAsync({ body: data, id: serviceNumber });
        navigate('/');
      } else {
        await createOrder.mutateAsync(data);
        navigate('/');
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 409) {
          setIsModalOpen(true);
        }
      }
    }
  };

  const onError: SubmitErrorHandler<ServiceFormValues> = (errors) => {
    console.log(errors);
  };

  const getContactList = async (): Promise<void> => {
    const response = await Client.get('/contact');
    setContacts(response.data);
  };

  useEffect(() => {
    getContactList();
  }, []);

  const formHandleBlur = (vatField: string): void => {
    if (vatField != null) {
      const findContact = contacts.find((contact) => contact.vat == vatField)?.id;
      if (findContact != null) {
        setValue('companyId', findContact);
      }
    }
  };

  return (
    <div className='flex-col w-2/5 md:w-full md:pl-3 ntb:ml-4 lg:ml-4 ntb:ml-4'>
      <FormProvider {...form}>
        <form className='lg:w-11/12 ntb:w-11/12 md:mr-5 flex-col' onSubmit={handleSubmit(onSubmit, onError)}>
          <div className='flex-row flex justify-between'>
            <div className='mt-4 text-4xl text-text font-bold mb-2 flex flex-row'>
              {serviceNumber ? `Zakázka #${serviceNumber}` : 'Nová zakázka'}
            </div>
            {serviceNumber ? (
              <div className='flex flex-row items-center'>
                <EditButton onClick={handleCloseForm}>Zavřít</EditButton>
                <RowButton type='submit'>Uložit změny</RowButton>
              </div>
            ) : (
              ''
            )}
          </div>

          <ServiceFormCard>
            <div className='flex space-x-44 mb-2 mt-4'>
              <div className='font-bold text-text text-2xl ml-4'>Zakázka</div>
            </div>

            <div className='text-lightGrey text-sm ml-4'>Základní informace o zakázce</div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-4 mr-6'>Status</div>
              <Controller
                control={control}
                name='status'
                render={({ field }) => <>{findLabelByName(field?.value)}</>}
              />{' '}
            </div>

            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-4 mr-3'>Technik</div>
              <Controller
                control={control}
                name='technicianId'
                render={({ field }) => (
                  <>
                    <Select {...field}>
                      <option value=''></option>
                      {technicians.map((technician) => (
                        <option key={technician.id} value={technician?.id}>
                          {technician.name}
                        </option>
                      ))}
                    </Select>
                  </>
                )}
              />
            </div>
            <HorizontalRow />
            <Controller
              control={control}
              name='datetime'
              render={({ field }) => {
                const value = isValid(parse(field.value ?? '', 'dd.MM.yyyy', new Date()))
                  ? format(parse(field.value ?? '', 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd')
                  : field.value;
                const selectValue = value ? format(new Date(value), 'HH:mm') : '';

                const handleSelectTime = (event: ChangeEvent<HTMLSelectElement>): void => {
                  const splitedValue = event.target.value.split(':');

                  if (value) {
                    field.onChange(
                      set(new Date(value), {
                        hours: Number.parseInt(splitedValue[0]),
                        minutes: Number.parseInt(splitedValue[1]),
                      }).toISOString(),
                    );
                  }
                };
                return (
                  <>
                    <div className='grid grid-cols-2 mb-4'>
                      <div className='font-bold text-text ml-4 mr-1'>Datum</div>
                      <DatePicker
                        {...field}
                        onChange={(date) => {
                          if (!date) {
                            field.onChange(null);
                            return;
                          }
                          const dateWithCorrectMinutes = set(new Date(date), {
                            minutes: getMinutes(new Date(date)) > 30 ? 30 : 0,
                          });
                          field.onChange(dateWithCorrectMinutes.toISOString());
                        }}
                        value={value ? parseISO(value) : null}
                      />
                    </div>
                    <HorizontalRow />
                    <div className='grid grid-cols-2 mb-4'>
                      <div className='font-bold text-text ml-4 mr-1'>Čas</div>
                      <Select {...field} value={selectValue} onChange={(value) => handleSelectTime(value)}>
                        {TIME_SELECT_VALUES.map(({ label, value }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                      </Select>
                    </div>
                  </>
                );
              }}
            />
            <HorizontalRow />

            <div className='font-bold text-text text-2xl ml-4'>Zákazník</div>
            <div className='text-lightGrey text-sm ml-4 mb-2'>Kontakt na zákazníka žádající o zakázku</div>
            <HorizontalRow />

            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-4 mb-2'>Vybrat zákazníka</div>

              <Controller
                control={control}
                name='companyId'
                render={({ field }) => (
                  <AutocompleteSelect
                    options={contacts}
                    getOptionLabel={(opt) => `${opt.companyName} - ${opt.vat}`}
                    getOptionValue={(opt) => opt.id}
                    {...field}
                  />
                )}
              />
            </div>

            <HorizontalRow />
            <div className='grid grid-cols-2 mb-4'>
              <div className='font-bold text-text ml-4 mr-12'>Název společnosti*</div>
              <Controller
                control={control}
                name='company.companyName'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </div>
            <HorizontalRow />
            <ServiceFormDataRow label='IČO*'>
              <Controller
                control={control}
                name='company.in'
                render={({ field, fieldState }) => {
                  const onBlur = (): void => {
                    field.onBlur();
                    formHandleBlur(field.value);
                  };
                  return <TextField {...field} onBlur={onBlur} error={fieldState.error?.message} />;
                }}
              />
            </ServiceFormDataRow>
            <HorizontalRow />
            <ServiceFormDataRow label='DIČ'>
              <Controller
                control={control}
                name='company.vat'
                render={({ field, fieldState }) => {
                  return <TextField {...field} error={fieldState.error?.message} />;
                }}
              />
            </ServiceFormDataRow>
            <HorizontalRow />
            <ServiceFormDataRow label='Město*'>
              <Controller
                control={control}
                name='company.city'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </ServiceFormDataRow>
            <HorizontalRow />
            <ServiceFormDataRow label='Ulice*'>
              <Controller
                control={control}
                name='company.street'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </ServiceFormDataRow>
            <HorizontalRow />
            <ServiceFormDataRow label='PSČ*'>
              <Controller
                control={control}
                name='company.zip'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </ServiceFormDataRow>
            <HorizontalRow />
            <ServiceFormDataRow label='Telefonní kontakt*'>
              <Controller
                control={control}
                name='company.phoneNumber'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </ServiceFormDataRow>
            <HorizontalRow />
            <ServiceFormDataRow label='Email*'>
              <Controller
                control={control}
                name='company.email'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </ServiceFormDataRow>
            <HorizontalRow />
            <ServiceFormDataRow label='Zasílání notifikací*'>
              <Controller
                control={control}
                name='company.notification'
                render={({ field }) => {
                  const value = field?.value === true ? 'yes' : 'no';
                  const onChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
                    field.onChange(e.target.value === 'yes');
                  };
                  return (
                    <>
                      <Select {...field} value={value} onChange={onChange}>
                        <option value={'yes'}>ANO</option>
                        <option value={'no'}>NE</option>
                      </Select>
                    </>
                  );
                }}
              />
            </ServiceFormDataRow>
            <HorizontalRow />
            <div className='font-bold text-text text-2xl ml-4'>Pobočka</div>
            <div className='text-lightGrey text-sm ml-4 mb-4'>Informace o pobočce</div>
            <HorizontalRow />

            <ServiceFormDataRow label='Vybrat pobočku'>
              <Controller
                control={control}
                name='branchId'
                render={({ field }) => (
                  <>
                    <Select {...field}>
                      <option value=''></option>
                      {branches.map((branch) => (
                        <option key={branch.id} value={branch.id}>
                          {branch.name}
                        </option>
                      ))}
                    </Select>
                  </>
                )}
              />
            </ServiceFormDataRow>
            <HorizontalRow />

            <ServiceFormDataRow label='Název pobočky*'>
              <Controller
                control={control}
                name='branch.name'
                render={({ field, fieldState }) => {
                  return <TextField {...field} error={fieldState.error?.message} />;
                }}
              />
            </ServiceFormDataRow>
            <HorizontalRow />

            <ServiceFormDataRow label='Město*'>
              <Controller
                control={control}
                name='branch.city'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </ServiceFormDataRow>
            <HorizontalRow />
            <ServiceFormDataRow label='Ulice*'>
              <Controller
                control={control}
                name='branch.street'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </ServiceFormDataRow>
            <HorizontalRow />
            <ServiceFormDataRow label='PSČ*'>
              <Controller
                control={control}
                name='branch.zip'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </ServiceFormDataRow>
            <HorizontalRow />
            <ServiceFormDataRow label='IČO pobočky*'>
              <Controller
                control={control}
                name='branch.vat'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </ServiceFormDataRow>
            <HorizontalRow />
            <ServiceFormDataRow label='Kontaktní osoba pobočky*'>
              <Controller
                control={control}
                name='branch.contactPerson'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </ServiceFormDataRow>
            <HorizontalRow />
            <ServiceFormDataRow label='Telefonní kontakt*'>
              <Controller
                control={control}
                name='branch.phoneNumber'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </ServiceFormDataRow>
            <HorizontalRow />

            <ServiceFormDataRow label='Email'>
              <Controller
                control={control}
                name='branch.email'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </ServiceFormDataRow>
            <HorizontalRow />

            <div className='font-bold text-text text-2xl ml-4'>Osoba</div>
            <div className='text-lightGrey text-sm ml-4 mb-4'>Informace o osobě</div>
            <HorizontalRow />
            <ServiceFormDataRow label='Vybrat osobu'>
              <Controller
                control={control}
                name='employeeId'
                render={({ field }) => (
                  <>
                    <Select {...field}>
                      <option value=''></option>
                      {employees.map((employee) => (
                        <option key={employee.id} value={employee?.id}>
                          {employee.name}
                        </option>
                      ))}
                    </Select>
                  </>
                )}
              />
            </ServiceFormDataRow>
            <HorizontalRow />
            <ServiceFormDataRow label='Jméno*'>
              <Controller
                control={control}
                name='employee.name'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </ServiceFormDataRow>
            <HorizontalRow />
            <ServiceFormDataRow label='Telefonní kontakt*'>
              <Controller
                control={control}
                name='employee.phoneNumber'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </ServiceFormDataRow>
            <HorizontalRow />
            <ServiceFormDataRow label='Email*'>
              <Controller
                control={control}
                name='employee.email'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </ServiceFormDataRow>
            <HorizontalRow />

            <div className='font-bold text-text text-2xl ml-4'>Produkt</div>

            <div className='text-lightGrey text-sm ml-4 mb-4'>Informace o produktu</div>

            <Controller control={control} name='product.branchId' defaultValue={0} render={() => <></>} />
            <HorizontalRow />
            <ServiceFormDataRow label='Vybrat zařízení'>
              <Controller
                control={control}
                name='productId'
                render={({ field }) => (
                  <>
                    <Select {...field}>
                      <option value=''></option>
                      {products.map((product) => (
                        <option key={product.id} value={product?.id}>
                          {product.productName}
                        </option>
                      ))}
                    </Select>
                  </>
                )}
              />
            </ServiceFormDataRow>
            <HorizontalRow />

            <ServiceFormDataRow label='Výrobce*'>
              <Controller
                control={control}
                name='product.productProducer'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </ServiceFormDataRow>
            <HorizontalRow />
            <ServiceFormDataRow label='Název*'>
              <Controller
                control={control}
                name='product.productName'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </ServiceFormDataRow>
            <HorizontalRow />
            <ServiceFormDataRow label='Sériové číslo'>
              <Controller
                control={control}
                name='product.productSerialNumber'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </ServiceFormDataRow>
            <HorizontalRow />
            <ServiceFormDataRow label='Pravidelný servis'>
              <Controller
                control={control}
                name='product.routineServiceId'
                render={({ field }) => {
                  return (
                    <Select {...field}>
                      <option value=''>NE</option>
                      {routineServices.data?.map((routineService) => (
                        <option key={routineService.id} value={routineService?.id}>
                          {routineService.routineName}
                        </option>
                      ))}
                    </Select>
                  );
                }}
              />
            </ServiceFormDataRow>
            <HorizontalRow />
            <ServiceFormDataRow label='Číslo faktury'>
              <Controller
                control={control}
                name='invoiceNumber'
                render={({ field, fieldState }) => (
                  <>
                    <TextField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </ServiceFormDataRow>
            <HorizontalRow />

            <ServiceFormDataRow label='Záruční oprava*'>
              <Controller
                control={control}
                name='quarantee'
                render={({ field }) => {
                  const value = field?.value === true ? 'yes' : 'no';
                  const onChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
                    field.onChange(e.target.value === 'yes');
                  };
                  return (
                    <>
                      <Select {...field} value={value} onChange={onChange}>
                        <option value={'yes'}>ANO</option>
                        <option value={'no'}>NE</option>
                      </Select>
                    </>
                  );
                }}
              />
            </ServiceFormDataRow>
            <HorizontalRow />
            <ServiceFormDataRow label='Popis závady'>
              <Controller
                control={control}
                name='productDescription'
                render={({ field, fieldState }) => (
                  <>
                    <DescriptionField {...field} error={fieldState.error?.message} />
                  </>
                )}
              />
            </ServiceFormDataRow>
          </ServiceFormCard>

          <div className='mt-5 grid place-items-end'>
            <div className='flex-row mr-4 mb-6'>
              <EditButton onClick={() => handleCloseForm()}>Zavřít</EditButton>
              <RowButton type='submit'>Uložit změny</RowButton>
            </div>
          </div>
          <ContactWatcher />
          <StatusWatcher />
          <EmployeeWatcher />
          <BranchesWatcher />
          <ProductsWatcher />
        </form>
      </FormProvider>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} hideButtons>
        <button onClick={() => navigate('/')}>
          <div className='justify-end flex mb-2'>
            <ClearIcon />
          </div>
        </button>
        <div className='flex-col'>Zadané IČ již existuje. Zákazník nebyl uložen, ale zakázka byla založena</div>
      </Modal>
    </div>
  );
};

export default ServiceForm;
