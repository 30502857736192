/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatePaymentMethodRequest } from '../models/CreatePaymentMethodRequest';
import type { PaymentMethodResponse } from '../models/PaymentMethodResponse';
import type { UpdatePaymentMethodRequest } from '../models/UpdatePaymentMethodRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PaymentMethodService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns PaymentMethodResponse
   * @throws ApiError
   */
  public getPaymentMethodList({
    pm,
    withDeleted,
  }: {
    /**
     * Payment method name
     */
    pm?: string;
    /**
     * With deleted
     */
    withDeleted?: boolean;
  }): CancelablePromise<Array<PaymentMethodResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/payment-method',
      query: {
        pm: pm,
        withDeleted: withDeleted,
      },
    });
  }

  /**
   * @returns PaymentMethodResponse
   * @throws ApiError
   */
  public createPaymentMethod({
    requestBody,
  }: {
    requestBody: CreatePaymentMethodRequest;
  }): CancelablePromise<PaymentMethodResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/payment-method',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns PaymentMethodResponse
   * @throws ApiError
   */
  public getPaymentMethod({ id }: { id: number }): CancelablePromise<PaymentMethodResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/payment-method/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns PaymentMethodResponse
   * @throws ApiError
   */
  public deletePaymentMethod({ id }: { id: number }): CancelablePromise<PaymentMethodResponse> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/payment-method/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns PaymentMethodResponse
   * @throws ApiError
   */
  public updatePaymentMethod({
    id,
    requestBody,
  }: {
    id: number;
    requestBody: UpdatePaymentMethodRequest;
  }): CancelablePromise<PaymentMethodResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/payment-method/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
