import React from 'react';

const ProductHistoryServiceHeader: React.FC = () => {
  return (
    <div className='flex'>
      <div className='border-l border-t border-b border-border w-full h-10 text-center bg-white rounded-tl-lg flex justify-center pt-1 pb-2'>
        <div className='pt-1'>Datum</div>
      </div>

      <div
        className='border-r border-t border-b border-border w-full h-10
        text-center bg-white rounded-tr-lg flex justify-center pt-1 pb-2'
      >
        <div className='pt-1'>Servisní list</div>
      </div>
    </div>
  );
};

export default ProductHistoryServiceHeader;
