import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Outlet, Route, Routes } from 'react-router-dom';
import PaymentMethodPreview from 'src/pages/PaymentMethodPage/PaymentMethodPreview';
import { useOldApiClient } from 'src/api';
import { PaymentMethodResponse } from 'src/api/types/paymentMethods/PaymentMethodResponse';
import PaymentMethodForm from 'src/pages/PaymentMethodPage/PaymentMethodForm';

const PaymentMethodFetch: React.FC = () => {
  const { paymentId } = useParams();
  const Client = useOldApiClient();
  const [dataInForm, setDataInForm] = useState<PaymentMethodResponse & { id: number }>();

  const getDataToForm = async (): Promise<void> => {
    if (paymentId) {
      const response = await Client.get(`/payment-method/${paymentId}`);
      setDataInForm(response.data);
    }
  };

  useEffect(() => {
    getDataToForm();
  }, [paymentId]);

  return (
    <>
      <Outlet />
      <Routes>
        <Route path='/preview' element={<PaymentMethodPreview data={dataInForm} />} />
        <Route
          path='/edit'
          element={
            dataInForm ? (
              <PaymentMethodForm paymentId={dataInForm?.id} defaultValues={{ paymentMethodName: dataInForm.name }} />
            ) : null
          }
        />
      </Routes>
    </>
  );
};

export default PaymentMethodFetch;
