import React from 'react';
import { useParams } from 'react-router';
import { Route, Routes, Outlet } from 'react-router-dom';
import { useNotification } from 'src/api/hooks/useNotificationSettings';
import NotificationForm from 'src/pages/NotificationPage/NotificationForm';
import PreviewNotificationForm from 'src/pages/NotificationPage/NotificationPreview';

type NotificationPageParams = {
  notificationId: string;
};

const NotificationFetch: React.FC = () => {
  const { notificationId } = useParams<NotificationPageParams>();
  const { data } = useNotification(Number(notificationId));

  return (
    <>
      <Outlet />
      <Routes>
        <Route path='/preview' element={<PreviewNotificationForm data={data} />} />
        <Route
          path='/edit'
          element={
            data ? <NotificationForm notificationId={Number(notificationId)} data={{ ...(data ?? undefined) }} /> : null
          }
        />
      </Routes>
    </>
  );
};

export default NotificationFetch;
