import React, { ReactNode } from 'react';

type PaymentMethodFormCardProps = {
  children?: ReactNode;
};

const PaymentMethodForm: React.FC<PaymentMethodFormCardProps> = ({ children }) => (
  <div className='h-3/4 rounded-md shadow-md bg-white border-border border-inherit border'>{children}</div>
);

export default PaymentMethodForm;
