import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { OrderStatusEmbeddedResponse, useOldApiClient } from 'src/api';

interface StatusContextProps {
  statuses: OrderStatusEmbeddedResponse[];
  setStatuses: React.Dispatch<React.SetStateAction<OrderStatusEmbeddedResponse[]>>;
  findLabelByName: (name: string) => string;
}

const defaultState: StatusContextProps = {
  statuses: [],
  setStatuses: () => {
    return;
  },
  findLabelByName: () => {
    return '';
  },
};

interface statusContextProviderProps {
  children: React.ReactNode;
}

export const StatusContext = createContext<StatusContextProps>(defaultState);

export const StatusProvider: React.FC<statusContextProviderProps> = ({ children }) => {
  const Client = useOldApiClient();
  const [statuses, setStatuses] = useState<OrderStatusEmbeddedResponse[]>([]);

  const getStatuses = async (): Promise<void> => {
    const response = await Client.get('/status');

    setStatuses(response.data);
  };

  const findLabelByName = useCallback(
    (name: string): string => {
      return statuses.find((status) => status.name === name)?.label || name;
    },
    [statuses],
  );

  useEffect(() => {
    getStatuses();
  }, []);

  return <StatusContext.Provider value={{ statuses, setStatuses, findLabelByName }}>{children}</StatusContext.Provider>;
};

export const useStatuses = (): StatusContextProps => useContext(StatusContext);
