import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HorizontalRow from 'src/components/HorizontalRow';
import EditButton from 'src/components/EditButton';
import RowButton from '../../../components/RowButton';
import BranchFormCard from '../BranchFormCard';
import Textfield from 'src/components/Textfield';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { CreateBranchRequest, useOldApiClient } from 'src/api';
import CreateBranchFromSchema, { CreateBranchFormValues } from './schema';
import DataRow from 'src/components/DataRow';

interface BranchFormProps {
  defaultValues?: Partial<CreateBranchRequest>;
  branchId?: number;
}

const BranchForm: React.FC<BranchFormProps> = ({ defaultValues, branchId }) => {
  const navigate = useNavigate();
  const { contactId } = useParams();

  const Client = useOldApiClient();

  const form = useForm<CreateBranchFormValues>({
    resolver: yupResolver(CreateBranchFromSchema),
    defaultValues: defaultValues,
  });
  const { handleSubmit, control } = form;

  const onSubmit: SubmitHandler<CreateBranchRequest> = async (data) => {
    try {
      if (branchId) {
        const response = await Client.put(`/contact/${contactId}/branch/${branchId}`, data);
        if (response.status === 200) {
          navigate(`/contact-detail/${contactId}`);
        }
      } else {
        const response = await Client.post(`/contact/${contactId}/branch`, data);
        if (response.status === 201) {
          navigate(`/contact-detail/${contactId}`);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='flex-col w-full mt-2 md:w-full md:pl-3 ntb:pl-10 lg:ml-4'>
      <div className='ml-2 mt-2 text-4xl text-text font-bold'>{branchId ? 'Editace pobočky' : ' Nová pobočka'}</div>
      <FormProvider {...form}>
        <form className='lg:w-11/12 ntb:w-11/12 md:mr-5 flex-col' onSubmit={handleSubmit(onSubmit)}>
          <BranchFormCard>
            <div className='mt-2'>
              <DataRow label='Název pobočky*'>
                <Controller
                  control={control}
                  name='name'
                  render={({ field, fieldState }) => <Textfield {...field} error={fieldState.error?.message} />}
                />
              </DataRow>
            </div>
            <HorizontalRow />

            <DataRow label='IČO pobočky*'>
              <Controller
                control={control}
                name='vat'
                render={({ field, fieldState }) => <Textfield {...field} error={fieldState.error?.message} />}
              />
            </DataRow>
            <HorizontalRow />

            <DataRow label='Město*'>
              <Controller
                control={control}
                name='city'
                render={({ field, fieldState }) => <Textfield {...field} error={fieldState.error?.message} />}
              />
            </DataRow>
            <HorizontalRow />

            <DataRow label='Ulice*'>
              <Controller
                control={control}
                name='street'
                render={({ field, fieldState }) => <Textfield {...field} error={fieldState.error?.message} />}
              />
            </DataRow>
            <HorizontalRow />

            <DataRow label='PSČ*'>
              <Controller
                control={control}
                name='zip'
                render={({ field, fieldState }) => <Textfield {...field} error={fieldState.error?.message} />}
              />
            </DataRow>
            <HorizontalRow />

            <DataRow label='Telefonní číslo*'>
              <Controller
                control={control}
                name='phoneNumber'
                render={({ field, fieldState }) => <Textfield {...field} error={fieldState.error?.message} />}
              />
            </DataRow>
            <HorizontalRow />

            <DataRow label='Email'>
              <Controller control={control} name='email' render={({ field }) => <Textfield {...field} />} />
            </DataRow>
            <HorizontalRow />

            <DataRow label='Kontaktní osoba*'>
              <Controller
                control={control}
                name='contactPerson'
                render={({ field, fieldState }) => <Textfield {...field} error={fieldState.error?.message} />}
              />
            </DataRow>
          </BranchFormCard>
          <div className='mt-5 grid place-items-end'>
            <div className='flex-row mr-4 mb-6'>
              <EditButton onClick={() => navigate(`/contact-detail/${contactId}`)}>Zavřít</EditButton>
              <RowButton type='submit'>Uložit změny</RowButton>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default BranchForm;
