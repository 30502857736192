import React, { useEffect } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { useOldApiClient } from 'src/api';
import { BranchesProvider, useBranches } from 'src/store/branchesContext';
import { Modules, useLicenses } from 'src/store/licenseContext';
import { StatusProvider } from 'src/store/statusContext';
import Branches from './Branches';
import ContactDetailPageCard from './ContactDetailPageCard';
import Employees from './Employees';
import HistoryServices from './HistoryServices';
import Products from './Products';

export type SortType = {
  path: string[];
  direction: 'asc' | 'desc';
  type: 'number' | 'string';
};

const Wrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { contactId } = useParams();
  const { setBranches } = useBranches();
  const Client = useOldApiClient();

  const getBranches = async (): Promise<void> => {
    const response = await Client.get(`/contact/${contactId}/branch`);
    setBranches(response.data);
  };

  useEffect(() => {
    getBranches();
  }, [contactId]);

  return <>{children}</>;
};

const ContactDetailPage: React.FC = () => {
  const { getIsModuleValid } = useLicenses();

  return (
    <>
      <BranchesProvider>
        <StatusProvider>
          <Wrapper>
            {!getIsModuleValid(Modules.CONTACTS) && <Navigate to='/contact' />}
            <div className='flex w-full md:flex-col md:w-full'>
              <div className='md:w-full ntb:w-full lg:w-full'>
                <ContactDetailPageCard />
                <div className='lg:grid lg:grid-cols-2 ntb:grid ntb:grid-cols-2'>
                  <Branches />
                </div>

                <div className='lg:grid lg:grid-cols-2 ntb:grid ntb:grid-cols-2'>
                  <Employees />
                </div>

                <div className='lg:grid lg:grid-cols-2 ntb:grid ntb:grid-cols-2'>
                  <Products />
                </div>
                <div className='lg:grid lg:grid-cols-2 ntb:grid ntb:grid-cols-2'>
                  <HistoryServices />
                </div>
              </div>
            </div>
            <Outlet />
          </Wrapper>
        </StatusProvider>
      </BranchesProvider>
    </>
  );
};

export default ContactDetailPage;
