import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Route, Routes } from 'react-router-dom';
import { useOldApiClient } from 'src/api';
import { ParametersResponse } from 'src/api/types/parameters/ParametersResponse';
import ParameterForm from 'src/pages/ParametersPage/ParameterForm';
import ParameterPreview from 'src/pages/ParametersPage/ParameterPreview';

const ParameterFetch: React.FC = () => {
  const { paramKey } = useParams();
  const Client = useOldApiClient();
  const [dataInForm, setDataInForm] = useState<ParametersResponse & { id: number }>();

  const getDataToForm = async (): Promise<void> => {
    if (paramKey) {
      const response = await Client.get(`/parameters/${paramKey}`);
      setDataInForm(response.data);
    }
  };

  useEffect(() => {
    getDataToForm();
  }, [paramKey]);

  return (
    <>
      <Routes>
        <Route path='/preview' element={<ParameterPreview data={dataInForm} />} />

        <Route
          path='/edit'
          element={
            dataInForm ? (
              <ParameterForm
                paramKey={dataInForm.paramKey}
                defaultValues={{
                  paramKey: dataInForm.paramKey,
                  paramValue: dataInForm.paramValue,
                  paramTitle: dataInForm.paramTitle,
                }}
              />
            ) : null
          }
        />
      </Routes>
    </>
  );
};

export default ParameterFetch;
