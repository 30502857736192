import { clone } from 'ramda';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams, Route, Routes } from 'react-router-dom';
import { useOldApiClient } from 'src/api';
import { BranchesListResponse } from 'src/api/types/branches/BranchesListResponse';
import BranchesFetch from 'src/components/BranchesFetch';
import FilterIcon from 'src/components/Icons/FilterIcon';

import RowButton from 'src/components/RowButton';
import { Modules, useLicenses } from 'src/store/licenseContext';
import { StatusProvider } from 'src/store/statusContext';

import { sortArrayOfObjects } from 'src/utils/sortArrayOfObjects';
import BranchesCard from '../BranchesCard';
import BranchForm from '../BranchForm';
import BranchTableHeader, { ServiceHeaderFilter } from '../BranchTableHeader';

export type SortType = {
  path: string[];
  direction: 'asc' | 'desc';
  type: 'number' | 'string';
};

const getHeightOfFormByPathname = (pathname: string): string => {
  if (pathname.endsWith('/new') || pathname.endsWith('/edit')) {
    return 'lg:h-[calc(100vh-8.5rem)] ntb:h-[calc(100vh-8.8rem)]';
  }
  if (pathname.endsWith('/preview')) {
    return 'lg:h-[calc(100vh-8.5rem)] ntb:h-[calc(100vh-8.8rem)]';
  }
  return 'lg:h-[calc(100vh-8.8rem)] ntb:h-[calc(100vh-8.8rem)]';
};

const Branches: React.FC = () => {
  const { getIsModuleValid } = useLicenses();
  const Client = useOldApiClient();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { contactId } = useParams();

  const [isFilterOpen, setIsFilterOpen] = useState<string | undefined>(undefined);
  const [currentFilters, setCurrentFilters] = useState<ServiceHeaderFilter>();
  const [branches, setBranches] = useState<BranchesListResponse[]>([]);
  const [currentSort, setCurrentSort] = useState<SortType>({ path: ['id'], type: 'number', direction: 'desc' });

  const getBranches = async (): Promise<void> => {
    const response = await Client.get(`/contact/${contactId}/branch`, { params: currentFilters });
    setBranches(sortArrayOfObjects(clone(response.data), currentSort.path, currentSort.direction, currentSort.type));
  };

  const handleFilterBranches = async (queryString: ServiceHeaderFilter): Promise<void> => {
    const response = await Client.get(`/contact/${contactId}/branch`, { params: queryString });
    setBranches(sortArrayOfObjects(clone(response.data), currentSort.path, currentSort.direction, currentSort.type));
    setCurrentFilters(queryString);
  };

  const handleSortBranches = (newItems: BranchesListResponse[], newSortObj: SortType): void => {
    const sortedArr = sortArrayOfObjects(clone(newItems), newSortObj.path, newSortObj.direction, newSortObj.type);
    setCurrentSort(newSortObj);
    setBranches(sortedArr);
  };

  useEffect(() => {
    getBranches();
  }, [pathname]);

  return (
    <>
      <StatusProvider>
        {!getIsModuleValid(Modules.CONTACTS) && <Navigate to='/contact' />}

        <div className='flex-col'>
          <div className='flex mb-2'>
            <div className='text-4xl text-text font-bold flex w-full ml-2 pt-2'>
              Pobočky
              <button
                onClick={() => {
                  setIsFilterOpen(isFilterOpen === 'branch' ? undefined : 'branch');
                }}
              >
                <div className='mt-3 ml-2'>
                  <FilterIcon />
                </div>
              </button>
            </div>
            <div className='mt-4 md:mr-6'>
              {pathname === `/contact-detail/${contactId}` && (
                <RowButton
                  onClick={() => {
                    navigate(`new-branch`);
                  }}
                >
                  Nová pobočka
                </RowButton>
              )}
            </div>
          </div>
          <BranchTableHeader
            sortBranches={branches}
            handleSortBranches={handleSortBranches}
            isFilterOpen={isFilterOpen === 'branch'}
            handleFilterBranches={handleFilterBranches}
          />
          <div
            className={`${getHeightOfFormByPathname(
              pathname,
            )} md:h-[12rem] ntb:h-[12rem] lg:h-[15rem] overflow-y-scroll rounded-b-lg`}
          >
            {' '}
            <BranchesCard branches={branches} />
          </div>
        </div>
        <Routes>
          <Route path='/branch/:branchId/*' element={<BranchesFetch />} />
          <Route path='/new-branch' element={<BranchForm />} />
        </Routes>
      </StatusProvider>
    </>
  );
};

export default Branches;
