import React, { useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { ProductResponse, ProductServiceHistory, useOldApiClient } from 'src/api';
import { useRoutineServices } from 'src/api/hooks/useRoutineServices';
import EditButton from 'src/components/EditButton';
import HorizontalRow from 'src/components/HorizontalRow';
import PreviewDataRow from 'src/components/PreviewDataRow';
import { useBranches } from 'src/store/branchesContext';
import RowButton from '../../../components/RowButton';
import HistoryServiceProductCard from '../HistoryServiceProductCard';
import ProductFormCard from '../ProductFormCard';
import ProductHistoryServiceHeader from '../ProductServiceHistoryHeader';

interface PreviewProductFormProps {
  data?: ProductResponse & { id: number };
}

export type SortType = {
  path: string[];
  direction: 'asc' | 'desc';
  type: 'number' | 'string';
};

const ProductPreview: React.FC<PreviewProductFormProps> = ({ data }) => {
  const { contactId, productId, branchId } = useParams();
  const { branches } = useBranches();
  const Client = useOldApiClient();
  const navigate = useNavigate();
  const routineServices = useRoutineServices();

  const routineServiceName = useMemo(() => {
    return routineServices.data?.find((routineService) => routineService.id == data?.routineServiceId)?.routineName;
  }, [routineServices.data, data?.routineServiceId]);

  const form = useForm<ProductResponse | ProductServiceHistory>({
    defaultValues: data,
  });

  const { handleSubmit } = form;
  const branchName = branches.find((branch) => data?.branchId === branch.id)?.name;

  const onSubmit: SubmitHandler<ProductResponse | ProductServiceHistory> = async (data) => {
    try {
      const response = await Client.delete(`contact/${contactId}/branch/${branchId}/product/${productId}`, {
        data,
      });
      if (response.status === 200) {
        navigate(`/contact-detail/${contactId}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className='flex-col w-full md:w-full md:pl-3 ml-4'>
        <div className='flex w-11/12'>
          <div className='grid grid-row-2 mb-2 w-full'>
            <div className='flex w-full'>
              <div className='mt-2 text-4xl text-text font-bold flex w-full'>Detail zařízení</div>

              <div className='flex flex-row items-center pt-2'>
                <EditButton onClick={() => navigate(`/contact-detail/${contactId}`)}>Zpět</EditButton>
                <RowButton
                  onClick={() => navigate(`/contact-detail/${contactId}/branch/${branchId}/product/${productId}/edit`)}
                >
                  Upravit
                </RowButton>
              </div>
            </div>
          </div>
        </div>
        <form className='w-11/12' onSubmit={handleSubmit(onSubmit)}>
          <ProductFormCard>
            <PreviewDataRow label='Název'>{data?.productName}</PreviewDataRow>
            <HorizontalRow />
            <PreviewDataRow label='Pobočka'>{branchName}</PreviewDataRow>
            <HorizontalRow />
            <PreviewDataRow label='Výrobce'>{data?.productProducer}</PreviewDataRow>
            <HorizontalRow />
            <PreviewDataRow label='Sériové číslo'>{data?.productSerialNumber}</PreviewDataRow>
            <HorizontalRow />
            <PreviewDataRow label='Pravidelný servis'>{routineServiceName}</PreviewDataRow>
          </ProductFormCard>
          <div className='flex justify-end mt-4'>
            <RowButton type='submit'>Odstranit</RowButton>
          </div>
        </form>
        <div className='w-11/12'>
          <div className='flex-col md:w-full'>
            <div className='flex justify-between'>
              <div className='flex items-baseline'>
                <div className='ml-6 mt-2 text-4xl text-text font-bold flex mr-4 '>Historie zásahů</div>
              </div>
            </div>

            <div className='h-full'>
              <ProductHistoryServiceHeader />
              <div className='overflow-y-scroll rounded-b-lg mb-4'>
                <HistoryServiceProductCard historyServices={data?.productServiceHistory ?? []} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductPreview;
