/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BranchListResponse } from '../models/BranchListResponse';
import type { BranchResponse } from '../models/BranchResponse';
import type { CreateBranchRequest } from '../models/CreateBranchRequest';
import type { UpdateBranchRequest } from '../models/UpdateBranchRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BranchService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns BranchResponse
   * @throws ApiError
   */
  public createBranch({
    contactId,
    requestBody,
  }: {
    contactId: number;
    requestBody: CreateBranchRequest;
  }): CancelablePromise<BranchResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/contact/{contactId}/branch',
      path: {
        contactId: contactId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns BranchListResponse
   * @throws ApiError
   */
  public getContactList({
    contactId,
    name,
    city,
    withDeleted,
  }: {
    contactId: number;
    /**
     * Branch NAME
     */
    name?: string;
    /**
     * Branch CITY
     */
    city?: string;
    /**
     * With deleted
     */
    withDeleted?: boolean;
  }): CancelablePromise<BranchListResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/contact/{contactId}/branch',
      path: {
        contactId: contactId,
      },
      query: {
        name: name,
        city: city,
        withDeleted: withDeleted,
      },
    });
  }

  /**
   * @returns BranchResponse
   * @throws ApiError
   */
  public getBranch({ contactId, id }: { contactId: number; id: number }): CancelablePromise<BranchResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/contact/{contactId}/branch/{id}',
      path: {
        contactId: contactId,
        id: id,
      },
    });
  }

  /**
   * @returns BranchResponse
   * @throws ApiError
   */
  public updateContact({
    contactId,
    id,
    requestBody,
  }: {
    contactId: number;
    id: number;
    requestBody: UpdateBranchRequest;
  }): CancelablePromise<BranchResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/contact/{contactId}/branch/{id}',
      path: {
        contactId: contactId,
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns BranchResponse
   * @throws ApiError
   */
  public deleteBranch({ contactId, id }: { contactId: number; id: number }): CancelablePromise<BranchResponse> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/contact/{contactId}/branch/{id}',
      path: {
        contactId: contactId,
        id: id,
      },
    });
  }
}
