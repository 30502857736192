import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { RoutineServicesResponse, UpdateRoutineServicesRequest, useApiClient } from '..';
import { CreateRoutineServicesRequest } from '../types/routineServices/CreateRoutineServiceRequest';

export type ServiceHeaderFilter = {
  rs: string;
};

export const ROUTINE_SERVICE_CACHE_KEY = 'routineServices';

export const useRoutineServices = (filters?: ServiceHeaderFilter): UseQueryResult<RoutineServicesResponse[]> => {
  const client = useApiClient();
  return useQuery([ROUTINE_SERVICE_CACHE_KEY, filters], () =>
    client.routineServices.getRoutineServicesList(filters ?? {}),
  );
};

export const useRoutineService = (id: number): UseQueryResult<RoutineServicesResponse, { id: number }> => {
  const client = useApiClient();
  return useQuery([ROUTINE_SERVICE_CACHE_KEY, id], async () => client.routineServices.getRoutineServices({ id }));
};

export const useCreateRoutineServiceMutation = (): UseMutationResult<
  CreateRoutineServicesRequest,
  unknown,
  RoutineServicesResponse
> => {
  const queryClient = useQueryClient();
  const client = useApiClient();
  return useMutation(
    [ROUTINE_SERVICE_CACHE_KEY],
    async (body) => client.routineServices.createRoutineServices({ requestBody: body }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ROUTINE_SERVICE_CACHE_KEY]);
      },
    },
  );
};

export const useUpdateRoutineServiceMutation = (): UseMutationResult<
  RoutineServicesResponse,
  unknown,
  { body: UpdateRoutineServicesRequest; id: number }
> => {
  const queryClient = useQueryClient();
  const client = useApiClient();
  return useMutation(
    [ROUTINE_SERVICE_CACHE_KEY],
    async ({ body, id }) => client.routineServices.updateRoutineServices({ requestBody: body, id }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ROUTINE_SERVICE_CACHE_KEY]);
      },
    },
  );
};

export const useDeleteRoutineServiceMutation = (): UseMutationResult<
  RoutineServicesResponse,
  unknown,
  { id: number }
> => {
  const queryClient = useQueryClient();
  const client = useApiClient();
  return useMutation(
    [ROUTINE_SERVICE_CACHE_KEY],
    async ({ id }) => client.routineServices.deleteRoutineServices({ id }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ROUTINE_SERVICE_CACHE_KEY]);
      },
    },
  );
};
