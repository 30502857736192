/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateEmployeeRequest } from '../models/CreateEmployeeRequest';
import type { EmployeeListResponse } from '../models/EmployeeListResponse';
import type { EmployeeResponse } from '../models/EmployeeResponse';
import type { UpdateEmployeeRequest } from '../models/UpdateEmployeeRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EmployeeService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns EmployeeResponse
   * @throws ApiError
   */
  public createBranch({
    contactId,
    requestBody,
  }: {
    contactId: number;
    requestBody: CreateEmployeeRequest;
  }): CancelablePromise<EmployeeResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/contact/{contactId}/employee',
      path: {
        contactId: contactId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns EmployeeListResponse
   * @throws ApiError
   */
  public getContactList({
    contactId,
    email,
    name,
    phoneNumber,
    withDeleted,
  }: {
    contactId: number;
    /**
     * Employee email
     */
    email?: string;
    /**
     * Employee name
     */
    name?: string;
    /**
     * Employee phone number
     */
    phoneNumber?: string;
    /**
     * With deleted
     */
    withDeleted?: boolean;
  }): CancelablePromise<EmployeeListResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/contact/{contactId}/employee',
      path: {
        contactId: contactId,
      },
      query: {
        email: email,
        name: name,
        phoneNumber: phoneNumber,
        withDeleted: withDeleted,
      },
    });
  }

  /**
   * @returns EmployeeResponse
   * @throws ApiError
   */
  public getBranch({ contactId, id }: { contactId: number; id: number }): CancelablePromise<EmployeeResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/contact/{contactId}/employee/{id}',
      path: {
        contactId: contactId,
        id: id,
      },
    });
  }

  /**
   * @returns EmployeeResponse
   * @throws ApiError
   */
  public updateContact({
    contactId,
    id,
    requestBody,
  }: {
    contactId: number;
    id: number;
    requestBody: UpdateEmployeeRequest;
  }): CancelablePromise<EmployeeResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/contact/{contactId}/employee/{id}',
      path: {
        contactId: contactId,
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns EmployeeResponse
   * @throws ApiError
   */
  public deleteBranch({ contactId, id }: { contactId: number; id: number }): CancelablePromise<EmployeeResponse> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/contact/{contactId}/employee/{id}',
      path: {
        contactId: contactId,
        id: id,
      },
    });
  }
}
