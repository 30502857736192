import React, { useState } from 'react';
import { useLocation, useNavigate, Route, Routes, Outlet, Navigate } from 'react-router-dom';
import { TechnicianProvider } from 'src/store/technicianContext';
import RowButton from '../../components/RowButton';
import ContactHeader, { ServiceHeaderFilter } from './ContactHeader';
import ContactCard from './ContactCard';
import ContactForm from './ContactForm';
import ContactFetch from 'src/components/ContactFetch';
import { ContactListResponse } from 'src/api';
import { clone } from 'ramda';
import { sortArrayOfObjects } from 'src/utils/sortArrayOfObjects';
import { Modules, useLicenses } from 'src/store/licenseContext';
import { StatusProvider } from 'src/store/statusContext';
import FilterIcon from 'src/components/Icons/FilterIcon';
import { useContacts } from 'src/api/hooks/useContacts';

const getHeightOfFormByPathname = (pathname: string): string => {
  if (pathname.endsWith('/new') || pathname.endsWith('/edit')) {
    return 'lg:h-[calc(100vh-8.5rem)] ntb:h-[calc(100vh-8.8rem)]';
  }
  if (pathname.endsWith('/preview')) {
    return 'lg:h-[calc(100vh-8.5rem)] ntb:h-[calc(100vh-8.8rem)]';
  }
  return 'lg:h-[calc(100vh-8.8rem)] ntb:h-[calc(100vh-8.8rem)]';
};
export type SortType = {
  path: string[];
  direction: 'asc' | 'desc';
  type: 'number' | 'string';
};

const ContactPage: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { getIsModuleValid } = useLicenses();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [currentFilters, setCurrentFilters] = useState<ServiceHeaderFilter>();
  const [currentSort, setCurrentSort] = useState<SortType>({
    path: ['companyName'],
    type: 'string',
    direction: 'asc',
  });

  const { data } = useContacts(currentFilters);

  const sortedContacts = sortArrayOfObjects(
    clone(data ?? []),
    currentSort.path,
    currentSort.direction,
    currentSort.type,
  );

  const handleFilter = async (queryString: ServiceHeaderFilter): Promise<void> => {
    setCurrentFilters(queryString);
  };

  const handleSort = (newItems: ContactListResponse[], newSortObj: SortType): void => {
    setCurrentSort(newSortObj);
  };

  return (
    <>
      <StatusProvider>
        {!getIsModuleValid(Modules.CONTACTS) && <Navigate to='/contact' />}
        <div className='flex lg:w-full ntb:w-full md:flex-col md:w-full'>
          <div className='flex-col w-2/4 md:w-full'>
            <div className='flex justify-between'>
              <div className='flex items-baseline'>
                <div className='ml-6 mt-2 text-4xl text-text font-bold flex mr-4 '>Zákazníci</div>
                <div>
                  <button
                    onClick={() => {
                      setIsFilterOpen(!isFilterOpen);
                    }}
                  >
                    <div className='flex'>
                      <FilterIcon />
                    </div>
                  </button>
                </div>
              </div>
              <div className='mt-4 md:mr-6'>
                {pathname === '/contact' && (
                  <RowButton
                    onClick={() => {
                      navigate('/contact/new');
                    }}
                  >
                    Nový zákazník
                  </RowButton>
                )}
              </div>
            </div>
            <div className='lg:ml-6 lg:mt-4 ntb:ml-6 ntb:mt-4 ntb:ml-6 ntb:mt-4 md:w-full md:p-3'>
              <div className='h-full'>
                <ContactHeader
                  sortContacts={sortedContacts}
                  handleSort={handleSort}
                  isFilterOpen={isFilterOpen}
                  handleFilter={handleFilter}
                />
                <div className={`${getHeightOfFormByPathname(pathname)} md:h-[30rem] overflow-y-scroll rounded-b-lg`}>
                  <ContactCard contacts={sortedContacts} />
                </div>
              </div>
            </div>
          </div>

          <Outlet />
          <TechnicianProvider>
            <Routes>
              <Route path='/:contactId/*' element={<ContactFetch />} />
              <Route path='/new' element={<ContactForm />} />
            </Routes>
          </TechnicianProvider>
        </div>
      </StatusProvider>
    </>
  );
};

export default ContactPage;
