import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { LicenceResponse } from 'src/api/types/licenses/LicenseResponse';
import { useOldApiClient } from 'src/api';

interface LicenseContextProps {
  getIsModuleValid: (module: Modules) => boolean;
  hasLicense: () => boolean;
}

const defaultState: LicenseContextProps = {
  getIsModuleValid: () => {
    return false;
  },
  hasLicense: () => {
    return false;
  },
};

interface licenseContextProviderProps {
  children: React.ReactNode;
}

export enum Modules {
  SERVICES = 'services',
  ADMINISTRATION = 'administration',
  CONTACTS = 'contacts',
}

export const LicenseContext = createContext<LicenseContextProps>(defaultState);

export const LicenseProvider: React.FC<licenseContextProviderProps> = ({ children }) => {
  const Client = useOldApiClient();
  const [licenses, setLicenses] = useState<LicenceResponse[]>([]);

  const getLicenses = async (): Promise<void> => {
    const response = await Client.get('/licences');

    setLicenses(response.data);
  };

  const getIsModuleValid = useCallback(
    (module: Modules): boolean => {
      return licenses.find((lincese) => lincese.module === module)?.isValid || false;
    },
    [licenses],
  );

  const hasLicense = useCallback((): boolean => {
    return licenses.some((license) => license.isValid === true);
  }, [licenses]);

  useEffect(() => {
    getLicenses();
  }, []);

  return <LicenseContext.Provider value={{ getIsModuleValid, hasLicense }}>{children}</LicenseContext.Provider>;
};

export const useLicenses = (): LicenseContextProps => useContext(LicenseContext);
