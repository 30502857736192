import { CreateProductRequest } from 'src/api';
import { emptyToNull, nullToEmptyString } from 'src/utils/schemaUtils';
import { inputRequired } from 'src/validation/validationMessages';
import { number, object, SchemaOf, string } from 'yup';

export type CreateProductsFormValues = CreateProductRequest;

const CreateProductsFormSchema: SchemaOf<CreateProductsFormValues> = object().shape({
  productName: string().required(inputRequired),
  productSerialNumber: string().defined().default(null).nullable(true).transform(emptyToNull),
  productProducer: string().defined().default(null).required(inputRequired).transform(nullToEmptyString),
  branchId: number().defined().default(null).required(inputRequired).transform(emptyToNull),
  routineServiceId: number()
    .nullable(true)
    .defined()
    .default(null)
    .transform(emptyToNull)
    .transform((val) => (isNaN(val) ? null : val)),
});

export default CreateProductsFormSchema;
