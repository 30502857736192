import React from 'react';
import { BranchesListResponse } from 'src/api/types/branches/BranchesListResponse';
import ServiceBranches from 'src/components/ServiceBranches';

type BranchesProps = {
  branches: BranchesListResponse[];
};

const BranchesCard: React.FC<BranchesProps> = ({ branches }) => {
  return (
    <>
      {branches.map((branch, index) => (
        <ServiceBranches key={branch.id} branch={branch} isLastItem={branches.length - 1 === index} />
      ))}
    </>
  );
};

export default BranchesCard;
