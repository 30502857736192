import React, { ReactNode, useState } from 'react';
import CheckIcon from '../../../components/Icons/CheckIcon';
import SortUpIcon from 'src/components/Icons/SortUpIcon';
import SortDownIcon from 'src/components/Icons/SortDownIcon';
import { SortType } from '../HomePage';
import { clone } from 'ramda';
import SortingTextfield from 'src/components/FilterTextfield';
import { Controller, useForm, useWatch } from 'react-hook-form';
import FilterSelect from 'src/components/SelectFilter';
import { useStatuses } from 'src/store/statusContext';
import { noop } from 'src/utils/commonUtils';
import { OrderListItemResponse } from 'src/api';

type SortSectionType = {
  sortItems: OrderListItemResponse[];
  handleSort: (sortItems: OrderListItemResponse[], sortObj: SortType) => void;
  path: string[];
  type: 'string' | 'number';
  children?: ReactNode;
};

type Direction = 'asc' | 'desc';

const SortSection: React.FC<SortSectionType> = ({ sortItems, handleSort, path, type, children }) => {
  const [direction, setDirection] = useState<Direction>('asc');
  return (
    <div className='flex'>
      <button
        onClick={() => {
          setDirection(direction === 'asc' ? 'desc' : 'asc');
          handleSort(clone(sortItems), { path, direction, type });
        }}
      >
        <div className='flex lg:mr-6'>
          {children}
          {direction === 'asc' ? (
            <div className='pt-1'>
              <SortUpIcon />
            </div>
          ) : (
            <div className='pt-1'>
              <SortDownIcon />
            </div>
          )}
        </div>
      </button>
    </div>
  );
};

type ServiceHeaderProps = {
  sortItems: OrderListItemResponse[];
  handleSort: (sortItems: OrderListItemResponse[], sortObj: SortType) => void;
  isFilterOpen: boolean;
  handleFilter: (queryString: ServiceHeaderFilter) => void;
};

export type ServiceHeaderFilter = {
  statusProp: string;
  companyProp: string;
  technicianProp: string;
};

const serviceHeaderFilterDefaultValues = {
  statusProp: '',
  companyProp: '',
  technicianProp: '',
};
const ServiceHeader: React.FC<ServiceHeaderProps> = ({ sortItems, handleSort, isFilterOpen, handleFilter }) => {
  const { statuses } = useStatuses();
  const { control, handleSubmit, getValues, reset } = useForm<ServiceHeaderFilter>({
    defaultValues: serviceHeaderFilterDefaultValues,
  });

  const formHandleBlur = (values: ServiceHeaderFilter): void => {
    handleFilter(values);
  };

  const statusPropValue = useWatch({ control, name: 'statusProp' });
  React.useEffect(() => {
    const formValues = getValues();
    formHandleBlur(formValues);
  }, [statusPropValue]);

  React.useEffect(() => {
    reset();
    handleFilter(serviceHeaderFilterDefaultValues);
  }, [isFilterOpen]);
  return (
    <form onSubmit={handleSubmit(noop)}>
      <div className='flex'>
        <div
          className={`border-l border-t border-b border-border w-3/5 text-center bg-white ${
            isFilterOpen ? 'h-20' : 'h-10'
          } rounded-tl-lg flex justify-center pt-1`}
        >
          <div className='mr-8 flex'>
            <div className='pt-1'>
              <div className='flex ntb:ml-2 lg:mr-2'>
                <SortSection sortItems={sortItems} handleSort={handleSort} path={['id']} type='number'>
                  ID
                </SortSection>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`border-t border-b border-border w-full text-left bg-white flex-col ${
            isFilterOpen ? 'h-20' : 'h-10'
          } pt-2  pl-2`}
        >
          <SortSection sortItems={sortItems} handleSort={handleSort} path={['companyName']} type='string'>
            Zákazník
          </SortSection>
          {isFilterOpen && (
            <Controller
              name='companyProp'
              control={control}
              render={({ field }) => {
                const onBlur = (): void => {
                  field.onBlur();
                  formHandleBlur(getValues());
                };
                const handleKeyDown = (event: { key: string }): void => {
                  if (event.key === 'Enter') {
                    formHandleBlur(getValues());
                  }
                };
                return <SortingTextfield {...field} onBlur={onBlur} onKeyDown={handleKeyDown} />;
              }}
            />
          )}
        </div>

        <div
          className={`border-t border-b  pt-2 border-border w-full text-left bg-white ${isFilterOpen ? 'h-20' : 'h-10'}
        pl-2 flex-col `}
        >
          <SortSection sortItems={sortItems} handleSort={handleSort} path={['technician', 'name']} type='string'>
            Technik
          </SortSection>
          {isFilterOpen && (
            <Controller
              name='technicianProp'
              control={control}
              render={({ field }) => {
                const onBlur = (): void => {
                  field.onBlur();
                  formHandleBlur(getValues());
                };
                const handleKeyDown = (event: { key: string }): void => {
                  if (event.key === 'Enter') {
                    formHandleBlur(getValues());
                  }
                };
                return <SortingTextfield {...field} onBlur={onBlur} onKeyDown={handleKeyDown} />;
              }}
            />
          )}
        </div>

        <div
          className={`border-t border-b  border-border w-3/5 text-left bg-white  ${
            isFilterOpen ? 'h-20' : 'h-10'
          } flex-col pt-2`}
        >
          <SortSection sortItems={sortItems} handleSort={handleSort} path={['datetime']} type='string'>
            Datum
          </SortSection>
        </div>

        <div
          className={`border-t border-b border-border w-6/12 text-left bg-white  ${
            isFilterOpen ? 'h-20' : 'h-10'
          } flex-col pt-2`}
        >
          <SortSection sortItems={sortItems} handleSort={handleSort} path={['status', 'label']} type='string'>
            Status
          </SortSection>

          {isFilterOpen && (
            <Controller
              name='statusProp'
              control={control}
              render={({ field }) => {
                return (
                  <FilterSelect {...field}>
                    <option value=''>Vše</option>
                    {statuses.map((status) => (
                      <option key={status.id} value={status.name}>
                        {status?.label}
                      </option>
                    ))}
                  </FilterSelect>
                );
              }}
            />
          )}
        </div>

        <div className='border-r border-t border-b border-border text-center bg-white h-2O rounded-tr-lg  w-1/5  pt-2 flex justify-center '>
          <CheckIcon />
        </div>
      </div>
      <input type='submit' className='hidden' />
    </form>
  );
};

export default ServiceHeader;
