import React, { useState, useEffect } from 'react';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { TechnicianOrderResponse } from '../../../api/types/technician/TechnicianOrderResponse';
import { useOldApiClient } from 'src/api';
import { format } from 'date-fns';
import TechnicianPageCard from 'src/components/TechnicianPageCard';
import HorizontalRow from 'src/components/HorizontalRow';
import TechnicianEditButton from 'src/components/TechnicianEditButton';
import TechnicianRowButton from 'src/components/TechnicianRowButton';
import logo from 'src/pictures/logo.svg';
import Modal from 'src/components/Modal';
import { useNavigateWithSearch } from 'src/utils/useNavigateWithSearch';
import DescriptionField from 'src/components/DescriptionField';
import { UnsuccessfulOrderRequest } from 'src/api/types/technician/UnsuccesfulOrderRequest';
import EditButton from 'src/components/EditButton';
import RowButton from 'src/components/RowButton';
import TechnicianDataRowPreview from 'src/components/TechnicianDataRowPreview';

interface TechnicianFinishFormProps {
  defaultValues?: Partial<UnsuccessfulOrderRequest>;
}

const TechnicianOrder: React.FC<TechnicianFinishFormProps> = () => {
  const navigate = useNavigateWithSearch();
  const Client = useOldApiClient();
  const [dataInForm, setDataInForm] = useState<TechnicianOrderResponse>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalSucces, setIsModalSucces] = useState(false);

  const form = useForm<UnsuccessfulOrderRequest>({
    defaultValues: {
      status: 'UNSUCCESSFUL',
      descriptionNote: null,
    },
  });

  const { handleSubmit, control } = form;

  const onHandleUnsuccessfulService: SubmitHandler<UnsuccessfulOrderRequest> = async (data) => {
    try {
      const response = await Client.put('/technician-order/unsuccessful', {
        status: 'UNSUCCESSFUL',
        descriptionNote: data.descriptionNote,
      });

      if (response.status === 200) {
        setIsModalSucces(true);
      }
    } catch (error) {
      console.error('test', error);
    } finally {
      setIsModalOpen(false);
    }
  };

  const getDataToForm = async (): Promise<void> => {
    {
      const response = await Client.get('/technician-order');
      setDataInForm(response.data);
    }
  };

  useEffect(() => {
    getDataToForm();
  }, []);

  const date = dataInForm?.datetime && format(new Date(dataInForm.datetime), 'dd.MM.yyyy HH:mm');

  return (
    <div className='bg-background '>
      <div className='grid place-items-center'>
        <form className='min-w-full grid place-items-center'>
          <div className='w-10/12'>
            <div className='flex justify-start mb-4'>
              <div className='ml-2 mt-4 text-4xl text-text font-bold'>Zakázka číslo #{dataInForm?.id} </div>
              <img src={logo} className='App-logo px-8 pt-6 w-44 mb-2' alt='logo' />
            </div>
          </div>

          <TechnicianPageCard>
            <div className='flex space-x-44 mb-2 mt-4'>
              <div className='font-bold text-text text-2xl ml-4'>Zakázka</div>
            </div>
            <div className='text-lightGrey text-sm ml-4'>Základní informace o zakázce</div>
            <HorizontalRow />
            <TechnicianDataRowPreview label='Technik'>{dataInForm?.technician.name}</TechnicianDataRowPreview>
            <HorizontalRow />
            <TechnicianDataRowPreview label='Datum'>{date}</TechnicianDataRowPreview>
            <HorizontalRow />
            <div className='font-bold text-text text-2xl ml-4 mb-2'>Zákazník</div>
            <div className='text-lightGrey text-sm ml-4 mb-4'>Kontakt na zákazníka</div>
            <HorizontalRow />
            <TechnicianDataRowPreview label='Telefonní kontakt'>
              {dataInForm?.company.phoneNumber}
            </TechnicianDataRowPreview>
            <HorizontalRow />
            <TechnicianDataRowPreview label='Město'>{dataInForm?.company.city}</TechnicianDataRowPreview>
            <HorizontalRow />
            <TechnicianDataRowPreview label='Ulice'>{dataInForm?.company.street}</TechnicianDataRowPreview>
            <HorizontalRow />
            <TechnicianDataRowPreview label='PSČ'>{dataInForm?.company.zip}</TechnicianDataRowPreview>
            <HorizontalRow />
            <TechnicianDataRowPreview label='Název firmy'>{dataInForm?.company.companyName}</TechnicianDataRowPreview>
            <HorizontalRow />
            <TechnicianDataRowPreview label='IČO'>{dataInForm?.company.in}</TechnicianDataRowPreview>
            <HorizontalRow />
            <TechnicianDataRowPreview label='Email'>{dataInForm?.company.email}</TechnicianDataRowPreview>
            <HorizontalRow />
            <div className='font-bold text-text text-2xl ml-4 mb-2'>Pobočka</div>
            <div className='text-lightGrey text-sm ml-4 mb-4'>Informace o pobočce</div>
            <HorizontalRow />
            <TechnicianDataRowPreview label='Název pobočky'>{dataInForm?.branch.name}</TechnicianDataRowPreview>
            <HorizontalRow />
            <TechnicianDataRowPreview label='IČO pobočky'>{dataInForm?.branch.vat}</TechnicianDataRowPreview>
            <HorizontalRow />
            <TechnicianDataRowPreview label='Kontaktní osoba'>
              {dataInForm?.branch.contactPerson}
            </TechnicianDataRowPreview>
            <HorizontalRow />
            <TechnicianDataRowPreview label='Email'>{dataInForm?.branch.email}</TechnicianDataRowPreview>
            <HorizontalRow />
            <TechnicianDataRowPreview label='Telefonní kontakt'>
              {dataInForm?.branch.phoneNumber}
            </TechnicianDataRowPreview>
            <HorizontalRow />
            <TechnicianDataRowPreview label='Město'>{dataInForm?.branch.city}</TechnicianDataRowPreview>
            <HorizontalRow />
            <TechnicianDataRowPreview label='Ulice'>{dataInForm?.branch.street}</TechnicianDataRowPreview>
            <HorizontalRow />
            <TechnicianDataRowPreview label='PSČ'>{dataInForm?.branch.zip}</TechnicianDataRowPreview>
            <HorizontalRow />
            <div className='font-bold text-text text-2xl ml-4 mb-2'>Osoba</div>
            <div className='text-lightGrey text-sm ml-4 mb-4'>Informace o osobě</div>
            <HorizontalRow />
            <TechnicianDataRowPreview label='Jméno'>{dataInForm?.employee.name}</TechnicianDataRowPreview>
            <HorizontalRow />
            <TechnicianDataRowPreview label='Telefonní kontakt'>
              {dataInForm?.employee.phoneNumber}
            </TechnicianDataRowPreview>
            <HorizontalRow />
            <TechnicianDataRowPreview label='Email'>{dataInForm?.employee.email}</TechnicianDataRowPreview>
            <HorizontalRow />
            <div className='font-bold text-text text-2xl ml-4'>Produkt</div>
            <div className='text-lightGrey text-sm ml-4'>Informace o produktu</div>
            <HorizontalRow />
            <TechnicianDataRowPreview label='Záruční oprava'>
              {dataInForm?.quarantee === true ? 'ano' : 'ne'}
            </TechnicianDataRowPreview>
            <HorizontalRow />
            <TechnicianDataRowPreview label='Výrobce'>{dataInForm?.product.productProducer}</TechnicianDataRowPreview>
            <HorizontalRow />
            <TechnicianDataRowPreview label='Model'>{dataInForm?.product.productName}</TechnicianDataRowPreview>
            <HorizontalRow />
            <TechnicianDataRowPreview label='Sériové číslo'>
              {dataInForm?.product.productSerialNumber}
            </TechnicianDataRowPreview>
            <HorizontalRow />
            <TechnicianDataRowPreview label='Číslo zakázky'>{dataInForm?.invoiceNumber}</TechnicianDataRowPreview>
            <HorizontalRow />
            <TechnicianDataRowPreview label='Popis závady'>{dataInForm?.productDescription}</TechnicianDataRowPreview>
          </TechnicianPageCard>

          <div className='mt-5 grid place-items-center'>
            <div className='flex mb-6 ml-2'>
              <div className='md:p-2 lg:p-2 ntb:p-2'>
                <TechnicianEditButton type='button' onClick={() => setIsModalOpen(true)}>
                  Neúspěšný zakázka
                </TechnicianEditButton>
              </div>
              <div className='md:p-2 lg:p-2 ntb:p-2'>
                <TechnicianRowButton onClick={() => navigate({ pathname: `/technician-order/finish` })}>
                  Další
                </TechnicianRowButton>
              </div>
            </div>
          </div>
        </form>
      </div>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onHandleUnsuccessfulService)}>
          <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} hideButtons>
            <div className='relative p-6 flex-auto'>Opravdu chcete zakázku označit za nedokončený?</div>
            <div className='flex-col ml-8'>
              Poznámka pro neúspěšný zakázku
              <Controller
                control={control}
                name='descriptionNote'
                render={({ field }) => <DescriptionField {...field} />}
              />
              <EditButton type='button' onClick={() => setIsModalOpen(false)}>
                Storno
              </EditButton>
              <RowButton type='submit'>Dokončit</RowButton>
            </div>
          </Modal>
        </form>
      </FormProvider>
      <Modal isOpen={isModalSucces} hideButtons>
        <div>Zakázka byla označena za neúspěšnou.</div>{' '}
      </Modal>
    </div>
  );
};

export default TechnicianOrder;
