import { Route, Routes } from 'react-router-dom';
import { AppRouter } from './AppRouter';
import { Layout } from './components/Layout';
import ProtectedRoute from './components/ProtectedRoute/index';
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage';
import LoginPage from './pages/LoginPage';
import TechnicianOrder from './pages/TechnicianOrder/TechnicianOrder';
import TechnicianOrderFinish from './pages/TechnicianOrder/TechnicianOrderFinish';
import { AuthProvider } from './store/authContext';
import { TechnicianAuthProvider } from './store/technicianAuthContext';
import { LicenseProvider } from './store/licenseContext';
import { PaymentMethodProvider } from './store/paymentMethodContext';
import { MaterialsProvider } from './store/materialsContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <div className='bg-background w-screen  min-h-screen'>
        <Routes>
          <Route
            path='/technician-order/*'
            element={
              <TechnicianAuthProvider>
                <PaymentMethodProvider>
                  <MaterialsProvider>
                    <Routes>
                      <Route path='/' element={<TechnicianOrder />} />
                      <Route path='/finish' element={<TechnicianOrderFinish />} />
                    </Routes>
                  </MaterialsProvider>
                </PaymentMethodProvider>
              </TechnicianAuthProvider>
            }
          />
          <Route
            path='/*'
            element={
              <AuthProvider>
                <Routes>
                  <Route
                    path='/*'
                    element={
                      <ProtectedRoute>
                        <LicenseProvider>
                          <Layout>
                            <AppRouter />
                          </Layout>
                        </LicenseProvider>
                      </ProtectedRoute>
                    }
                  />
                  <Route path='/login' element={<LoginPage />} />
                  <Route path='/forgot-password' element={<ForgotPasswordPage />} />
                </Routes>
              </AuthProvider>
            }
          />
        </Routes>
      </div>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
