import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Route, Routes, Outlet } from 'react-router-dom';

import UsersHeader, { ServiceHeaderFilter } from './UsersHeader';
import UsersCard from './UsersCard';
import { TechnicianProvider } from 'src/store/technicianContext';
import RowButton from '../../components/RowButton';
import UserForm from './UserForm';
import UserFetch from 'src/components/UserFetch';
import { useOldApiClient } from 'src/api';
import { UserListResponse } from 'src/api/types/user/UserListResponse';
import { clone } from 'ramda';
import { sortArrayOfObjects } from 'src/utils/sortArrayOfObjects';
import FilterIcon from 'src/components/Icons/FilterIcon';

const getHeightOfFormByPathname = (pathname: string): string => {
  if (pathname.endsWith('/new') || pathname.endsWith('/edit')) {
    return 'lg:h-[calc(100vh-8.5rem)] ntb:h-[calc(100vh-8.8rem)]';
  }
  if (pathname.endsWith('/preview')) {
    return 'lg:h-[calc(100vh-8.5rem)] ntb:h-[calc(100vh-8.8rem)]';
  }
  return 'lg:h-[calc(100vh-8.8rem)] ntb:h-[calc(100vh-8.8rem)]';
};

export type SortType = {
  path: string[];
  direction: 'asc' | 'desc';
  type: 'number' | 'string';
};

const UserPage: React.FC = () => {
  const { pathname } = useLocation();
  const Client = useOldApiClient();
  const navigate = useNavigate();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [users, setUsers] = useState<UserListResponse[]>([]);
  const [currentFilters, setCurrentFilters] = useState<ServiceHeaderFilter>();
  const [currentSort, setCurrentSort] = useState<SortType>({ path: ['id'], type: 'number', direction: 'desc' });

  const handleFilter = async (queryString: ServiceHeaderFilter): Promise<void> => {
    const response = await Client.get('/user', { params: queryString });
    setUsers(sortArrayOfObjects(clone(response.data), currentSort.path, currentSort.direction, currentSort.type));
    setCurrentFilters(queryString);
  };

  const getUserList = async (): Promise<void> => {
    const response = await Client.get('/user', { params: currentFilters });
    setUsers(sortArrayOfObjects(clone(response.data), currentSort.path, currentSort.direction, currentSort.type));
  };

  useEffect(() => {
    getUserList();
  }, [pathname]);

  const handleSort = (newItems: UserListResponse[], newSortObj: SortType): void => {
    const sortedArr = sortArrayOfObjects(clone(newItems), newSortObj.path, newSortObj.direction, newSortObj.type);
    setCurrentSort(newSortObj);
    setUsers(sortedArr);
  };

  return (
    <>
      <div className='flex lg:w-full ntb:w-full md:flex-col md:w-full'>
        <div className='flex-col w-2/4 md:w-full'>
          <div className='flex justify-between'>
            <div className='flex items-baseline'>
              <div className='ml-6 mt-2 text-4xl text-text font-bold flex mr-4 '>Uživatelé</div>
              <div>
                <button
                  onClick={() => {
                    setIsFilterOpen(!isFilterOpen);
                  }}
                >
                  <div className='flex'>
                    <FilterIcon />
                  </div>
                </button>
              </div>
            </div>
            <div className='mt-4 md:mr-6'>
              {pathname === '/user' && (
                <RowButton
                  onClick={() => {
                    navigate('/user/new');
                  }}
                >
                  Nový uživatel
                </RowButton>
              )}
            </div>
          </div>
          <div className='lg:ml-6 lg:mt-4 ntb:ml-6 ntb:mt-4 ntb:ml-6 ntb:mt-4 md:w-full md:p-3'>
            <div className='h-full'>
              <UsersHeader
                sortUsers={users}
                handleSort={handleSort}
                isFilterOpen={isFilterOpen}
                handleFilter={handleFilter}
              />
              <div className={`${getHeightOfFormByPathname(pathname)} md:h-[30rem] overflow-y-scroll rounded-b-lg`}>
                <UsersCard users={users} />
              </div>
            </div>
          </div>
        </div>

        <Outlet />
        <TechnicianProvider>
          <Routes>
            <Route path='/:userId/*' element={<UserFetch />} />
            <Route path='/new' element={<UserForm />} />
          </Routes>
        </TechnicianProvider>
      </div>
    </>
  );
};

export default UserPage;
