import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditButton from 'src/components/EditButton';
import RowButton from '../../../components/RowButton';
import TechnicianFormCard from '../TechnicianFormCard';
import HorizontalRow from 'src/components/HorizontalRow';
import { TechnicianResponse } from 'src/api/types/technician/TechnicianResponse';

interface PreviewTechnicianFormProps {
  data?: TechnicianResponse & { id: number };
}

const TechnicianPreview: React.FC<PreviewTechnicianFormProps> = ({ data }) => {
  const navigate = useNavigate();
  const { technicianId } = useParams();

  return (
    <div className='flex-col w-2/4 md:w-full md:pl-3 ml-4'>
      <div className='flex w-11/12'>
        <div className='grid grid-row-2 mb-4 w-full'>
          <div className='flex w-full'>
            <div className='mt-2 text-4xl text-text font-bold flex w-full'>Technik</div>

            <div className='flex flex-row items-center pt-2'>
              <EditButton onClick={() => navigate('/technician')}>Zpět</EditButton>
              <RowButton onClick={() => navigate(`/technician/${technicianId}/edit`)}>Upravit</RowButton>
            </div>
          </div>
        </div>
      </div>

      <form className='w-11/12'>
        <TechnicianFormCard>
          <div className='grid grid-cols-2 mb-4 mt-2'>
            <div className='font-bold text-text ml-4 mr-1'>Email</div>
            <div className='text-lightGrey'>{data?.email}</div>
          </div>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4 mt-2'>
            <div className='font-bold text-text ml-4 mr-1'>Jméno</div>
            <div className='text-lightGrey'>{data?.name}</div>
          </div>
        </TechnicianFormCard>
      </form>
    </div>
  );
};

export default TechnicianPreview;
