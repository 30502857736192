import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { NotificationResponse } from 'src/api';
import EditButton from 'src/components/EditButton';
import PreviewDataRow from 'src/components/PreviewDataRow';
import RowButton from '../../../components/RowButton';
import NotificationFormCard from '../NotificationFormCard';

interface PreviewNotificationFormProps {
  data?: NotificationResponse & { id: number };
}
const PreviewNotificationForm: React.FC<PreviewNotificationFormProps> = ({ data }) => {
  const { notificationId } = useParams();
  const navigate = useNavigate();

  return (
    <div className='flex-col w-2/4 md:w-full md:pl-3 ml-4'>
      <div className='flex w-11/12'>
        <div className='grid grid-row-2 mb-4 w-full'>
          <div className='flex w-full'>
            <div className='mt-2 text-4xl text-text font-bold flex w-full'>Notifikace</div>

            <div className='flex flex-row items-center pt-2'>
              <EditButton onClick={() => navigate('/notification')}>Zpět</EditButton>
              <RowButton onClick={() => navigate(`/notification/${notificationId}/edit`)}>Upravit</RowButton>
            </div>
          </div>
        </div>
      </div>
      <form className='w-11/12'>
        <NotificationFormCard>
          <PreviewDataRow label='Zpráva'>{data?.title}</PreviewDataRow>

          <PreviewDataRow label='Text'>{data?.text}</PreviewDataRow>

          <PreviewDataRow label='Čas oznámení'>{data?.time}</PreviewDataRow>
        </NotificationFormCard>
      </form>
    </div>
  );
};

export default PreviewNotificationForm;
