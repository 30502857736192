import { CreateMaterialRequest } from 'src/api/types/material/CreateMaterialRequest';
import { emptyToNull } from 'src/utils/schemaUtils';

import { object, SchemaOf, string, number } from 'yup';

export type CreateMaterialFormValues = CreateMaterialRequest;

const CreateMaterialFormSchema: SchemaOf<CreateMaterialFormValues> = object().shape({
  materialValue: number().defined().default(null).transform(emptyToNull),
  materialName: string().defined().default(null).transform(emptyToNull),
  materialCode: string().defined().default(null).transform(emptyToNull),
});

export default CreateMaterialFormSchema;
