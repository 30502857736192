import React, { createContext, useContext, useState } from 'react';
import { EmployeeResponse } from 'src/api/types/employee/EmployeeResponse';

interface EmployeesContextProps {
  employees: EmployeeResponse[];
  setEmployees: React.Dispatch<React.SetStateAction<EmployeeResponse[]>>;
}

const defaultState: EmployeesContextProps = {
  employees: [],
  setEmployees: () => {
    return;
  },
};

interface EmployeesContextProviderProps {
  children: React.ReactNode;
}

export const EmployeesContext = createContext<EmployeesContextProps>(defaultState);

export const EmployeesProvider: React.FC<EmployeesContextProviderProps> = ({ children }) => {
  const [employees, setEmployees] = useState<EmployeeResponse[]>([]);

  return <EmployeesContext.Provider value={{ employees, setEmployees }}>{children}</EmployeesContext.Provider>;
};

export const useEmployees = (): EmployeesContextProps => useContext(EmployeesContext);
