import React, { createContext, useContext, useState } from 'react';
import { BranchResponse } from 'src/api/types/branches/BranchResponse';

interface BranchesContextProps {
  branches: BranchResponse[];
  setBranches: React.Dispatch<React.SetStateAction<BranchResponse[]>>;
}

const defaultState: BranchesContextProps = {
  branches: [],
  setBranches: () => {
    return;
  },
};

interface BranchesContextProviderProps {
  children: React.ReactNode;
}

export const BranchesContext = createContext<BranchesContextProps>(defaultState);

export const BranchesProvider: React.FC<BranchesContextProviderProps> = ({ children }) => {
  const [branches, setBranches] = useState<BranchResponse[]>([]);

  return <BranchesContext.Provider value={{ branches, setBranches }}>{children}</BranchesContext.Provider>;
};

export const useBranches = (): BranchesContextProps => useContext(BranchesContext);
