import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SelectContextProvider } from '../context/selectContext';
import { SelectOption, SelectProps } from '../types';
import { SuggestionsListComponent } from './SuggestionList';

const _Select = <Option extends SelectOption, ReturnValue>(
  {
    getOptionLabel,
    getOptionValue,
    options,
    value,
    onChange,
    onFilter,
    createOnEmpty,
    ...rest
  }: SelectProps<Option, ReturnValue>,
  ref: React.ForwardedRef<HTMLInputElement>,
): React.ReactElement => {
  const inputRef = useRef<HTMLInputElement>();
  const cachedProps = useRef<
    Pick<SelectProps<Option, ReturnValue>, 'getOptionLabel' | 'getOptionValue' | 'onChange' | 'onFilter'>
  >({
    getOptionLabel,
    getOptionValue,
    onChange,
    onFilter,
  });

  const [filteredOptions, setFilteredOptions] = useState<typeof options>([]);

  const _getOptionValue = useCallback(cachedProps.current.getOptionValue, []);
  const _getOptionLabel = useCallback(cachedProps.current.getOptionLabel, []);

  const onSelect = useCallback(
    (option: Option) => {
      const val = _getOptionValue(option);
      const label = _getOptionLabel(option);
      cachedProps.current.onChange?.(val, option);
      if (inputRef.current) {
        inputRef.current.value = String(label);
      }
    },
    [_getOptionValue, _getOptionLabel],
  );

  const onKeyDown = useCallback(
    (key: React.KeyboardEvent): void => {
      if (key.key === 'Enter' || (key.key === 'Tab' && filteredOptions[0])) {
        onSelect(filteredOptions[0]);
      }
    },
    [onSelect],
  );

  const selected = useMemo(() => {
    return options.find((opt) => _getOptionValue(opt) === value);
  }, [options, value, _getOptionValue]);

  const handleChange = useCallback(
    (search: string) => {
      if (selected != null && !createOnEmpty) {
        cachedProps.current.onChange?.(null, null);
      }

      if (createOnEmpty) {
        cachedProps.current.onChange?.(null, null, search);
      }

      if (!search) {
        setFilteredOptions([]);
        return;
      }
      const onFilter = cachedProps.current.onFilter;
      if (onFilter) {
        setFilteredOptions(options.filter((opt) => onFilter(opt, search)));
      } else {
        setFilteredOptions(
          options.filter((opt) =>
            _getOptionLabel(opt).toString().toLowerCase().includes(search.toString().toLowerCase()),
          ),
        );
      }
    },
    [options, _getOptionLabel, selected],
  );

  useEffect(() => {
    cachedProps.current.getOptionLabel = getOptionLabel;
    cachedProps.current.getOptionValue = getOptionValue;
    cachedProps.current.onChange = onChange;
    cachedProps.current.onFilter = onFilter;
  }, [getOptionLabel, getOptionValue, onChange, onFilter]);

  useEffect(() => {
    if (selected && inputRef.current) {
      inputRef.current.value = _getOptionLabel(selected).toString();
    }
  }, [selected]);

  return (
    <SelectContextProvider<Option, ReturnValue>
      getOptionValue={_getOptionValue}
      getOptionLabel={_getOptionLabel}
      selected={selected ?? null}
      options={selected == null ? filteredOptions : []}
      onSelect={onSelect}
    >
      <div className='flex flex-col'>
        <input
          ref={(r) => {
            inputRef.current = r as HTMLInputElement;
            if (typeof ref === 'function') {
              ref(r);
            } else if (ref) {
              ref.current = r;
            }
          }}
          className='w-11/12 h-9 rounded-md border-2 border-border pl-2 md:w-full'
          type='label'
          onChange={(e) => handleChange(e.target.value)}
          onKeyDown={onKeyDown}
          defaultValue={selected ? _getOptionLabel(selected) : ''}
          {...rest}
        />
        <SuggestionsListComponent />
      </div>
    </SelectContextProvider>
  );
};

export const Select = React.forwardRef(_Select) as <Option extends SelectOption, ReturnValue>(
  props: SelectProps<Option, ReturnValue>,
  ref: React.ForwardedRef<HTMLInputElement>,
) => React.ReactElement;
