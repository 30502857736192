import { string, object, SchemaOf, ref } from 'yup';
import { emptyToNull } from 'src/utils/schemaUtils';
import { ChangePasswordRequest } from 'src/api/types/user/changePasswordRequest';

export type ChangePasswordFormValues = ChangePasswordRequest;

const changePasswordFormSchema: SchemaOf<ChangePasswordFormValues> = object().shape({
  userPsw: string().min(8).defined().default(null).transform(emptyToNull),
  userNewPsw: string().min(8).defined().default(null).transform(emptyToNull),
  userNewPswConfirm: string()
    .min(8)
    .defined()
    .default(null)
    .oneOf([ref('userNewPsw')], 'Does not match with userNewPsw!')
    .transform(emptyToNull),
});

export default changePasswordFormSchema;
