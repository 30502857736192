import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { NotificationResponse, useApiClient } from '..';

export type ServiceHeaderFilter = {
  title: string;
};

export const NOTIFICATION_CACHE_KEY = 'notificationSetting';

export const useNotifications = (): UseQueryResult<NotificationResponse[]> => {
  const client = useApiClient();
  return useQuery([NOTIFICATION_CACHE_KEY], () => client.notificationSettings.getNotificationList());
};

export const useNotification = (id: number): UseQueryResult<NotificationResponse, { id: number }> => {
  const client = useApiClient();
  return useQuery([NOTIFICATION_CACHE_KEY, id], async () => client.notificationSettings.getNotification({ id }));
};

export const useUpdateNotificationMutation = (): UseMutationResult<
  NotificationResponse,
  unknown,
  { body: NotificationResponse; id: number }
> => {
  const client = useApiClient();
  const queryClient = useQueryClient();
  return useMutation(
    [NOTIFICATION_CACHE_KEY],
    async ({ body, id }) => client.notificationSettings.updateNotification({ requestBody: body, id }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([NOTIFICATION_CACHE_KEY]);
      },
    },
  );
};
