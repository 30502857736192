import React from 'react';

import { ParametersResponse } from 'src/api/types/parameters/ParametersResponse';
import ServiceParameters from 'src/components/ServiceParameters';

type ParameterProps = {
  parameters: ParametersResponse[];
};

const ParameterCard: React.FC<ParameterProps> = ({ parameters }) => {
  return (
    <>
      {parameters.map((parameter, index) => (
        <ServiceParameters key={parameter.id} parameter={parameter} isLastItem={parameters.length - 1 === index} />
      ))}
    </>
  );
};

export default ParameterCard;
