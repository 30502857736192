import React, { ReactNode } from 'react';

interface TechnicianDataRowPreviewProps {
  label: string;
  children?: ReactNode;
}

const TechnicianDataRowPreview: React.FC<TechnicianDataRowPreviewProps> = ({ children, label }) => (
  <div className='grid grid-cols-2 mb-4'>
    <div className='font-bold text-text ml-10 mr-3'>{label}</div>
    <div className='text-lightGrey'> {children}</div>
  </div>
);

export default TechnicianDataRowPreview;
