import React from 'react';
import { ContactListResponse } from 'src/api';
import ServiceContact from 'src/components/ServiceContact';

type ContactProps = {
  contacts: ContactListResponse[];
};

const ContactCard: React.FC<ContactProps> = ({ contacts }) => {
  return (
    <>
      {contacts.map((contact, index) => (
        <ServiceContact key={contact.id} contact={contact} isLastItem={contacts.length - 1 === index} />
      ))}
    </>
  );
};

export default ContactCard;
