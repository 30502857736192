import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Route, Routes, Outlet } from 'react-router-dom';
import { useOldApiClient } from 'src/api';
import { BranchResponse } from 'src/api/types/branches/BranchResponse';
import BranchForm from 'src/pages/ContactDetailPage/BranchForm';
import BranchPreview from 'src/pages/ContactDetailPage/BranchPreview';

const BranchesFetch: React.FC = () => {
  const { contactId, branchId } = useParams();
  const Client = useOldApiClient();
  const [dataInForm, setDataInForm] = useState<BranchResponse & { id: number }>();

  const getDataToForm = async (): Promise<void> => {
    const response = await Client.get(`/contact/${contactId}/branch/${branchId}`);
    setDataInForm(response.data);
  };

  useEffect(() => {
    getDataToForm();
  }, [branchId]);

  return (
    <>
      <Outlet />
      <Routes>
        <Route path='/preview' element={<BranchPreview data={dataInForm} />} />
        <Route
          path='/edit'
          element={
            dataInForm ? (
              <BranchForm
                branchId={dataInForm.id}
                defaultValues={{ ...dataInForm, name: dataInForm.name ?? undefined }}
              />
            ) : null
          }
        />
      </Routes>
    </>
  );
};

export default BranchesFetch;
