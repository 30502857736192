import React, { ReactNode } from 'react';

type MaterialFormCardProps = {
  children?: ReactNode;
};

const MaterialFormCard: React.FC<MaterialFormCardProps> = ({ children }) => (
  <div className='h-3/4 rounded-md shadow-md bg-white border-border border-inherit border'>{children}</div>
);

export default MaterialFormCard;
