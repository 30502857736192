import { useQueryClient } from '@tanstack/react-query';
import { isNil } from 'ramda';
import React, { useState } from 'react';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router';
import { OrderListItemResponse, useOldApiClient } from 'src/api';
import { ORDERS_CACHE_KEY } from 'src/api/hooks/useOrders';
import { UpdateOrderStatusRequest } from 'src/api/types/orders/UpdateOrderStatusRequest';
import InvoicingIcon from 'src/components/Icons/InvoicingIcon';
import Modal from 'src/components/Modal';
import EditButton from '../EditButton';
import RowButton from '../RowButton';
import Textfield from '../Textfield';

interface ServiceItemProps {
  item: OrderListItemResponse;
  isLastItem?: boolean;
}

const ServiceItem: React.FC<ServiceItemProps> = ({ item, isLastItem }) => {
  const queryClient = useQueryClient();

  const Client = useOldApiClient();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const date = isNil(item.datetime) ? '' : new Date(item.datetime).toLocaleDateString();

  const form = useForm<UpdateOrderStatusRequest>({
    defaultValues: {
      serviceInvoice: undefined,
    },
  });

  const { control, handleSubmit } = form;

  const onHandleCompletedService: SubmitHandler<UpdateOrderStatusRequest> = async (data) => {
    try {
      await Client.post(`/orders/${item.id}/actions/invoice`, {
        serviceInvoice: data.serviceInvoice,
      });
      queryClient.invalidateQueries([ORDERS_CACHE_KEY]);
    } catch (error) {
      console.error('test', error);
    } finally {
      setIsModalOpen(false);
    }
  };

  return (
    <div>
      <div
        onClick={() => {
          navigate(`/order/${item.id}/preview`);
        }}
        key={item.id}
        className={`h-12  flex bg-white  rounded-br-lg  rounded-bl-lg ${
          typeof item.id === 'number' && pathname?.match(/\d+/g)?.join('') === item.id.toString() ? 'bg-rose' : ''
        }`}
      >
        <div
          className={`text-text cursor-pointer pl-4 w-3/5 self-center border-l border-border h-12 border-b pt-3 ${
            isLastItem ? 'rounded-bl-lg border-b' : ''
          }`}
        >
          {item.id}
        </div>
        <div className='text-text cursor-pointer text-left  w-full border-b border-border pt-3 truncate'>
          {item.companyName}
        </div>
        <div className='text-text cursor-pointer text-left w-full  border-b border-border pt-3 truncate'>
          {item.technician?.name}
        </div>
        <div className='text-text cursor-pointer text-left w-3/5  border-b border-border pt-3 '>{date}</div>
        <div className='text-text cursor-pointer text-left w-6/12  border-b border-border pt-3 '>
          {item.status?.name === 'INVOICED' ? item.serviceInvoice : item.status?.label}
        </div>
        <div
          className={`text-text cursor-pointer w-1/5 h-12 pt-3 text-left truncate border-b border-r border-border ${
            isLastItem ? 'rounded-br-lg border-b' : ''
          } `}
        >
          {item.status?.name === 'COMPLETED' ? (
            <span
              onClick={(e) => {
                setIsModalOpen(true);
                e.stopPropagation();
              }}
            >
              <div className='flex justify-center'>
                <InvoicingIcon />
              </div>
            </span>
          ) : (
            ''
          )}
        </div>
      </div>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onHandleCompletedService)}>
          <Modal isOpen={isModalOpen} hideButtons>
            <div className='mb-4'>Opravdu chcete objednávky vyfakturovat?</div> Číslo faktury:
            <Controller control={control} name='serviceInvoice' render={({ field }) => <Textfield {...field} />} />
            <div className='flex mt-6'>
              <EditButton
                type='button'
                onClick={() => {
                  setIsModalOpen(false);
                }}
              >
                Storno
              </EditButton>
              <RowButton type='submit'>Dokončit</RowButton>
            </div>
          </Modal>
        </form>
      </FormProvider>
    </div>
  );
};

export default ServiceItem;
