import React from 'react';
import { FieldProps } from 'src/types/commonTypes';

export type MobileDescriptionProps = FieldProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement,
  string | number | null
>;

const MobileDescriptionField: React.ForwardRefRenderFunction<HTMLTextAreaElement, MobileDescriptionProps> = (
  props,
  ref,
) => {
  const { value, ...rest } = props;
  return (
    <textarea
      className='w-10/12 h-20 rounded-md border-2 border-border p-1'
      value={value ?? ''}
      {...rest}
      ref={ref}
    ></textarea>
  );
};

export default React.forwardRef(MobileDescriptionField);
