import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HorizontalRow from 'src/components/HorizontalRow';
import EditButton from 'src/components/EditButton';
import RowButton from '../../../components/RowButton';
import ProductFormCard from '../ProductFormCard';
import Textfield from 'src/components/Textfield';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { CreateProductRequest, useOldApiClient } from 'src/api';
import CreateProductsFormSchema, { CreateProductsFormValues } from './schema';
import DataRow from 'src/components/DataRow';
import Select from 'src/components/Select';
import { useBranches } from 'src/store/branchesContext';
import { useRoutineServices } from 'src/api/hooks/useRoutineServices';

interface ProductsFormProps {
  defaultValues?: Partial<CreateProductRequest>;
  productId?: number;
}

const ProductForm: React.FC<ProductsFormProps> = ({ defaultValues, productId }) => {
  const navigate = useNavigate();
  const { contactId, branchId } = useParams();
  const { branches } = useBranches();

  const routineServices = useRoutineServices();

  const Client = useOldApiClient();

  const form = useForm<CreateProductsFormValues>({
    resolver: yupResolver(CreateProductsFormSchema),
    defaultValues: defaultValues,
  });
  const { handleSubmit, control } = form;

  const onSubmit: SubmitHandler<CreateProductRequest> = async (data) => {
    try {
      if (productId) {
        const response = await Client.put(`/contact/${contactId}/branch/${branchId}/product/${productId}`, data);
        if (response.status === 200) {
          navigate(`/contact-detail/${contactId}`);
        }
      } else {
        const response = await Client.post(`/contact/${contactId}/branch/${data.branchId}/product`, data);
        if (response.status === 201) {
          navigate(`/contact-detail/${contactId}`);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='flex-col w-full mt-2 md:w-full md:pl-3 ntb:pl-10 lg:ml-4 ntb:ml-4'>
      <div className='mt-2  text-4xl text-text font-bold'>{productId ? 'Editace zařízení' : ' Nové zařízení'}</div>
      <FormProvider {...form}>
        <form className='lg:w-11/12 ntb:w-11/12 md:mr-5 flex-col' onSubmit={handleSubmit(onSubmit)}>
          <ProductFormCard>
            <div className='mt-2'>
              <DataRow label='Název'>
                <Controller
                  control={control}
                  name='productName'
                  render={({ field, fieldState }) => <Textfield {...field} error={fieldState.error?.message} />}
                />
              </DataRow>
              <HorizontalRow />
            </div>

            <DataRow label='Pobočka'>
              <Controller
                control={control}
                name='branchId'
                render={({ field, fieldState }) => (
                  <Select {...field} error={fieldState.error?.message}>
                    <option value=''></option>
                    {branches.map((branch) => (
                      <option key={branch.id} value={branch?.id}>
                        {branch.name}
                      </option>
                    ))}
                  </Select>
                )}
              />
            </DataRow>
            <HorizontalRow />
            <DataRow label='Výrobce'>
              <Controller
                control={control}
                name='productProducer'
                render={({ field, fieldState }) => <Textfield {...field} error={fieldState.error?.message} />}
              />
            </DataRow>
            <HorizontalRow />
            <DataRow label='Seriové číslo'>
              <Controller
                control={control}
                name='productSerialNumber'
                render={({ field }) => <Textfield {...field} />}
              />
            </DataRow>
            <HorizontalRow />
            <DataRow label='Pravidelný servis'>
              <Controller
                control={control}
                name='routineServiceId'
                render={({ field }) => (
                  <Select {...field}>
                    <option value=''>NE</option>
                    {routineServices.data?.map((routineService) => (
                      <option key={routineService.id} value={routineService?.id}>
                        {routineService.routineName}
                      </option>
                    ))}
                  </Select>
                )}
              />
            </DataRow>
          </ProductFormCard>
          <div className='mt-5 grid place-items-end'>
            <div className='flex-row mr-4 mb-6'>
              <EditButton onClick={() => navigate(`/contact-detail/${contactId}`)}>Zavřít</EditButton>
              <RowButton type='submit'>Uložit změny</RowButton>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default ProductForm;
