/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { changePasswordRequest } from '../models/changePasswordRequest';
import type { CreateUserRequest } from '../models/CreateUserRequest';
import type { UserListResponse } from '../models/UserListResponse';
import type { UserResponse } from '../models/UserResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns UserResponse
   * @throws ApiError
   */
  public create({ requestBody }: { requestBody: CreateUserRequest }): CancelablePromise<UserResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/user',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns UserListResponse
   * @throws ApiError
   */
  public getUserList({
    email,
  }: {
    /**
     * User email
     */
    email?: string;
  }): CancelablePromise<Array<UserListResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/user',
      query: {
        email: email,
      },
    });
  }

  /**
   * @returns UserResponse
   * @throws ApiError
   */
  public getUser({ id }: { id: number }): CancelablePromise<UserResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/user/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns UserResponse
   * @throws ApiError
   */
  public deleteUser({ id }: { id: number }): CancelablePromise<UserResponse> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/user/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns UserResponse
   * @throws ApiError
   */
  public restoreUser({ id }: { id: number }): CancelablePromise<UserResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/user/{id}/restore-user',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public changePassword({ requestBody }: { requestBody: changePasswordRequest }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/user/change-password',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
