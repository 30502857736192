import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import EditButton from 'src/components/EditButton';
import RowButton from '../../../components/RowButton';
import Textfield from 'src/components/Textfield';
import { useOldApiClient } from 'src/api';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CreateContactFormSchema from './schema';
import { CreateContactRequest } from 'src/api/types/contact/CreateContactRequest';
import ContactFormCard from '../ContactFormCard';
import { UpdateContactRequest } from 'src/api/types/contact/UpdateContactRequest';
import HorizontalRow from 'src/components/HorizontalRow';
import Checkbox from 'src/components/Checkbox';
import Modal from 'src/components/Modal';
import ClearIcon from 'src/components/Icons/ClearIcon';
import { AxiosError } from 'axios';
import ServiceFormDataRow from 'src/components/ServiceFormDataRow';
import Select from 'src/components/Select';

interface ContactFormProps {
  defaultValues?: Partial<CreateContactRequest>;
  contactId?: number;
  deletedAt?: string | null;
}

const ContactForm: React.FC<ContactFormProps> = ({ defaultValues, contactId, deletedAt }) => {
  const navigate = useNavigate();
  const Client = useOldApiClient();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const form = useForm<CreateContactRequest & UpdateContactRequest>({
    resolver: yupResolver(CreateContactFormSchema),
    defaultValues: defaultValues,
  });
  const { handleSubmit, control } = form;

  const CONTACT_ROUTE = '/contact';

  const onSubmit: SubmitHandler<CreateContactRequest & UpdateContactRequest> = async (data) => {
    try {
      if (data.deletedAt) {
        const response = await Client.delete(`/contact/${contactId}`);
        if (response.status === 200) {
          navigate(CONTACT_ROUTE);
        }
      }
      if (contactId) {
        const response = await Client.put(`contact/${contactId}`, {
          companyName: data.companyName,
          client: data.client,
          email: data.email,
          city: data.city,
          street: data.street,
          zip: data.zip,
          vat: data.vat,
          phoneNumber: data.phoneNumber,
          in: data.in,
          notification: data.notification,
        });
        if (response.status === 200) {
          navigate(CONTACT_ROUTE);
        }
      } else {
        const response = await Client.post('contact', {
          in: data.in,
          vat: data.vat,
          companyName: data.companyName,
          client: data.client,
          email: data.email,
          city: data.city,
          street: data.street,
          zip: data.zip,
          phoneNumber: data.phoneNumber,
          notification: data.notification,
        });
        if (response.status === 201) {
          navigate(CONTACT_ROUTE);
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 409) {
          setIsModalOpen(true);
        }
      }
    }
  };

  return (
    <div className='flex-col w-2/4 md:w-full md:pl-3 md:pl-3 ntb:ml-4 lg:ml-4 ntb:ml-4'>
      <div className='mt-4 mb-2 text-4xl text-text font-bold '>
        {contactId ? 'Editace zákazníka' : ' Nový zákazník'}
      </div>
      <FormProvider {...form}>
        <form className='lg:w-11/12 mt-2 ntb:w-11/12 md:mr-5 flex-col' onSubmit={handleSubmit(onSubmit)}>
          <ContactFormCard>
            <div className='grid grid-cols-2 mb-4 mt-2'>
              <div className='font-bold text-text ml-4 pt-2'>IČO</div>
              {contactId ? (
                <div className='pt-2'> {defaultValues?.in} </div>
              ) : (
                <Controller
                  control={control}
                  name='in'
                  render={({ field, fieldState }) => <Textfield {...field} error={fieldState.error?.message} />}
                />
              )}
            </div>
            <HorizontalRow />

            <div className='grid grid-cols-2 mb-2'>
              <div className='font-bold text-text ml-4 mr-6'>DIČ</div>
              <Controller control={control} name='vat' render={({ field }) => <Textfield {...field} />} />
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-2'>
              <div className='font-bold text-text ml-4 mr-6'>Název společnosti</div>
              <Controller
                control={control}
                name='companyName'
                render={({ field, fieldState }) => <Textfield {...field} error={fieldState.error?.message} />}
              />
            </div>
            <HorizontalRow />

            <div className='grid grid-cols-2 mb-2'>
              <div className='font-bold text-text ml-4 mr-6'>Email</div>
              <Controller
                control={control}
                name='email'
                render={({ field, fieldState }) => <Textfield {...field} error={fieldState.error?.message} />}
              />
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-2'>
              <div className='font-bold text-text ml-4 mr-6'>Telefonní číslo</div>
              <Controller
                control={control}
                name='phoneNumber'
                render={({ field, fieldState }) => <Textfield {...field} error={fieldState.error?.message} />}
              />
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-2'>
              <div className='font-bold text-text ml-4 mr-6'>Město</div>
              <Controller
                control={control}
                name='city'
                render={({ field, fieldState }) => <Textfield {...field} error={fieldState.error?.message} />}
              />
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-2'>
              <div className='font-bold text-text ml-4 mr-6'>Ulice</div>
              <Controller
                control={control}
                name='street'
                render={({ field, fieldState }) => <Textfield {...field} error={fieldState.error?.message} />}
              />
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-2'>
              <div className='font-bold text-text ml-4 mr-6'>PSČ</div>
              <Controller
                control={control}
                name='zip'
                render={({ field, fieldState }) => <Textfield {...field} error={fieldState.error?.message} />}
              />
            </div>
            <HorizontalRow />
            <ServiceFormDataRow label='Zasílání notifikací*'>
              <Controller
                control={control}
                name='notification'
                render={({ field }) => {
                  const value = field?.value === true ? 'yes' : 'no';
                  const onChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
                    field.onChange(e.target.value === 'yes');
                  };
                  return (
                    <>
                      <Select {...field} value={value} onChange={onChange}>
                        <option value={'yes'}>ANO</option>
                        <option value={'no'}>NE</option>)
                      </Select>
                    </>
                  );
                }}
              />
            </ServiceFormDataRow>
            {contactId ? (
              deletedAt === null ? (
                <>
                  <HorizontalRow />
                  <div className='grid grid-cols-2 mb-4 mt-2'>
                    <div className='font-bold text-text ml-4 mb-2'>Neaktivní společnost</div>
                    <div className='text-lightGrey'>
                      <Controller control={control} name='deletedAt' render={({ field }) => <Checkbox {...field} />} />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <HorizontalRow />
                  <div className='grid grid-cols-2 mb-4 mt-2'>
                    <div className='font-bold text-text ml-4 mb-2'>Neaktivní společnost</div>
                    <div className='text-lightGrey'>Ano</div>
                  </div>
                </>
              )
            ) : null}
          </ContactFormCard>
          <div className='mt-5 grid place-items-end'>
            <div className='flex-row mr-4 mb-6'>
              <EditButton onClick={() => navigate('/contact')}>Zavřít</EditButton>
              <RowButton type='submit'>Uložit změny</RowButton>
            </div>
          </div>
        </form>
      </FormProvider>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} hideButtons>
        <button onClick={() => setIsModalOpen(false)}>
          <div className='justify-end flex mb-2'>
            <ClearIcon />
          </div>
        </button>
        <div className='flex-col'>Zadaný kontakt již existuje. Kontakt nebyl uložen.</div>
      </Modal>
    </div>
  );
};

export default ContactForm;
