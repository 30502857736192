import { Route, Routes } from 'react-router';
import HomePage from './pages/HomePage';
import PaymentMethodPage from './pages/PaymentMethodPage';
import SettingPage from './pages/SettingsPage';
import TechnicianPage from './pages/TechnicianPage';
import UserPage from './pages/Users';
import LicensePage from './pages/LicensePage';
import { Modules, useLicenses } from './store/licenseContext';
import ParametersPage from './pages/ParametersPage';
import MaterialPage from './pages/MaterialPage';
import ContactPage from './pages/ContactPage';
import ContactDetailPage from './pages/ContactDetailPage';
import RoutineServicesPage from './pages/RoutineServices';
import NotificationPage from './pages/NotificationPage';

export const AppRouter: React.FC = () => {
  const { getIsModuleValid, hasLicense } = useLicenses();

  return (
    <Routes>
      <Route path='/*' element={hasLicense() ? <HomePage /> : <LicensePage />} />

      {getIsModuleValid(Modules.ADMINISTRATION) && (
        <>
          <Route path='/settings' element={<SettingPage />} />
          <Route path='/user/*' element={<UserPage />} />
          <Route path='/payment-method/*' element={<PaymentMethodPage />} />
          <Route path='/technician/*' element={<TechnicianPage />} />
          <Route path='/parameter/*' element={<ParametersPage />} />
          <Route path='/material/*' element={<MaterialPage />} />
          <Route path='/routine-services/*' element={<RoutineServicesPage />} />
          <Route path='/notification/*' element={<NotificationPage />} />
        </>
      )}
      {getIsModuleValid(Modules.CONTACTS) && (
        <>
          <Route path='/contact/*' element={<ContactPage />} />
          <Route path='/contact-detail/:contactId/*' element={<ContactDetailPage />} />
        </>
      )}
    </Routes>
  );
};
