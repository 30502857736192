import React, { createContext, useContext, useState } from 'react';
import { MaterialResponse } from 'src/api/types/material/MaterialResponse';

interface MaterialsContextProps {
  materials: MaterialResponse[];
  setMaterials: React.Dispatch<React.SetStateAction<MaterialResponse[]>>;
}

const defaultState: MaterialsContextProps = {
  materials: [],
  setMaterials: () => {
    return;
  },
};

interface MaterialsContextProviderProps {
  children: React.ReactNode;
}

export const MaterialsContext = createContext<MaterialsContextProps>(defaultState);

export const MaterialsProvider: React.FC<MaterialsContextProviderProps> = ({ children }) => {
  const [materials, setMaterials] = useState<MaterialResponse[]>([]);

  return <MaterialsContext.Provider value={{ materials, setMaterials }}>{children}</MaterialsContext.Provider>;
};

export const useMaterials = (): MaterialsContextProps => useContext(MaterialsContext);
