import { clone } from 'ramda';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams, Route, Routes } from 'react-router-dom';
import { ProductListResponse, useOldApiClient } from 'src/api';
import FilterIcon from 'src/components/Icons/FilterIcon';
import ProductsFetch from 'src/components/ProductsFetch';
import RowButton from 'src/components/RowButton'; /* 
import { useBranches } from 'src/store/branchesContext'; */
import { Modules, useLicenses } from 'src/store/licenseContext';
import { StatusProvider } from 'src/store/statusContext';
import { sortArrayOfObjects } from 'src/utils/sortArrayOfObjects';
import ProductsCard from '../ProductCard';
import ProductForm from '../ProductForm';
import ProductsTableHeader, { ServiceHeaderFilterProducts } from '../ProductTableHeader';

export type SortType = {
  path: string[];
  direction: 'asc' | 'desc';
  type: 'number' | 'string';
};

const getHeightOfFormByPathname = (pathname: string): string => {
  if (pathname.endsWith('/new') || pathname.endsWith('/edit')) {
    return 'lg:h-[calc(100vh-8.5rem)] ntb:h-[calc(100vh-8.8rem)]';
  }
  if (pathname.endsWith('/preview')) {
    return 'lg:h-[calc(100vh-8.5rem)] ntb:h-[calc(100vh-8.8rem)]';
  }
  return 'lg:h-[calc(100vh-8.8rem)] ntb:h-[calc(100vh-8.8rem)]';
};

const Products: React.FC = () => {
  const { getIsModuleValid } = useLicenses();
  const Client = useOldApiClient();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { contactId } = useParams();
  const [isFilterOpen, setIsFilterOpen] = useState<string | undefined>(undefined);
  const [currentFilters, setCurrentFilters] = useState<ServiceHeaderFilterProducts>();
  const [products, setProducts] = useState<ProductListResponse[]>([]);
  const [currentSort, setCurrentSort] = useState<SortType>({ path: ['id'], type: 'number', direction: 'desc' });

  const handleFilterProducts = async (queryString: ServiceHeaderFilterProducts): Promise<void> => {
    const response = await Client.get(`/contact/${contactId}/products`, { params: queryString });
    setProducts(sortArrayOfObjects(clone(response.data), currentSort.path, currentSort.direction, currentSort.type));
    setCurrentFilters(queryString);
  };

  const getProducts = async (): Promise<void> => {
    const response = await Client.get(`/contact/${contactId}/products`, { params: currentFilters });
    setProducts(sortArrayOfObjects(clone(response.data), currentSort.path, currentSort.direction, currentSort.type));
  };

  const handleSortProducts = (newItems: ProductListResponse[], newSortObj: SortType): void => {
    const sortedArr = sortArrayOfObjects(clone(newItems), newSortObj.path, newSortObj.direction, newSortObj.type);
    setCurrentSort(newSortObj);
    setProducts(sortedArr);
  };

  useEffect(() => {
    getProducts();
  }, [pathname]);

  return (
    <>
      <StatusProvider>
        {!getIsModuleValid(Modules.CONTACTS) && <Navigate to='/contact' />}

        <div className='flex-col'>
          <div className='flex mb-2'>
            <div className='text-4xl text-text font-bold flex w-full ml-2 pt-2'>
              Zařízení
              <button
                onClick={() => {
                  setIsFilterOpen(isFilterOpen === 'product' ? undefined : 'product');
                }}
              >
                <div className='mt-3 ml-2'>
                  <FilterIcon />
                </div>
              </button>
            </div>
            <div className='mt-4 md:mr-6'>
              {pathname === `/contact-detail/${contactId}` && (
                <RowButton
                  onClick={() => {
                    navigate(`new-product`);
                  }}
                >
                  Nové zařízení
                </RowButton>
              )}
            </div>
          </div>
          <ProductsTableHeader
            sortProducts={products}
            handleSortProducts={handleSortProducts}
            isFilterOpen={isFilterOpen === 'product'}
            handleFilterProducts={handleFilterProducts}
          />
          <div
            className={`${getHeightOfFormByPathname(
              pathname,
            )} md:h-[12rem] ntb:h-[12rem] lg:h-[15rem] overflow-y-scroll rounded-b-lg`}
          >
            {' '}
            <ProductsCard products={products} />
          </div>
        </div>
        <Routes>
          <Route path='/new-product' element={<ProductForm />} />
          <Route path='branch/:branchId/product/:productId/*' element={<ProductsFetch />} />
        </Routes>
      </StatusProvider>
    </>
  );
};

export default Products;
