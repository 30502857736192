import React from 'react';

import { FieldProps } from 'src/types/commonTypes';
export type SelectMobileProps = FieldProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement,
  string | number | null
>;

const SelectMobile = React.forwardRef((props: SelectMobileProps, ref: React.Ref<HTMLSelectElement>) => {
  const { value, ...rest } = props;
  return (
    <select
      className='mr-12 text-black md:w-10/12 font-medium h-9 rounded-md border-2 border-border pl-2 lg:w-5/6 ntb:w-5/6'
      value={value ?? ''}
      ref={ref}
      {...rest}
    >
      {props.children}
    </select>
  );
});

SelectMobile.displayName = 'SelectMobile';

export default SelectMobile;
