import React from 'react';
import { Outlet } from 'react-router-dom';
import ChangePasswordPage from './ChangePassword';

const SettingPage: React.FC = () => {
  return (
    <>
      <div className='flex lg:w-full ntb:w-full md:flex-col md:w-full'>
        <div className='flex-col w-2/4 md:w-full'>
          <div className='flex justify-between'>
            <div className='ml-6 mt-4 text-4xl text-text font-bold'>Nastavení</div>
          </div>
          <div className='ml-6 mt-4'>
            <div className='lg:ml-6 lg:mt-4 md:w-full md:p-3 ntb:ml-6 ntb:mt-4'>
              <div className='w-10/12'>
                <ChangePasswordPage />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default SettingPage;
