import React, { ReactNode } from 'react';

type RoutineServicesFormCardProps = {
  children?: ReactNode;
};

const RoutineServicesFormCard: React.FC<RoutineServicesFormCardProps> = ({ children }) => (
  <div className='h-3/4 rounded-md shadow-md bg-white border-border border-inherit border'>{children}</div>
);

export default RoutineServicesFormCard;
