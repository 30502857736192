/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateProductRequest } from '../models/CreateProductRequest';
import type { ProductListResponse } from '../models/ProductListResponse';
import type { ProductResponse } from '../models/ProductResponse';
import type { UpdateProductRequest } from '../models/UpdateProductRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProductService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns ProductResponse
   * @throws ApiError
   */
  public createProduct({
    contactId,
    branchId,
    requestBody,
  }: {
    contactId: number;
    branchId: number;
    requestBody: CreateProductRequest;
  }): CancelablePromise<ProductResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/contact/{contactId}/branch/{branchId}/product',
      path: {
        contactId: contactId,
        branchId: branchId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ProductListResponse
   * @throws ApiError
   */
  public getProductList({
    contactId,
    branchId,
    productName,
    withDeleted,
  }: {
    contactId: number;
    branchId: number;
    /**
     * Product name
     */
    productName?: string;
    /**
     * With deleted
     */
    withDeleted?: boolean;
  }): CancelablePromise<ProductListResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/contact/{contactId}/branch/{branchId}/product',
      path: {
        contactId: contactId,
        branchId: branchId,
      },
      query: {
        productName: productName,
        withDeleted: withDeleted,
      },
    });
  }

  /**
   * @returns ProductResponse
   * @throws ApiError
   */
  public getProduct({
    contactId,
    branchId,
    id,
  }: {
    contactId: number;
    branchId: number;
    id: number;
  }): CancelablePromise<ProductResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/contact/{contactId}/branch/{branchId}/product/{id}',
      path: {
        contactId: contactId,
        branchId: branchId,
        id: id,
      },
    });
  }

  /**
   * @returns ProductResponse
   * @throws ApiError
   */
  public updateProduct({
    contactId,
    branchId,
    id,
    requestBody,
  }: {
    contactId: number;
    branchId: number;
    id: number;
    requestBody: UpdateProductRequest;
  }): CancelablePromise<ProductResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/contact/{contactId}/branch/{branchId}/product/{id}',
      path: {
        contactId: contactId,
        branchId: branchId,
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ProductResponse
   * @throws ApiError
   */
  public deleteProduct({
    contactId,
    branchId,
    id,
  }: {
    contactId: number;
    branchId: number;
    id: number;
  }): CancelablePromise<ProductResponse> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/contact/{contactId}/branch/{branchId}/product/{id}',
      path: {
        contactId: contactId,
        branchId: branchId,
        id: id,
      },
    });
  }
}
