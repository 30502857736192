/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LicenceResponse } from '../models/LicenceResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LicencesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns LicenceResponse
   * @throws ApiError
   */
  public getLicences(): CancelablePromise<Array<LicenceResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/licences',
    });
  }
}
