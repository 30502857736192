import React, { createContext, useContext, useState } from 'react';
import { ContactResponse } from 'src/api/types/contact/ContactResponse';

interface ContactsContextProps {
  contacts: ContactResponse[];
  setContacts: React.Dispatch<React.SetStateAction<ContactResponse[]>>;
}

const defaultState: ContactsContextProps = {
  contacts: [],
  setContacts: () => {
    return;
  },
};

interface ContactsContextProviderProps {
  children: React.ReactNode;
}

export const ContactsContext = createContext<ContactsContextProps>(defaultState);

export const ContactsProvider: React.FC<ContactsContextProviderProps> = ({ children }) => {
  const [contacts, setContacts] = useState<ContactResponse[]>([]);

  return <ContactsContext.Provider value={{ contacts, setContacts }}>{children}</ContactsContext.Provider>;
};

export const useContacts = (): ContactsContextProps => useContext(ContactsContext);
