import { clone } from 'ramda';
import React, { useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { ContactServiceListsResponse } from 'src/api';
import { useServiceLists } from 'src/api/hooks/useServiceLists';
import FilterIcon from 'src/components/Icons/FilterIcon';
import { Modules, useLicenses } from 'src/store/licenseContext';
import { StatusProvider } from 'src/store/statusContext';
import { sortArrayOfObjects } from 'src/utils/sortArrayOfObjects';
import HistoryServiceCard from '../HistoryServicesCard';
import HistoryTableHeader, { ServiceHeaderFilter } from '../HistoryTableHeader';

export type SortType = {
  path: string[];
  direction: 'asc' | 'desc';
  type: 'number' | 'string';
};

const getHeightOfFormByPathname = (pathname: string): string => {
  if (pathname.endsWith('/new') || pathname.endsWith('/edit')) {
    return 'lg:h-[calc(100vh-8.5rem)] ntb:h-[calc(100vh-8.8rem)]';
  }
  if (pathname.endsWith('/preview')) {
    return 'lg:h-[calc(100vh-8.5rem)] ntb:h-[calc(100vh-8.8rem)]';
  }
  return 'lg:h-[calc(100vh-8.8rem)] ntb:h-[calc(100vh-8.8rem)]';
};

const HistoryServices: React.FC = () => {
  const { pathname } = useLocation();
  const { getIsModuleValid } = useLicenses();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [currentFilters, setCurrentFilters] = useState<ServiceHeaderFilter>();
  const [currentSort, setCurrentSort] = useState<SortType>({ path: ['id'], type: 'number', direction: 'desc' });
  const { contactId } = useParams();

  const { data } = useServiceLists(contactId as unknown as number, currentFilters);

  const sortedItems = sortArrayOfObjects(clone(data ?? []), currentSort.path, currentSort.direction, currentSort.type);

  const handleFilter = async (queryString: ServiceHeaderFilter): Promise<void> => {
    setCurrentFilters(queryString);
  };

  const handleSort = (newItems: ContactServiceListsResponse[], newSortObj: SortType): void => {
    setCurrentSort(newSortObj);
  };

  return (
    <>
      <StatusProvider>
        {!getIsModuleValid(Modules.CONTACTS) && <Navigate to='/contact' />}

        <div className='flex-col ml-2'>
          <div className='flex mb-2'>
            <div className='text-4xl text-text font-bold flex w-full  pt-2'>
              Historie
              <button
                onClick={() => {
                  setIsFilterOpen(!isFilterOpen);
                }}
              >
                <div className='mt-3 ml-2'>
                  <FilterIcon />
                </div>
              </button>
            </div>
          </div>
          <div className='mr-4'>
            <HistoryTableHeader
              sortItems={sortedItems}
              handleSort={handleSort}
              isFilterOpen={isFilterOpen}
              handleFilter={handleFilter}
            />
            <div
              className={`${getHeightOfFormByPathname(
                pathname,
              )} md:h-[12rem] ntb:h-[12rem] lg:h-[15rem] overflow-y-scroll rounded-b-lg`}
            >
              {' '}
              <HistoryServiceCard historyDatas={sortedItems} />
            </div>
          </div>
        </div>
      </StatusProvider>
    </>
  );
};

export default HistoryServices;
