import { useSelectContext } from '../context/selectContext';
import { SelectOption } from '../types';
import { SuggestionListItem } from './SuggestionListItem';

export const SuggestionsListComponent = <Option extends SelectOption, ReturnValue>(): React.ReactElement => {
  const { options } = useSelectContext<Option, ReturnValue>();

  return options.length ? (
    <ul className='absolute w-fit mt-10 border-solid border bg-background border-gray rounded-md'>
      {options.map((option, index) => (
        <SuggestionListItem key={index} isLast={options.length - 1 === index} option={option} />
      ))}
    </ul>
  ) : (
    <></>
  );
};
