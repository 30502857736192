import { pickAll } from 'ramda';
import { useCallback, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useOldApiClient } from 'src/api';
import { EmployeeResponse } from 'src/api/types/employee/EmployeeResponse';
import { ServiceFormValues } from './schema';

export const EmployeeWatcher: React.FC = () => {
  const Client = useOldApiClient();
  const { setValue, control } = useFormContext<ServiceFormValues>();
  const [employeeSelect, companyId] = useWatch({ name: ['employeeId', 'companyId'], control });

  const getFillEmployeeData = async (companyId: number, employeeId: number): Promise<EmployeeResponse> => {
    const fillBranchData = await Client.get(`/contact/${companyId}/employee/${employeeId}`);
    return fillBranchData.data;
  };

  const setEmployeeInfo = useCallback(
    (data?: EmployeeResponse) => {
      const fields = ['name', 'email', 'phoneNumber'] as const;

      const obj = pickAll<EmployeeResponse, Pick<EmployeeResponse, typeof fields[number]>>(
        fields,
        data ?? ({} as EmployeeResponse),
      );

      setValue('employeeId', data?.id ?? null);

      Object.keys(obj).forEach((key) => {
        const _key = key as keyof typeof obj;
        setValue(`employee.${_key}`, obj[_key] ?? null);
      });
    },
    [setValue],
  );

  const fillEmployeeData = (companyId: number, employeeId: number): void => {
    getFillEmployeeData(companyId, employeeId).then((data) => {
      setEmployeeInfo(data);
    });
  };

  useEffect(() => {
    if (employeeSelect != null && companyId != null) {
      fillEmployeeData(companyId, employeeSelect);
    }
  }, [employeeSelect, companyId]);

  return null;
};
