import React, { ReactNode } from 'react';

interface DataRowProps {
  label: string;
  children?: ReactNode;
}

const DataRow: React.FC<DataRowProps> = ({ children, label }) => (
  <div className='grid grid-cols-2 mb-2'>
    <div className='font-bold text-text ml-4 mr-6'>{label}</div>
    {children}
  </div>
);

export default DataRow;
