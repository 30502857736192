import React from 'react';
import { useNavigate, useLocation } from 'react-router';
import { RoutineServicesResponse } from 'src/api';

interface ServiceRoutineServicesProps {
  item: RoutineServicesResponse;
  isLastItem?: boolean;
}

const ServiceRoutineServices: React.FC<ServiceRoutineServicesProps> = ({ isLastItem, item }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div>
      <div
        onClick={() => {
          navigate(`/routine-services/${item.id}/preview`);
        }}
        key={item.id}
        className={`h-12  flex bg-white  rounded-br-lg  rounded-bl-lg ${
          typeof item.id === 'number' && pathname?.match(/\d+/g)?.join('') === item.id.toString() ? 'bg-rose' : ''
        }`}
      >
        <div
          className={`text-text cursor-pointer text-center w-full truncate self-center pt-3 border-l border-border h-12 border-b border-r border-border pt-3 ${
            isLastItem ? 'rounded-bl-lg  border-b' : ''
          }`}
        >
          {item.routineName}
        </div>
      </div>
    </div>
  );
};

export default ServiceRoutineServices;
