import React from 'react';
import { RoutineServicesResponse } from 'src/api';
import ServiceRoutineServices from 'src/components/ServiceRoutineServices';

type RoutineServicesProps = {
  items: RoutineServicesResponse[];
};

const RoutineServicesCard: React.FC<RoutineServicesProps> = ({ items }) => {
  return (
    <>
      {items.map((item, index) => (
        <ServiceRoutineServices key={item.id} item={item} isLastItem={items.length - 1 === index} />
      ))}
    </>
  );
};

export default RoutineServicesCard;
