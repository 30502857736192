import React from 'react';
import { FieldProps } from 'src/types/commonTypes';

export type MobileTextfieldProps = FieldProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement,
  string | number | null
>;

const MobileTextfield: React.ForwardRefRenderFunction<HTMLInputElement, MobileTextfieldProps> = (props, ref) => {
  const { value, error, ...rest } = props;
  return (
    <div className='flex-col  w-full'>
      <input
        className='w-11/12 h-9 rounded-md border-2 border-border pl-2 md:w-full'
        value={value ?? ''}
        {...rest}
        ref={ref}
      ></input>
      {error && (
        <p>
          <span className='text-primary'>{error}</span>
        </p>
      )}
    </div>
  );
};

export default React.forwardRef(MobileTextfield);
