import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Route, Routes } from 'react-router-dom';
import { useOldApiClient } from 'src/api';
import { EmployeeResponse } from 'src/api/types/employee/EmployeeResponse';
import EmployeeForm from 'src/pages/ContactDetailPage/EmployeeForm';
import EmployeePreview from 'src/pages/ContactDetailPage/EmployeePreview';

const EmployeeFetch: React.FC = () => {
  const { employeeId, contactId } = useParams();
  const Client = useOldApiClient();
  const [dataInForm, setDataInForm] = useState<EmployeeResponse & { id: number }>();

  const getDataToForm = async (): Promise<void> => {
    if (employeeId) {
      const response = await Client.get(`contact/${contactId}/employee/${employeeId}`);
      setDataInForm(response.data);
    }
  };

  useEffect(() => {
    getDataToForm();
  }, [employeeId]);

  return (
    <>
      <Routes>
        <Route path='/preview' element={<EmployeePreview data={dataInForm} />} />
        <Route
          path='/edit'
          element={dataInForm ? <EmployeeForm employeeId={dataInForm.id} defaultValues={dataInForm} /> : null}
        />
      </Routes>
    </>
  );
};

export default EmployeeFetch;
