import React, { ReactNode } from 'react';

type ParameterFormCardProps = {
  children?: ReactNode;
};

const ParameterFormCard: React.FC<ParameterFormCardProps> = ({ children }) => (
  <div className='h-3/4 rounded-md shadow-md bg-white border-border border-inherit border'>{children}</div>
);

export default ParameterFormCard;
