import React from 'react';
import { useNavigate, useLocation } from 'react-router';
import { PaymentMethodResponse } from '../../api/types/paymentMethods/PaymentMethodResponse';

interface ServicePaymentMethodProps {
  paymentMethod: PaymentMethodResponse;
  isLastItem?: boolean;
}

const ServicePaymentMethod: React.FC<ServicePaymentMethodProps> = ({ isLastItem, paymentMethod }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div>
      <div
        onClick={() => {
          navigate(`/payment-method/${paymentMethod.id}/preview`);
        }}
        key={paymentMethod.id}
        className={`h-12  flex bg-white  rounded-br-lg  rounded-bl-lg ${
          typeof paymentMethod.id === 'number' && pathname?.match(/\d+/g)?.join('') === paymentMethod.id.toString()
            ? 'bg-rose'
            : ''
        }`}
      >
        <div
          className={`text-text cursor-pointer text-center w-full self-center pt-3 border-l border-r border-border h-12 border-b border-border pt-3 ${
            isLastItem ? 'rounded-bl-lg rounded-br-lg border-b' : ''
          }`}
        >
          {paymentMethod.name}
        </div>
      </div>
    </div>
  );
};

export default ServicePaymentMethod;
