import { isNil } from 'ramda';
import React from 'react';
import { ProductServiceHistory } from 'src/api';
import DocumentTextIcon from '../Icons/DocumentTextIcon';

interface ServiceProductsProps {
  historyServices: ProductServiceHistory;
  isLastItem?: boolean;
}

const ServiceHistoryProducts: React.FC<ServiceProductsProps> = ({ isLastItem, historyServices }) => {
  const date = isNil(historyServices.serviceDate) ? '' : new Date(historyServices.serviceDate).toLocaleDateString();

  return (
    <div>
      <div className='h-12  flex bg-white  rounded-br-lg  rounded-bl-lg'>
        <div
          className={`text-text cursor-pointer text-center w-full self-center pt-3 border-l border-border h-12 border-b border-border pt-3 ${
            isLastItem ? 'rounded-bl-lg  border-b' : ''
          }`}
        >
          {date}
        </div>
        <div
          className={`text-text cursor-pointer text-center w-full self-center pt-3 border-r border-border h-12 border-b border-border pt-3 ${
            isLastItem ? ' rounded-br-lg border-b' : ''
          }`}
        >
          <a href={`${historyServices?.serviceList}`} target='blank'>
            <div className='flex justify-center'>
              <DocumentTextIcon />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ServiceHistoryProducts;
