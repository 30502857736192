import React from 'react';
import { useNavigate, useLocation } from 'react-router';
import { EmployeeResponse } from 'src/api/types/employee/EmployeeResponse';

interface ServiceBranchesProps {
  employee: EmployeeResponse;
  isLastItem?: boolean;
}

const ServiceEmployee: React.FC<ServiceBranchesProps> = ({ isLastItem, employee }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div>
      <div
        onClick={() => {
          navigate(`employee/${employee.id}/preview`);
        }}
        key={employee.id}
        className={`h-12 md:pr-2 flex bg-white rounded-br-lg  rounded-bl-lg ${
          typeof employee.id === 'number' && pathname?.match(/\d+/g)?.join('') === employee.id.toString()
            ? 'bg-rose'
            : ''
        }`}
      >
        <div
          className={`text-text cursor-pointer text-center w-full ml-2 self-center pt-3 border-l border-border h-12 border-b border-border pt-3 ${
            isLastItem ? 'rounded-bl-lg  border-b' : ''
          }`}
        >
          {employee.email}
        </div>
        <div
          className={`text-text cursor-pointer text-center w-full self-center pt-3 border-r border-border h-12 border-b border-border pt-3 ${
            isLastItem ? 'rounded-br-lg rounded-br-lg border-b' : ''
          }`}
        >
          {employee.name}
        </div>
      </div>
    </div>
  );
};

export default ServiceEmployee;
