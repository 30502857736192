import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useOldApiClient } from 'src/api';
import { ChangePasswordRequest } from 'src/api/types/user/changePasswordRequest';
import Button from 'src/components/Button';
import Textfield from 'src/components/Textfield';
import changePasswordFormSchema, { ChangePasswordFormValues } from './schema';

const ChangePasswordPage: React.VFC = () => {
  const Client = useOldApiClient();

  const { handleSubmit, control } = useForm<ChangePasswordFormValues>({
    resolver: yupResolver(changePasswordFormSchema),
  });

  const onSubmit: SubmitHandler<ChangePasswordRequest> = async (data) => {
    try {
      const response = await Client.put('user/change-password', {
        userPsw: data.userPsw,
        userNewPsw: data.userNewPsw,
      });
      console.log(response);
    } catch (error) {
      console.error('test', error);
    }
  };

  return (
    <div className='grid'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid'>
          <div className=' text-3xl font-medium text-text mb-2 mt-8'>Změna hesla</div>
          <div className='mt-4'>
            <div className='text-text'>Heslo:</div>
            <Controller
              control={control}
              name='userPsw'
              render={({ field }) => <Textfield {...field} type='password' />}
            />
          </div>
          <div className='mt-4'>
            <div className='text-text'>Nové heslo:</div>
            <Controller
              control={control}
              name='userNewPsw'
              render={({ field }) => <Textfield {...field} type='password' />}
            />
          </div>
          <div className='mt-4'>
            <div className='text-text'>Nové heslo znovu:</div>
            <Controller
              control={control}
              name='userNewPswConfirm'
              render={({ field }) => <Textfield {...field} type='password' />}
            />
          </div>

          <div className='mt-4'>
            <Button type='submit'>Změnit heslo</Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChangePasswordPage;
