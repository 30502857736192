import React from 'react';
import { useNavigate } from 'react-router';
import { ProductListResponse } from 'src/api';

interface ServiceProductsProps {
  product: ProductListResponse;
  isLastItem?: boolean;
}

const ServiceProducts: React.FC<ServiceProductsProps> = ({ isLastItem, product }) => {
  const navigate = useNavigate();

  return (
    <div>
      <div
        onClick={() => {
          navigate(`branch/${product.branchId}/product/${product.id}/preview`);
        }}
        key={product.id}
        className='h-12  flex bg-white pl-2  rounded-br-lg  rounded-bl-lg'
      >
        <div
          className={`text-text cursor-pointer text-center w-full self-center pt-3 border-l border-r border-border h-12 border-b border-border pt-3 ${
            isLastItem ? 'rounded-bl-lg rounded-br-lg border-b' : ''
          }`}
        >
          {product.productName}
        </div>
      </div>
    </div>
  );
};

export default ServiceProducts;
