import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import ClearIcon from '../Icons/ClearIcon';

interface SignatureProps {
  handleSign: (sign: Blob | undefined) => void;
}

const Signature: React.FC<SignatureProps> = ({ handleSign }) => {
  const sigPad = useRef<SignatureCanvas | null>();

  const getSignatureRef = (element: SignatureCanvas | null): void => {
    sigPad.current = element;
  };

  const handleTrim = (): void => {
    sigPad.current?.getTrimmedCanvas().toBlob((blob) => {
      if (blob) {
        handleSign(blob);
      }
    });
  };

  const handleClear = (): void => {
    sigPad.current?.clear();
  };

  return (
    <>
      <div>
        <div className='border w-80 border-border mb-2 ml-4'>
          <SignatureCanvas
            penColor='black'
            ref={(ref) => getSignatureRef(ref)}
            canvasProps={{ width: 320, height: 200 }}
            onEnd={handleTrim}
          />
        </div>
        <button type='button' onClick={() => handleClear()}>
          <div className='flex ml-2'>
            <ClearIcon /> Vymazat
          </div>
        </button>
      </div>
    </>
  );
};

export default Signature;
