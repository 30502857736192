/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ParameterResponse } from '../models/ParameterResponse';
import type { ParameterUpdateRequest } from '../models/ParameterUpdateRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ParametersService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns ParameterResponse
   * @throws ApiError
   */
  public paramList({
    key,
    value,
    title,
    withDeleted,
  }: {
    /**
     * Parameter key
     */
    key?: string;
    /**
     * Parameter value
     */
    value?: string;
    /**
     * Parameter title
     */
    title?: string;
    /**
     * With deleted
     */
    withDeleted?: boolean;
  }): CancelablePromise<ParameterResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/parameters',
      query: {
        key: key,
        value: value,
        title: title,
        withDeleted: withDeleted,
      },
    });
  }

  /**
   * @returns ParameterResponse
   * @throws ApiError
   */
  public param({ key }: { key: string }): CancelablePromise<ParameterResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/parameters/{key}',
      path: {
        key: key,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public paramUpdate({
    key,
    requestBody,
  }: {
    key: string;
    requestBody: ParameterUpdateRequest;
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/parameters/{key}',
      path: {
        key: key,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
