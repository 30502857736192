import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditButton from 'src/components/EditButton';
import RowButton from '../../../components/RowButton';
import { RoutineServicesResponse } from 'src/api';
import { SubmitHandler, useForm } from 'react-hook-form';
import RoutineServicesFormCard from '../RoutineServicesFormCard';
import { useDeleteRoutineServiceMutation } from 'src/api/hooks/useRoutineServices';

interface PreviewRoutineServiceProps {
  data?: RoutineServicesResponse & { id: number };
}

const RoutineServicesPreview: React.FC<PreviewRoutineServiceProps> = ({ data }) => {
  const navigate = useNavigate();
  const { routineServiceId } = useParams();
  const deleteRoutineService = useDeleteRoutineServiceMutation();

  const form = useForm<RoutineServicesResponse>({
    defaultValues: data,
  });

  const ROUTINE_SERVICES_ROUTE = '/routine-services';

  const { handleSubmit } = form;

  const onSubmit: SubmitHandler<RoutineServicesResponse> = async (data) => {
    try {
      await deleteRoutineService.mutateAsync(data);

      navigate(ROUTINE_SERVICES_ROUTE);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='flex-col w-2/4 md:w-full md:pl-3 ml-4'>
      <div className='flex w-11/12'>
        <div className='grid grid-row-2 mb-2 w-full'>
          <div className='flex w-full'>
            <div className='mt-4 text-4xl text-text font-bold flex w-full'>Pravidelný servis</div>

            <div className='flex flex-row items-center pt-4'>
              <EditButton onClick={() => navigate('/routine-services')}>Zpět</EditButton>
              <RowButton onClick={() => navigate(`/routine-services/${routineServiceId}/edit`)}>Upravit</RowButton>
            </div>
          </div>
        </div>
      </div>
      <form className='w-11/12' onSubmit={handleSubmit(onSubmit)}>
        <RoutineServicesFormCard>
          <div className='grid grid-cols-2 mb-4 mt-2'>
            <div className='font-bold text-text ml-4 mr-1'>Název</div>
            <div className='text-lightGrey'>{data?.routineName}</div>
          </div>
          <div className='grid grid-cols-2 mb-4 mt-2'>
            <div className='font-bold text-text ml-4 mr-1'>Počet dnů</div>
            <div className='text-lightGrey'> {data?.routineValue} </div>
          </div>
        </RoutineServicesFormCard>
        <div className='mt-5 grid place-items-end'>
          <div className='flex-row mr-4 mb-6'>
            <RowButton type='submit'>Odstranit</RowButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RoutineServicesPreview;
