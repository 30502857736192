import React from 'react';
import { useNavigate } from 'react-router-dom';
import HorizontalRow from 'src/components/HorizontalRow';
import EditButton from 'src/components/EditButton';
import RowButton from '../../../components/RowButton';
import TechnicianFormCard from '../TechnicianFormCard';
import Textfield from 'src/components/Textfield';
import CreateTechnicianFormSchema, { CreateTechnicianFormValues } from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { CreateTechnicianRequest } from 'src/api/types/technician/CreateTechnicianRequest';
import UpdateTechnicianFormSchema from './updateSchema';
import { useOldApiClient } from 'src/api';
import { UpdateTechnicianRequest } from 'src/api/types/technician/UpdateTechnicianRequest';

interface TechnicianFormProps {
  defaultValues?: Partial<CreateTechnicianRequest>;
  technicianId?: number;
}

const TechnicianForm: React.FC<TechnicianFormProps> = ({ defaultValues, technicianId }) => {
  const navigate = useNavigate();

  const Client = useOldApiClient();

  const form = useForm<CreateTechnicianFormValues & UpdateTechnicianRequest>({
    resolver: yupResolver(technicianId ? UpdateTechnicianFormSchema : CreateTechnicianFormSchema),
    defaultValues: defaultValues,
  });
  const { handleSubmit, control } = form;

  const onSubmit: SubmitHandler<CreateTechnicianRequest & UpdateTechnicianRequest> = async (data) => {
    try {
      if (technicianId) {
        const response = await Client.put(`technician/${technicianId}`, {
          technicianName: data.technicianName,
        });
        if (response.status === 200) {
          navigate('/technician');
        }
      } else {
        const response = await Client.post('technician', {
          technicianEmail: data.technicianEmail,
          technicianName: data.technicianName,
        });
        if (response.status === 201) {
          navigate('/technician');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='flex-col w-2/4 md:w-full md:pl-3 ntb:ml-4 lg:ml-4 ntb:ml-4'>
      <div className='ml-6 mt-2 mb-2 text-4xl text-text font-bold'>
        {technicianId ? 'Editace technika' : ' Nový technik'}
      </div>
      <FormProvider {...form}>
        <form className='lg:w-11/12 mt-4 ntb:w-11/12 md:mr-5 flex-col' onSubmit={handleSubmit(onSubmit)}>
          <TechnicianFormCard>
            <div className='grid grid-cols-2 mb-4 mt-2'>
              <div className='font-bold text-text ml-4 mb-2'>Email</div>
              {technicianId ? (
                <div> {defaultValues?.technicianEmail} </div>
              ) : (
                <Controller control={control} name='technicianEmail' render={({ field }) => <Textfield {...field} />} />
              )}
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-2'>
              <div className='font-bold text-text ml-4 mr-6'>Jméno</div>

              <Controller control={control} name='technicianName' render={({ field }) => <Textfield {...field} />} />
            </div>
          </TechnicianFormCard>
          <div className='mt-5 grid place-items-end'>
            <div className='flex-row mr-4 mb-6'>
              <EditButton onClick={() => navigate('/technician')}>Zavřít</EditButton>
              <RowButton type='submit'>Uložit změny</RowButton>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default TechnicianForm;
