import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Outlet, Route, Routes } from 'react-router-dom';
import { useOldApiClient } from 'src/api';
import { UserResponse } from 'src/api/types/user/UserResponse';
import UserEditForm from 'src/pages/Users/UserEditForm';
import UserPreview from 'src/pages/Users/UserPreview';

const UserFetch: React.FC = () => {
  const { userId } = useParams();
  const Client = useOldApiClient();
  const [dataInForm, setDataInForm] = useState<UserResponse & { id: number }>();

  const getDataToForm = async (): Promise<void> => {
    if (userId) {
      const response = await Client.get(`/user/${userId}`);
      setDataInForm(response.data);
    }
  };

  useEffect(() => {
    getDataToForm();
  }, [userId]);

  return (
    <>
      <Outlet />
      <Routes>
        <Route path='/preview' element={<UserPreview data={dataInForm} />} />
        <Route
          path='/edit'
          element={
            <UserEditForm
              data={
                dataInForm
                  ? { userEmail: dataInForm?.userEmail, id: dataInForm.id, isDeleted: !!dataInForm.deletedAt }
                  : undefined
              }
            />
          }
        />
      </Routes>
    </>
  );
};

export default UserFetch;
