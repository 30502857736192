import React from 'react';
import { useNavigate } from 'react-router-dom';
import EditButton from 'src/components/EditButton';
import RowButton from '../../../components/RowButton';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import NotificationFormCard from '../NotificationFormCard';
import DataRow from 'src/components/DataRow';
import Select from 'src/components/Select';
import DescriptionField from 'src/components/DescriptionField';
import { useUpdateNotificationMutation } from 'src/api/hooks/useNotificationSettings';
import { NotificationResponse } from 'src/api';
import { TIME_SELECT_VALUES } from 'src/pages/HomePage/ServiceForm/constants';

interface NotificationFormProps {
  data?: Partial<NotificationResponse>;
  notificationId: number;
}

const NotificationForm: React.FC<NotificationFormProps> = ({ data, notificationId }) => {
  const navigate = useNavigate();

  const updateNotification = useUpdateNotificationMutation();

  const form = useForm<NotificationResponse>({
    defaultValues: data,
  });

  const { handleSubmit, control } = form;

  const onSubmit: SubmitHandler<NotificationResponse> = async (data) => {
    try {
      await updateNotification.mutateAsync({ body: data, id: notificationId });
      navigate('/notification');
    } catch (error) {
      console.log('test', error);
    }
  };

  return (
    <div className='flex-col w-2/4 md:w-full md:pl-3 ntb:ml-4 lg:ml-4 ntb:ml-4'>
      <div className='mt-4 mb-2 text-4xl text-text font-bold'>Editace notifikace</div>
      <form className='lg:w-11/12 mt-2 ntb:w-11/12 md:mr-5 flex-col' onSubmit={handleSubmit(onSubmit)}>
        <NotificationFormCard>
          <div className='grid grid-cols-2 mb-4 mt-2'>
            <div className='font-bold text-text ml-4 mb-2'>Typ zprávy</div>
            <div> {data?.title} </div>
          </div>
          <DataRow label='Zpráva'>
            <Controller control={control} name='text' render={({ field }) => <DescriptionField {...field} />} />
          </DataRow>
          <DataRow label='Čas oznámení'>
            <Controller
              control={control}
              name='time'
              render={({ field }) => {
                return (
                  <Select {...field}>
                    {TIME_SELECT_VALUES.map(({ label, value }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </Select>
                );
              }}
            />
          </DataRow>
        </NotificationFormCard>
        <div className='mt-5 grid place-items-end'>
          <div className='flex-row mr-4 mb-6'>
            <EditButton onClick={() => navigate('/notification')}>Zavřít</EditButton>
            <RowButton type='submit'>Uložit změny</RowButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NotificationForm;
