import { pickAll } from 'ramda';
import { useCallback, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useOldApiClient } from 'src/api';
import { BranchResponse } from 'src/api/types/branches/BranchResponse';
import { useProducts } from 'src/store/productsContext';
import { ServiceFormValues } from './schema';

export const BranchesWatcher: React.FC = () => {
  const Client = useOldApiClient();
  const { setValue, control } = useFormContext<ServiceFormValues>();
  const { setProducts } = useProducts();
  const [branchSelect, companyId] = useWatch({ name: ['branchId', 'companyId'], control });

  const getFillBranchData = async (companyId: number, branchId: number): Promise<BranchResponse> => {
    const fillBranchData = await Client.get(`/contact/${companyId}/branch/${branchId}`);
    return fillBranchData.data;
  };

  const getProductData = async (branchId: number, companyId: number): Promise<void> => {
    const fillProductData = await Client.get(`/contact/${companyId}/branch/${branchId}/product`);
    setProducts(fillProductData.data);
  };

  const setBranchInfo = useCallback(
    (data?: BranchResponse) => {
      const fields = ['city', 'street', 'zip', 'vat', 'email', 'contactPerson', 'phoneNumber', 'name'] as const;

      const obj = pickAll<BranchResponse, Pick<BranchResponse, typeof fields[number]>>(
        fields,
        data ?? ({} as BranchResponse),
      );

      setValue('branchId', data?.id ?? null);

      Object.keys(obj).forEach((key) => {
        const _key = key as keyof typeof obj;
        setValue(`branch.${_key}`, obj[_key] ?? null);
      });
    },
    [setValue],
  );

  const fillBranchData = (companyId: number, branchId: number): void => {
    getFillBranchData(companyId, branchId).then((data) => {
      setBranchInfo(data);
    });
  };

  useEffect(() => {
    if (branchSelect != null && companyId != null) {
      getProductData(branchSelect, companyId);
    } else {
      setProducts([]);
    }
  }, [branchSelect, companyId]);

  useEffect(() => {
    if (branchSelect != null && companyId != null) {
      fillBranchData(companyId, branchSelect);
    }
  }, [branchSelect, companyId]);

  return null;
};
