import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useOldApiClient } from 'src/api';
import { EmployeeResponse } from 'src/api/types/employee/EmployeeResponse';
import EditButton from 'src/components/EditButton';
import HorizontalRow from 'src/components/HorizontalRow';
import PreviewDataRow from 'src/components/PreviewDataRow';
import RowButton from '../../../components/RowButton';
import EmployeeFormCard from '../EmployeeFormCard';

interface PreviewEmployeeFormProps {
  data?: EmployeeResponse & { id: number };
}
const EmployeePreview: React.FC<PreviewEmployeeFormProps> = ({ data }) => {
  const { contactId, employeeId } = useParams();
  const Client = useOldApiClient();
  const navigate = useNavigate();

  const form = useForm<EmployeeResponse>({
    defaultValues: data,
  });

  const { handleSubmit } = form;

  const onSubmit: SubmitHandler<EmployeeResponse> = async (data) => {
    try {
      const response = await Client.delete(`contact/${contactId}/employee/${employeeId}`, {
        data,
      });
      if (response.status === 200) {
        navigate(`/contact-detail/${contactId}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='flex-col w-full md:w-full md:pl-3 lg:ml-4'>
      <div className='flex w-11/12'>
        <div className='grid grid-row-2 mb-2 w-full'>
          <div className='flex w-full'>
            <div className='mt-2 text-4xl text-text font-bold flex w-full'>Osoba - {data?.name}</div>

            <div className='flex flex-row items-center pt-2'>
              <EditButton onClick={() => navigate(`/contact-detail/${contactId}`)}>Zpět</EditButton>
              <RowButton onClick={() => navigate(`/contact-detail/${contactId}/employee/${employeeId}/edit`)}>
                Upravit
              </RowButton>
            </div>
          </div>
        </div>
      </div>
      <form className='w-11/12' onSubmit={handleSubmit(onSubmit)}>
        <EmployeeFormCard>
          <PreviewDataRow label='Jméno'>{data?.name}</PreviewDataRow>
          <HorizontalRow />
          <PreviewDataRow label='Telefonní kontakt'>{data?.phoneNumber}</PreviewDataRow>
          <HorizontalRow />
          <PreviewDataRow label='Email'>{data?.email}</PreviewDataRow>
        </EmployeeFormCard>
        <div className='flex justify-end  mt-4'>
          <RowButton type='submit'>Odstranit</RowButton>
        </div>
      </form>
    </div>
  );
};

export default EmployeePreview;
