import React from 'react';

const LicensePage: React.FC = () => {
  return (
    <div className='flex lg:w-full ntb:w-full md:flex-col md:w-full justify-center self-center text-2xl md:ml-16'>
      Vypršela vám licence. Pro prodloužení licence nás kontaktujte na náš email.
    </div>
  );
};

export default LicensePage;
