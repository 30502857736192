import React from 'react';
import { FieldProps } from 'src/types/commonTypes';

export type FilterSelectProps = FieldProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement,
  string | number | null
>;

const FilterSelect = React.forwardRef((props: FilterSelectProps, ref: React.Ref<HTMLSelectElement>) => {
  const { value, ...rest } = props;
  return (
    <div className='flex-col  w-full'>
      <select
        className=' w-11/12 h-9 rounded-md border border-gray pl-2 md:w-full'
        value={value ?? ''}
        ref={ref}
        {...rest}
      >
        {props.children}
      </select>
    </div>
  );
});

FilterSelect.displayName = 'FilterSelect';

export default FilterSelect;
