import React, { createContext, useContext, useEffect, useState } from 'react';
import { PaymentMethodResponse } from 'src/api/types/paymentMethods/PaymentMethodResponse';
import { useOldApiClient } from 'src/api';

interface PaymentMethodContextProps {
  paymentMethods: PaymentMethodResponse[];
}

const defaultState: PaymentMethodContextProps = {
  paymentMethods: [],
};

interface paymentMethodContextProviderProps {
  children: React.ReactNode;
}

export const PaymentMethodContext = createContext<PaymentMethodContextProps>(defaultState);

export const PaymentMethodProvider: React.FC<paymentMethodContextProviderProps> = ({ children }) => {
  const Client = useOldApiClient();
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodResponse[]>([]);

  const getPaymentMethod = async (): Promise<void> => {
    const response = await Client.get('payment-method');
    setPaymentMethods(response.data);
  };

  useEffect(() => {
    getPaymentMethod();
  }, []);

  return <PaymentMethodContext.Provider value={{ paymentMethods }}>{children}</PaymentMethodContext.Provider>;
};

export const usePaymentMethods = (): PaymentMethodContextProps => useContext(PaymentMethodContext);
