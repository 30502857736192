import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ContactServiceListsResponse, useApiClient } from '..';

export type ServiceHeaderFilter = {
  datetime: string;
  branchName: string;
  employeeName: string;
  productName: string;
};

export const useServiceLists = (
  contactId: number,
  filters?: ServiceHeaderFilter,
): UseQueryResult<ContactServiceListsResponse[], { contactId: number }> => {
  const client = useApiClient();
  return useQuery(['serviceLists', filters, contactId], () =>
    client.contact.getContactServiceLists({ ...filters, contactId }),
  );
};
