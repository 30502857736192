import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditButton from 'src/components/EditButton';
import RowButton from '../../../components/RowButton';
import PaymentMethodFormCard from '../PaymentMethodFormCard';
import { PaymentMethodResponse } from '../../../api/types/paymentMethods/PaymentMethodResponse';

interface PreviewPaymentMethodFormProps {
  data?: PaymentMethodResponse & { id: number };
}

const PaymentMethodPreview: React.FC<PreviewPaymentMethodFormProps> = ({ data }) => {
  const navigate = useNavigate();
  const { paymentId } = useParams();

  return (
    <div className='flex-col w-2/4 md:w-full md:pl-3 ml-4'>
      <div className='flex w-11/12'>
        <div className='grid grid-row-2 mb-4 w-full'>
          <div className='flex w-full'>
            <div className='mt-2 text-4xl text-text font-bold flex w-full'>Platební metoda</div>

            <div className='flex flex-row items-center pt-2'>
              <EditButton onClick={() => navigate('/payment-method')}>Zpět</EditButton>
              <RowButton onClick={() => navigate(`/payment-method/${paymentId}/edit`)}>Upravit</RowButton>
            </div>
          </div>
        </div>
      </div>
      <form className='w-11/12'>
        <PaymentMethodFormCard>
          <div className='grid grid-cols-2 mb-4 mt-4'>
            <div className='font-bold text-text ml-4 mr-1'>Platební metoda</div>
            <div className='text-lightGrey'>{data?.name}</div>
          </div>
        </PaymentMethodFormCard>
      </form>
    </div>
  );
};

export default PaymentMethodPreview;
