import React, { useState } from 'react';
import { Outlet, Route, Routes, useLocation, useNavigate, Navigate } from 'react-router-dom';
import ServiceFetch from 'src/components/ServiceFetch';
import { StatusProvider } from 'src/store/statusContext';
import { TechnicianProvider } from 'src/store/technicianContext';
import RowButton from '../../components/RowButton';
import ServiceCard from './ServiceCard';
import ServiceForm from './ServiceForm';
import ServiceHeader, { ServiceHeaderFilter } from './ServiceHeader';
import FilterIcon from 'src/components/Icons/FilterIcon';
import { Modules, useLicenses } from 'src/store/licenseContext';
import { sortArrayOfObjects } from 'src/utils/sortArrayOfObjects';
import { clone } from 'ramda';
import { BranchesProvider } from 'src/store/branchesContext';
import { EmployeesProvider } from 'src/store/employeesContext';
import { ContactsProvider } from 'src/store/contactsContext';
import { ProductsProvider } from 'src/store/productsContext';
import { useOrders } from 'src/api/hooks/useOrders';
import { OrderListItemResponse } from 'src/api';

const getHeightOfFormByPathname = (pathname: string): string => {
  if (pathname.endsWith('/new') || pathname.endsWith('/edit')) {
    return ' lg:h-[96.2rem] ntb:h-[99.2rem]';
  }
  if (pathname.endsWith('/preview')) {
    return ' lg:h-[78.1rem] ntb:h-[84rem]';
  }
  return 'lg:h-[calc(100vh-8.8rem)] ntb:h-[calc(100vh-8.8rem)]';
};

export type SortType = {
  path: string[];
  direction: 'asc' | 'desc';
  type: 'number' | 'string';
};

const HomePage: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { getIsModuleValid } = useLicenses();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [currentFilters, setCurrentFilters] = useState<ServiceHeaderFilter>();
  const [currentSort, setCurrentSort] = useState<SortType>({ path: ['id'], type: 'number', direction: 'desc' });

  const { data } = useOrders(currentFilters);

  const sortedItems = sortArrayOfObjects(clone(data ?? []), currentSort.path, currentSort.direction, currentSort.type);

  const handleFilter = async (queryString: ServiceHeaderFilter): Promise<void> => {
    setCurrentFilters(queryString);
  };

  const handleSort = (newItems: OrderListItemResponse[], newSortObj: SortType): void => {
    setCurrentSort(newSortObj);
  };

  return (
    <>
      <StatusProvider>
        {!getIsModuleValid(Modules.SERVICES) && <Navigate to='/user' />}
        <div className='flex lg:w-full ntb:w-full md:flex-col md:w-full'>
          <div className='flex-col w-3/5 md:w-full'>
            <div className='flex justify-between'>
              <div className='flex items-baseline'>
                <div className='ml-6 mt-2 text-4xl text-text font-bold flex mr-4 '>Seznam zakázek</div>
                <div>
                  <button
                    onClick={() => {
                      setIsFilterOpen(!isFilterOpen);
                    }}
                  >
                    <div className='flex'>
                      <FilterIcon />
                    </div>
                  </button>
                </div>
              </div>

              <div className='mt-4 md:mr-6'>
                {pathname === '/' && (
                  <RowButton
                    onClick={() => {
                      navigate('/order/new');
                    }}
                  >
                    Nová zakázka
                  </RowButton>
                )}
              </div>
            </div>
            <div className='lg:ml-6 lg:mt-4 ntb:ml-6 ntb:mt-4 ntb:ml-6 ntb:mt-4 md:w-full md:p-3'>
              <div className='h-full'>
                <ServiceHeader
                  sortItems={sortedItems}
                  handleSort={handleSort}
                  isFilterOpen={isFilterOpen}
                  handleFilter={handleFilter}
                />
                <div className={`${getHeightOfFormByPathname(pathname)} md:h-[30rem] overflow-y-scroll rounded-b-lg`}>
                  <ServiceCard items={sortedItems} />
                </div>
              </div>
            </div>
          </div>

          <Outlet />
          <TechnicianProvider>
            <ContactsProvider>
              <BranchesProvider>
                <EmployeesProvider>
                  <ProductsProvider>
                    <Routes>
                      <Route path='/order/:orderId/*' element={<ServiceFetch />} />
                      <Route
                        path='/order/new'
                        element={
                          <ServiceForm
                            data={{
                              quarantee: true,
                              status: 'NEW',
                              company: {
                                notification: false,
                              },
                            }}
                          />
                        }
                      />
                    </Routes>
                  </ProductsProvider>
                </EmployeesProvider>
              </BranchesProvider>
            </ContactsProvider>
          </TechnicianProvider>
        </div>
      </StatusProvider>
    </>
  );
};

export default HomePage;
