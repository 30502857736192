import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Route, Routes } from 'react-router-dom';
import { useOldApiClient } from 'src/api';
import { ProductResponse } from 'src/api';
import ProductForm from 'src/pages/ContactDetailPage/ProductForm';
import ProductPreview from 'src/pages/ContactDetailPage/ProductPreview';

const ProductsFetch: React.FC = () => {
  const { productId, branchId, contactId } = useParams();
  const Client = useOldApiClient();
  const [dataInForm, setDataInForm] = useState<ProductResponse & { id: number }>();

  const getDataToForm = async (): Promise<void> => {
    if (productId) {
      const response = await Client.get(`contact/${contactId}/branch/${branchId}/product/${productId}`);
      setDataInForm(response.data);
    }
  };

  useEffect(() => {
    getDataToForm();
  }, [productId]);

  return (
    <>
      <Routes>
        <Route path='/preview' element={<ProductPreview data={dataInForm ?? undefined} />} />
        <Route
          path='/edit'
          element={dataInForm ? <ProductForm productId={dataInForm.id} defaultValues={dataInForm} /> : null}
        />
      </Routes>
    </>
  );
};

export default ProductsFetch;
