import React from 'react';
import { useNavigate } from 'react-router-dom';

import EditButton from 'src/components/EditButton';
import RowButton from '../../../components/RowButton';
import UserFormCard from '../UserFormCard';
import Textfield from 'src/components/Textfield';
import { useOldApiClient } from 'src/api';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import CreateUserFormSchema, { CreateUserFormValues } from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { CreateUserRequest } from 'src/api/types/user/CreateUserRequest';

const UserForm: React.FC = () => {
  const navigate = useNavigate();

  const Client = useOldApiClient();

  const { handleSubmit, control } = useForm<CreateUserFormValues>({
    resolver: yupResolver(CreateUserFormSchema),
  });

  const onSubmit: SubmitHandler<CreateUserRequest> = async (data) => {
    try {
      const response = await Client.post('user', {
        userEmail: data.email,
      });
      if (response.status === 201) navigate('/user');
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='flex-col w-2/4 md:w-full md:pl-3 md:pl-3 ntb:ml-4 lg:ml-4 ntb:ml-4'>
      <div className='mt-2 mb-2 text-4xl text-text font-bold'>Uživatel</div>
      <form className='lg:w-11/12 mt-4 ntb:w-11/12 md:mr-5 flex-col' onSubmit={handleSubmit(onSubmit)}>
        <UserFormCard>
          <div className='grid grid-cols-2 mb-4 mt-3'>
            <div className='font-bold text-text ml-4 mr-6 mt-2'>Email</div>
            <Controller control={control} name='email' render={({ field }) => <Textfield {...field} type='text' />} />
          </div>
        </UserFormCard>
        <div className='mt-5 grid place-items-end'>
          <div className='flex-row mr-4 mb-6'>
            <EditButton onClick={() => navigate('/user')}>Zavřít</EditButton>
            <RowButton type='submit'>Uložit změny</RowButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UserForm;
