import React, { ReactNode, useState } from 'react';
import SortUpIcon from 'src/components/Icons/SortUpIcon';
import SortDownIcon from 'src/components/Icons/SortDownIcon';
import { SortType } from '../RoutineServicesPage';
import { clone } from 'ramda';
import { Controller, useForm } from 'react-hook-form';
import SortingTextfield from 'src/components/FilterTextfield';
import { RoutineServicesResponse } from 'src/api';

type SortSectionType = {
  sortItems: RoutineServicesResponse[];
  handleSort: (sortItems: RoutineServicesResponse[], sortObj: SortType) => void;
  path: string[];
  type: 'string' | 'number';
  children?: ReactNode;
};

type Direction = 'asc' | 'desc';

const SortSection: React.FC<SortSectionType> = ({ sortItems, handleSort, path, type, children }) => {
  const [direction, setDirection] = useState<Direction>('asc');
  return (
    <div className='flex justify-center'>
      <button
        onClick={() => {
          setDirection(direction === 'asc' ? 'desc' : 'asc');
          handleSort(clone(sortItems), { path, direction, type });
        }}
      >
        <div className='flex'>
          {children}
          {direction === 'asc' ? (
            <div className='pt-1'>
              <SortUpIcon />
            </div>
          ) : (
            <div className='pt-1'>
              <SortDownIcon />
            </div>
          )}
        </div>
      </button>
    </div>
  );
};

type ServiceHeaderProps = {
  sortItems: RoutineServicesResponse[];
  handleSort: (sortItems: RoutineServicesResponse[], sortObj: SortType) => void;
  isFilterOpen: boolean;
  handleFilter: (queryString: ServiceHeaderFilter) => void;
};

export type ServiceHeaderFilter = {
  rs: string;
};

const serviceHeaderFilterDefaultValues = {
  rs: '',
};

const RoutineServicesHeader: React.FC<ServiceHeaderProps> = ({ sortItems, handleSort, isFilterOpen, handleFilter }) => {
  const { control, getValues, reset } = useForm<ServiceHeaderFilter>({
    defaultValues: serviceHeaderFilterDefaultValues,
  });

  const formHandleBlur = (values: ServiceHeaderFilter): void => {
    handleFilter(values);
  };

  React.useEffect(() => {
    reset();
    handleFilter(serviceHeaderFilterDefaultValues);
  }, [isFilterOpen]);

  return (
    <div className='flex'>
      <div
        className={`border-l border-t border-b border-border w-full  ${
          isFilterOpen ? 'h-20' : 'h-10'
        } text-center bg-white rounded-tl-lg flex justify-center border-r rounded-tr-lg pt-1 pb-2`}
      >
        <div className='pt-1'>
          <SortSection sortItems={sortItems} handleSort={handleSort} path={['routineName']} type='string'>
            Název
          </SortSection>
          {isFilterOpen && (
            <Controller
              name='rs'
              control={control}
              render={({ field }) => {
                const onBlur = (): void => {
                  field.onBlur();
                  formHandleBlur(getValues());
                };
                const handleKeyDown = (event: { key: string }): void => {
                  if (event.key === 'Enter') {
                    formHandleBlur(getValues());
                  }
                };
                return <SortingTextfield {...field} onBlur={onBlur} onKeyDown={handleKeyDown} />;
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RoutineServicesHeader;
