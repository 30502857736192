import React from 'react';
import { OrderListItemResponse } from 'src/api';

import ServiceItem from 'src/components/ServiceItem';

type OrderProps = {
  items: OrderListItemResponse[];
};

const ServiceCard: React.FC<OrderProps> = ({ items }) => {
  return (
    <>
      {items.map((item, index) => {
        return <ServiceItem key={item.id} item={item} isLastItem={items.length - 1 === index} />;
      })}
    </>
  );
};

export default ServiceCard;
