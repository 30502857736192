import { clone } from 'ramda';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams, Route, Routes } from 'react-router-dom';
import { useOldApiClient } from 'src/api';
import { EmployeeResponse } from 'src/api/types/employee/EmployeeResponse';
import EmployeeFetch from 'src/components/EmployeeFetch';
import FilterIcon from 'src/components/Icons/FilterIcon';

import RowButton from 'src/components/RowButton';
import { Modules, useLicenses } from 'src/store/licenseContext';
import { StatusProvider } from 'src/store/statusContext';
import { sortArrayOfObjects } from 'src/utils/sortArrayOfObjects';
import EmployeesCard from '../EmployeeCard';
import EmployeeForm from '../EmployeeForm';
import EmployeesTableHeader, { ServiceHeaderFilterEmployees } from '../EmployeeTableHeader';

export type SortType = {
  path: string[];
  direction: 'asc' | 'desc';
  type: 'number' | 'string';
};

const getHeightOfFormByPathname = (pathname: string): string => {
  if (pathname.endsWith('/new') || pathname.endsWith('/edit')) {
    return 'lg:h-[calc(100vh-8.5rem)] ntb:h-[calc(100vh-8.8rem)]';
  }
  if (pathname.endsWith('/preview')) {
    return 'lg:h-[calc(100vh-8.5rem)] ntb:h-[calc(100vh-8.8rem)]';
  }
  return 'lg:h-[calc(100vh-8.8rem)] ntb:h-[calc(100vh-8.8rem)]';
};

const Employees: React.FC = () => {
  const { getIsModuleValid } = useLicenses();
  const Client = useOldApiClient();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { contactId } = useParams();

  const [isFilterOpen, setIsFilterOpen] = useState<string | undefined>(undefined);
  const [currentFilters, setCurrentFilters] = useState<ServiceHeaderFilterEmployees>();
  const [employees, setEmployees] = useState<EmployeeResponse[]>([]);
  const [currentSort, setCurrentSort] = useState<SortType>({ path: ['id'], type: 'number', direction: 'desc' });

  const handleFilterEmployees = async (queryString: ServiceHeaderFilterEmployees): Promise<void> => {
    const response = await Client.get(`/contact/${contactId}/employee`, { params: queryString });
    setEmployees(sortArrayOfObjects(clone(response.data), currentSort.path, currentSort.direction, currentSort.type));
    setCurrentFilters(queryString);
  };

  const getEmployees = async (): Promise<void> => {
    const response = await Client.get(`/contact/${contactId}/employee`, { params: currentFilters });
    setEmployees(sortArrayOfObjects(clone(response.data), currentSort.path, currentSort.direction, currentSort.type));
  };

  const handleSortEmployees = (newItems: EmployeeResponse[], newSortObj: SortType): void => {
    const sortedArr = sortArrayOfObjects(clone(newItems), newSortObj.path, newSortObj.direction, newSortObj.type);
    setCurrentSort(newSortObj);
    setEmployees(sortedArr);
  };

  useEffect(() => {
    getEmployees();
  }, [pathname]);

  return (
    <>
      <StatusProvider>
        {!getIsModuleValid(Modules.CONTACTS) && <Navigate to='/contact' />}
        <div className='flex-col'>
          <div className='flex mb-2'>
            <div className='text-4xl text-text font-bold flex w-full ml-2 pt-2'>
              Osoby
              <button
                onClick={() => {
                  setIsFilterOpen(isFilterOpen === 'employee' ? undefined : 'employee');
                }}
              >
                <div className='mt-3 ml-2'>
                  <FilterIcon />
                </div>
              </button>
            </div>
            <div className='mt-4 md:mr-6'>
              {pathname === `/contact-detail/${contactId}` && (
                <RowButton
                  onClick={() => {
                    navigate(`new-employee`);
                  }}
                >
                  Nová osoba
                </RowButton>
              )}
            </div>
          </div>
          <EmployeesTableHeader
            sortEmployees={employees}
            handleSortEmployees={handleSortEmployees}
            isFilterOpen={isFilterOpen === 'employee'}
            handleFilterEmployees={handleFilterEmployees}
          />
          <div
            className={`${getHeightOfFormByPathname(
              pathname,
            )} md:h-[12rem] ntb:h-[12rem] lg:h-[15rem] overflow-y-scroll rounded-b-lg`}
          >
            <EmployeesCard employees={employees} />
          </div>
        </div>
        <Routes>
          <Route path='/new-employee' element={<EmployeeForm />} />
          <Route path='employee/:employeeId/*' element={<EmployeeFetch />} />
        </Routes>
      </StatusProvider>
    </>
  );
};

export default Employees;
