import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Outlet, Route, Routes } from 'react-router-dom';
import { useOldApiClient } from 'src/api';
import { ContactResponse } from 'src/api/types/contact/ContactResponse';
import ContactForm from 'src/pages/ContactPage/ContactForm';
import ContactPreview from 'src/pages/ContactPage/ContactPreview';

const ContactFetch: React.FC = () => {
  const { contactId } = useParams();
  const Client = useOldApiClient();
  const [dataInForm, setDataInForm] = useState<ContactResponse & { id: number }>();

  const getDataToForm = async (): Promise<void> => {
    if (contactId) {
      const response = await Client.get(`/contact/${contactId}`);
      setDataInForm(response.data);
    }
  };

  useEffect(() => {
    getDataToForm();
  }, [contactId]);

  return (
    <>
      <Outlet />
      <Routes>
        <Route path='/preview' element={<ContactPreview data={dataInForm} />} />
        <Route
          path='/edit'
          element={
            dataInForm ? (
              <ContactForm contactId={dataInForm.id} deletedAt={dataInForm?.deletedAt} defaultValues={dataInForm} />
            ) : null
          }
        />
      </Routes>
    </>
  );
};

export default ContactFetch;
