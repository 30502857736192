import React from 'react';
import { useNavigate } from 'react-router-dom';
import HorizontalRow from 'src/components/HorizontalRow';
import EditButton from 'src/components/EditButton';
import RowButton from '../../../components/RowButton';

import Textfield from 'src/components/Textfield';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { useOldApiClient } from 'src/api';

import MaterialFormCard from '../MaterialFormCard';
import { CreateMaterialRequest } from 'src/api/types/material/CreateMaterialRequest';
import { UpdateMaterialRequest } from 'src/api/types/material/UpdateMaterialRequest';
import CreateMaterialFormSchema from './schema';

interface MaterialFormProps {
  defaultValues?: Partial<CreateMaterialRequest>;
  materialId?: number;
}

const MaterialForm: React.FC<MaterialFormProps> = ({ defaultValues, materialId }) => {
  const navigate = useNavigate();

  const Client = useOldApiClient();

  const form = useForm<CreateMaterialRequest & UpdateMaterialRequest>({
    resolver: yupResolver(CreateMaterialFormSchema),
    defaultValues: defaultValues,
  });
  const { handleSubmit, control } = form;

  const MATERIAL_ROUTE = '/material';

  const onSubmit: SubmitHandler<CreateMaterialRequest & UpdateMaterialRequest> = async (data) => {
    try {
      if (materialId) {
        const response = await Client.put(`material/${materialId}`, {
          materialName: data.materialName,
          materialValue: data.materialValue,
          materialCode: data.materialCode,
        });
        if (response.status === 200) {
          navigate(MATERIAL_ROUTE);
        }
      } else {
        const response = await Client.post('material', {
          materialName: data.materialName,
          materialValue: data.materialValue,
          materialCode: data.materialCode,
        });
        if (response.status === 201) {
          navigate(MATERIAL_ROUTE);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='flex-col w-2/4 md:w-full md:pl-3 ntb:ml-4 lg:ml-4 ntb:ml-4'>
      <div className='mt-4 mb-2 text-4xl text-text font-bold'>
        {materialId ? 'Editace materiálu' : ' Nový materiál'}
      </div>
      <FormProvider {...form}>
        <form className='w-11/12' onSubmit={handleSubmit(onSubmit)}>
          <MaterialFormCard>
            <div className='grid grid-cols-2 mb-4 mt-2'>
              <div className='font-bold text-text ml-4 mb-2'>Název</div>
              <Controller control={control} name='materialName' render={({ field }) => <Textfield {...field} />} />
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-2'>
              <div className='font-bold text-text ml-4 mr-6'>Cena</div>
              <Controller control={control} name='materialValue' render={({ field }) => <Textfield {...field} />} />
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-2'>
              <div className='font-bold text-text ml-4 mr-6'>Kód</div>
              <Controller control={control} name='materialCode' render={({ field }) => <Textfield {...field} />} />
            </div>
          </MaterialFormCard>
          <div className='mt-5 grid place-items-end'>
            <div className='flex-row mr-4 mb-6'>
              <EditButton onClick={() => navigate('/material')}>Zavřít</EditButton>
              <RowButton type='submit'>Uložit změny</RowButton>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default MaterialForm;
