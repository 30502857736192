import React from 'react';
import { TechnicianResponse } from 'src/api/types/technician/TechnicianResponse';
import ServiceTechnician from 'src/components/ServiceTechnician';

type TechnicianProps = {
  technicians: TechnicianResponse[];
};

const TechnicianCard: React.FC<TechnicianProps> = ({ technicians }) => {
  return (
    <>
      {technicians.map((technician, index) => {
        return (
          <ServiceTechnician
            key={technician.id}
            technician={technician}
            isLastItem={technicians.length - 1 === index}
          />
        );
      })}
    </>
  );
};

export default TechnicianCard;
