import { clone } from 'ramda';
import React, { useState } from 'react';
import { useLocation, useNavigate, Route, Routes, Outlet } from 'react-router-dom';
import { RoutineServicesResponse } from 'src/api';
import { useRoutineServices } from 'src/api/hooks/useRoutineServices';
import FilterIcon from 'src/components/Icons/FilterIcon';
import RoutineServicesFetch from 'src/components/RoutineServicesFetch';
import { sortArrayOfObjects } from 'src/utils/sortArrayOfObjects';
import RowButton from '../../components/RowButton';
import RoutineServicesCard from './RoutineServicesCard';
import RoutineServicesForm from './RoutineServicesForm';
import RoutineServicesHeader, { ServiceHeaderFilter } from './RoutineServicesHeader';

const getHeightOfFormByPathname = (pathname: string): string => {
  if (pathname.endsWith('/new') || pathname.endsWith('/edit')) {
    return 'lg:h-[calc(100vh-8.5rem)] ntb:h-[calc(100vh-8.8rem)]';
  }
  if (pathname.endsWith('/preview')) {
    return 'lg:h-[calc(100vh-8.5rem)] ntb:h-[calc(100vh-8.8rem)]';
  }
  return 'lg:h-[calc(100vh-8.8rem)] ntb:h-[calc(100vh-8.8rem)]';
};

export type SortType = {
  path: string[];
  direction: 'asc' | 'desc';
  type: 'number' | 'string';
};

const RoutineServicesPage: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [currentFilters, setCurrentFilters] = useState<ServiceHeaderFilter>();
  const [currentSort, setCurrentSort] = useState<SortType>({ path: ['id'], type: 'number', direction: 'desc' });

  const { data } = useRoutineServices(currentFilters);

  const sortedItems = sortArrayOfObjects(clone(data ?? []), currentSort.path, currentSort.direction, currentSort.type);

  const handleFilter = async (queryString: ServiceHeaderFilter): Promise<void> => {
    setCurrentFilters(queryString);
  };

  const handleSort = (newItems: RoutineServicesResponse[], newSortObj: SortType): void => {
    setCurrentSort(newSortObj);
  };

  return (
    <>
      <div className='flex lg:w-full ntb:w-full md:flex-col md:w-full'>
        <div className='flex-col w-2/4 md:w-full'>
          <div className='flex justify-between'>
            <div className='flex items-baseline'>
              <div className='ml-6 mt-2 text-4xl text-text font-bold flex mr-4 '>Pravidelné servisy</div>
              <div>
                <button
                  onClick={() => {
                    setIsFilterOpen(!isFilterOpen);
                  }}
                >
                  <div className='flex'>
                    <FilterIcon />
                  </div>
                </button>
              </div>
            </div>
            <div className='mt-4 md:mr-6'>
              {pathname === '/routine-services' && (
                <RowButton
                  onClick={() => {
                    navigate('/routine-services/new');
                  }}
                >
                  Nový pravidelný servis
                </RowButton>
              )}
            </div>
          </div>
          <div className='lg:ml-6 lg:mt-4 ntb:ml-6 ntb:mt-4 ntb:ml-6 ntb:mt-4 md:w-full md:p-3'>
            <div className='h-full'>
              <RoutineServicesHeader
                sortItems={sortedItems}
                handleSort={handleSort}
                isFilterOpen={isFilterOpen}
                handleFilter={handleFilter}
              />
              <div className={`${getHeightOfFormByPathname(pathname)} md:h-[30rem] overflow-y-scroll rounded-b-lg`}>
                <RoutineServicesCard items={sortedItems} />
              </div>
            </div>
          </div>
        </div>

        <Outlet />

        <Routes>
          <Route path='/:routineServiceId/*' element={<RoutineServicesFetch />} />
          <Route path='/new' element={<RoutineServicesForm />} />
        </Routes>
      </div>
    </>
  );
};

export default RoutineServicesPage;
