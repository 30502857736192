import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditButton from 'src/components/EditButton';
import RowButton from '../../../components/RowButton';
import ParametrFormCard from '../ParameterFormCard';
import { ParametersResponse } from 'src/api/types/parameters/ParametersResponse';
import PreviewDataRow from 'src/components/PreviewDataRow';

interface PreviewParameterFormProps {
  data?: ParametersResponse & { id: number };
}

const ParameterPreview: React.FC<PreviewParameterFormProps> = ({ data }) => {
  const navigate = useNavigate();
  const { paramKey } = useParams();

  return (
    <div className='flex-col w-2/4 md:w-full md:pl-3 ml-4'>
      <div className='flex w-11/12'>
        <div className='grid grid-row-2 mb-4 w-full'>
          <div className='flex w-full'>
            <div className='mt-2 text-4xl text-text font-bold flex w-full'>Parametr</div>

            <div className='flex flex-row items-center pt-2'>
              <EditButton onClick={() => navigate('/parameter')}>Zpět</EditButton>
              <RowButton onClick={() => navigate(`/parameter/${paramKey}/edit`)}>Upravit</RowButton>
            </div>
          </div>
        </div>
      </div>
      <form className='w-11/12'>
        <ParametrFormCard>
          <PreviewDataRow label='Parametr'>{data?.paramKey}</PreviewDataRow>
          <PreviewDataRow label='Název parametru'>{data?.paramTitle}</PreviewDataRow>
          <PreviewDataRow label='Hodnota parametru'>{data?.paramValue}</PreviewDataRow>
        </ParametrFormCard>
      </form>
    </div>
  );
};

export default ParameterPreview;
