import { AxiosError } from 'axios';
import { useState } from 'react';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useOldApiClient } from 'src/api';
import { useAuth } from 'src/store/authContext';

import Button from '../../components/Button';
import Card from '../../components/Card';
import Checkbox from '../../components/Checkbox';
import TextField from '../../components/Textfield';
import logo from '../../pictures/logo.svg';

type LoginForm = {
  email: string;
  password: string;
  canSaveToken: boolean;
};

function LoginPage(): React.ReactElement {
  const Client = useOldApiClient();

  const { handleSubmit, control } = useForm<LoginForm>();

  const navigate = useNavigate();

  const { setAuthToken } = useAuth();

  const [isLoginInvalid, setIsLoginInvalid] = useState(false);

  const onSubmit: SubmitHandler<LoginForm> = async (data) => {
    try {
      const response = await Client.post('auth/login', {
        userEmail: data.email.toLocaleLowerCase().trim(),
        userPsw: data.password,
      });
      setAuthToken(response.data.token, data.canSaveToken);
      navigate('/');
    } catch (error) {
      const err = error as AxiosError;
      console.error('test', error);
      if (err?.response?.status === 400) {
        setIsLoginInvalid(true);
      }
    }
  };

  return (
    <div className='bg-background grid place-items-center h-screen'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid place-items-center'>
          <img src={logo} className='App-logo' alt='logo' />
          <div className='text-4xl font-medium text-text mb-2 mt-8 '>Přihlásit se</div>

          <Card>
            <div className='ml-8 mt-8'>
              <div className='text-text '>E-mail:</div>

              <Controller
                control={control}
                name='email'
                render={({ field }) => (
                  <>
                    <TextField {...field} />
                  </>
                )}
              />
            </div>
            <div className='ml-8 mt-6'>
              <div className='text-text'>Heslo:</div>
              <Controller
                control={control}
                name='password'
                render={({ field }) => (
                  <>
                    <TextField {...field} type='password' />
                  </>
                )}
              />
            </div>
            <div className='text-primary text-sm flex justify-end mr-8 mt-2'>
              {isLoginInvalid && 'Byly zadány nevalidní přihlašovací údaje!'}
            </div>

            <div>
              <div className='ml-8 mt-6 flex'>
                <Controller
                  control={control}
                  name='canSaveToken'
                  render={({ field }) => (
                    <>
                      <Checkbox {...field} />
                    </>
                  )}
                />

                <div className='text-text ml-2 mt-1 text-xs flex'>
                  Pamatovat si mě
                  <Link to='/forgot-password'>
                    <div className='ml-24 text-primary'> Zapomněli jste heslo? </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className='ml-8 mt-7 mb-4'>
              <Button type='submit'>Přihlásit se</Button>
            </div>
          </Card>
        </div>
      </form>
    </div>
  );
}

export default LoginPage;
