import React from 'react';

export type PhotoButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const PhotoButton = React.forwardRef((props: PhotoButtonProps, ref: React.Ref<HTMLButtonElement>) => (
  <button className='bg-primary text-white text-xs font-medium w-5/6 h-10 rounded-md' ref={ref} {...props}>
    {props.children}
  </button>
));

PhotoButton.displayName = 'PhotoButton';

export default PhotoButton;
