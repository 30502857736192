import React, { ReactNode, useState } from 'react';
import SortUpIcon from 'src/components/Icons/SortUpIcon';
import SortDownIcon from 'src/components/Icons/SortDownIcon';

import { clone } from 'ramda';
import { Controller, useForm } from 'react-hook-form';
import SortingTextfield from 'src/components/FilterTextfield';
import { SortType } from '../ContactDetailPage';
import { BranchesListResponse } from 'src/api/types/branches/BranchesListResponse';

type SortSectionType = {
  sortBranches: BranchesListResponse[];
  handleSortBranches: (sortMaterial: BranchesListResponse[], sortObj: SortType) => void;
  path: string[];
  type: 'string' | 'number';
  children?: ReactNode;
};

type Direction = 'asc' | 'desc';

const SortSection: React.FC<SortSectionType> = ({ sortBranches, handleSortBranches, path, type, children }) => {
  const [direction, setDirection] = useState<Direction>('asc');
  return (
    <div className='flex justify-center'>
      <button
        onClick={() => {
          setDirection(direction === 'asc' ? 'desc' : 'asc');
          handleSortBranches(clone(sortBranches), { path, direction, type });
        }}
      >
        <div className='flex'>
          {children}
          {direction === 'asc' ? (
            <div className='pt-1'>
              <SortUpIcon />
            </div>
          ) : (
            <div className='pt-1'>
              <SortDownIcon />
            </div>
          )}
        </div>
      </button>
    </div>
  );
};

type ServiceHeaderProps = {
  sortBranches: BranchesListResponse[];
  handleSortBranches: (sortBranches: BranchesListResponse[], sortObj: SortType) => void;
  isFilterOpen: boolean;
  handleFilterBranches: (queryString: ServiceHeaderFilter) => void;
};

export type ServiceHeaderFilter = {
  name: string;
  city: string;
};

const serviceHeaderFilterDefaultValues = {
  name: '',
  city: '',
};

const BranchTableHeader: React.FC<ServiceHeaderProps> = ({
  sortBranches,
  handleSortBranches,
  isFilterOpen,
  handleFilterBranches,
}) => {
  const { control, getValues, reset } = useForm<ServiceHeaderFilter>({
    defaultValues: serviceHeaderFilterDefaultValues,
  });

  const formHandleBlur = (values: ServiceHeaderFilter): void => {
    handleFilterBranches(values);
  };

  React.useEffect(() => {
    reset();
    handleFilterBranches(serviceHeaderFilterDefaultValues);
  }, [isFilterOpen]);

  return (
    <div className='flex ml-2 md:mr-2'>
      <div
        className={`border-l border-t border-b border-border w-full  ${
          isFilterOpen ? 'h-20' : 'h-10'
        } text-center bg-white rounded-tl-lg flex justify-center pt-1 pb-2`}
      >
        <div className='pt-1'>
          <SortSection
            sortBranches={sortBranches}
            handleSortBranches={handleSortBranches}
            path={['name']}
            type='string'
          >
            Jméno pobočky
          </SortSection>
          {isFilterOpen && (
            <Controller
              name='name'
              control={control}
              render={({ field }) => {
                const onBlur = (): void => {
                  field.onBlur();
                  formHandleBlur(getValues());
                };
                const handleKeyDown = (event: { key: string }): void => {
                  if (event.key === 'Enter') {
                    formHandleBlur(getValues());
                  }
                };
                return <SortingTextfield {...field} onBlur={onBlur} onKeyDown={handleKeyDown} />;
              }}
            />
          )}
        </div>
      </div>

      <div
        className={`border-r border-t border-b border-border w-full  ${
          isFilterOpen ? 'h-20' : 'h-10'
        } text-center bg-white rounded-tr-lg flex justify-center pt-1 pb-2`}
      >
        <div className='pt-1'>
          <SortSection
            sortBranches={sortBranches}
            handleSortBranches={handleSortBranches}
            path={['city']}
            type='string'
          >
            Město
          </SortSection>
          {isFilterOpen && (
            <Controller
              name='city'
              control={control}
              render={({ field }) => {
                const onBlur = (): void => {
                  field.onBlur();
                  formHandleBlur(getValues());
                };
                const handleKeyDown = (event: { key: string }): void => {
                  if (event.key === 'Enter') {
                    formHandleBlur(getValues());
                  }
                };
                return <SortingTextfield {...field} onBlur={onBlur} onKeyDown={handleKeyDown} />;
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BranchTableHeader;
