import React from 'react';
import { Outlet, Route, Routes, useParams } from 'react-router-dom';
import RoutineServicesForm from 'src/pages/RoutineServices/RoutineServicesForm';
import { useRoutineService } from 'src/api/hooks/useRoutineServices';
import { ServiceRoutineFormValues } from 'src/pages/RoutineServices/RoutineServicesForm/schema';
import RoutineServicesPreview from 'src/pages/RoutineServices/RoutineServicesPreview';

const RoutineServicesFetch: React.FC = () => {
  const { routineServiceId } = useParams();
  const { data } = useRoutineService(routineServiceId as unknown as number);

  return (
    <>
      <Outlet />
      <Routes>
        <Route path='/preview' element={<RoutineServicesPreview data={data} />} />
        <Route
          path='/edit'
          element={
            data ? (
              <RoutineServicesForm
                routineServiceNumber={routineServiceId as unknown as number}
                data={data as Partial<ServiceRoutineFormValues>}
              />
            ) : null
          }
        />
      </Routes>
    </>
  );
};

export default RoutineServicesFetch;
