import { isNil } from 'ramda';
import React from 'react';
import { ContactServiceListsResponse } from 'src/api';
import DocumentTextIcon from '../Icons/DocumentTextIcon';

interface ServiceHistoryServicesProps {
  historyData: ContactServiceListsResponse;
  isLastItem?: boolean;
}

const ServiceHistoryServices: React.FC<ServiceHistoryServicesProps> = ({ historyData, isLastItem }) => {
  const date = isNil(historyData.datetime) ? '' : new Date(historyData.datetime).toLocaleDateString();

  return (
    <div>
      <div key={historyData.datetime} className={`h-12  flex bg-white  rounded-br-lg  rounded-bl-lg `}>
        <div
          className={`text-text pl-4 w-3/5 self-center border-l border-border h-12 border-b pt-3 ${
            isLastItem ? 'rounded-bl-lg border-b' : ''
          }`}
        >
          {date}
        </div>
        <div className='text-text text-left  w-full border-b border-border pt-3 truncate'>
          {historyData.branch.name}
        </div>
        <div className='text-text text-left w-full  border-b border-border pt-3 truncate'>
          {historyData.employee.name}
        </div>
        <div className='text-text text-left w-3/5  border-b border-border pt-3 '>{historyData.product.name}</div>
        <div
          className={`cursor-pointer w-9/12 h-12 pt-3 truncate border-b border-r border-border ${
            isLastItem ? 'rounded-br-lg border-b' : ''
          } `}
        >
          <a href={`${historyData?.serviceListUrl}`} target='blank'>
            <div className='flex justify-center'>
              <DocumentTextIcon />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ServiceHistoryServices;
