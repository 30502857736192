import React from 'react';

import { MaterialResponse } from 'src/api/types/material/MaterialResponse';
import ServiceMaterials from 'src/components/ServiceMaterials';

type MaterialProps = {
  materials: MaterialResponse[];
};

const MaterialCard: React.FC<MaterialProps> = ({ materials }) => {
  return (
    <>
      {materials.map((material, index) => (
        <ServiceMaterials key={material.id} material={material} isLastItem={materials.length - 1 === index} />
      ))}
    </>
  );
};

export default MaterialCard;
