import React from 'react';
import { useNavigate } from 'react-router-dom';
import HorizontalRow from 'src/components/HorizontalRow';
import EditButton from 'src/components/EditButton';
import RowButton from '../../../components/RowButton';

import Textfield from 'src/components/Textfield';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { RoutineServicesResponse } from 'src/api';

import RoutineServicesFormCard from '../RoutineServicesFormCard';
import CreateServiceRoutineFormSchema, { ServiceRoutineFormValues } from './schema';
import { useCreateRoutineServiceMutation, useUpdateRoutineServiceMutation } from 'src/api/hooks/useRoutineServices';

interface RoutineServicesFormProps {
  data?: Partial<ServiceRoutineFormValues>;
  routineServiceNumber?: number;
}

const RoutineServicesForm: React.FC<RoutineServicesFormProps> = ({ data, routineServiceNumber }) => {
  const navigate = useNavigate();
  const createRoutineService = useCreateRoutineServiceMutation();
  const updateRoutineService = useUpdateRoutineServiceMutation();

  const form = useForm<RoutineServicesResponse>({
    resolver: yupResolver(CreateServiceRoutineFormSchema),
    defaultValues: data,
  });
  const { handleSubmit, control } = form;

  const ROUTINE_SERVICE_ROUTE = '/routine-services';

  const onSubmit: SubmitHandler<RoutineServicesResponse> = async (data) => {
    try {
      if (routineServiceNumber) {
        await updateRoutineService.mutateAsync({ body: data, id: routineServiceNumber });

        navigate(ROUTINE_SERVICE_ROUTE);
      } else {
        await createRoutineService.mutateAsync(data);

        navigate(ROUTINE_SERVICE_ROUTE);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='flex-col w-2/4 md:w-full md:pl-3 ntb:ml-4 lg:ml-4 ntb:ml-4'>
      <div className='mt-4 mb-2 text-4xl text-text font-bold'>
        {routineServiceNumber ? 'Editace pravidelného servisu' : ' Nový pravidelný servis'}
      </div>
      <FormProvider {...form}>
        <form className='w-11/12' onSubmit={handleSubmit(onSubmit)}>
          <RoutineServicesFormCard>
            <div className='grid grid-cols-2 mb-4 mt-2'>
              <div className='font-bold text-text ml-4 mb-2'>Název</div>
              <Controller control={control} name='routineName' render={({ field }) => <Textfield {...field} />} />
            </div>
            <HorizontalRow />
            <div className='grid grid-cols-2 mb-2'>
              <div className='font-bold text-text ml-4 mr-6'>Počet dnů</div>
              <Controller control={control} name='routineValue' render={({ field }) => <Textfield {...field} />} />
            </div>
          </RoutineServicesFormCard>
          <div className='mt-5 grid place-items-end'>
            <div className='flex-row mr-4 mb-6'>
              <EditButton onClick={() => navigate('/routine-services')}>Zavřít</EditButton>
              <RowButton type='submit'>Uložit změny</RowButton>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default RoutineServicesForm;
