import React from 'react';
import { ContactServiceListsResponse } from 'src/api';
import ServiceHistoryServices from 'src/components/ServiceHistoryService';

type HistoryServiceCardProps = {
  historyDatas: ContactServiceListsResponse[];
};

const HistoryServiceCard: React.FC<HistoryServiceCardProps> = ({ historyDatas }) => {
  return (
    <>
      {historyDatas.map((historyData, index) => (
        <ServiceHistoryServices
          key={historyData.datetime}
          historyData={historyData}
          isLastItem={historyDatas.length - 1 === index}
        />
      ))}
    </>
  );
};

export default HistoryServiceCard;
