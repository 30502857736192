import React from 'react';
import { UserListResponse } from 'src/api/types/user/UserListResponse';
import ServiceUser from 'src/components/ServiceUser';

type UsersProps = {
  users: UserListResponse[];
};

const UsersCard: React.FC<UsersProps> = ({ users }) => {
  return (
    <>
      {users.map((user, index) => (
        <ServiceUser key={user.id} user={user} isLastItem={users.length - 1 === index} />
      ))}
    </>
  );
};

export default UsersCard;
