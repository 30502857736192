import { CreateTechnicianRequest } from 'src/api/types/technician/CreateTechnicianRequest';
import { emptyToNull } from 'src/utils/schemaUtils';
import { object, SchemaOf, string } from 'yup';

export type CreateTechnicianFormValues = CreateTechnicianRequest;

const CreateTechnicianFormSchema: SchemaOf<CreateTechnicianFormValues> = object().shape({
  technicianEmail: string().email().defined().default(null).transform(emptyToNull),
  technicianName: string().defined().default(null).transform(emptyToNull),
});

export default CreateTechnicianFormSchema;
