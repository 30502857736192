import React, { ReactNode } from 'react';

type BranchFormCardProps = {
  children?: ReactNode;
};

const BranchFormCard: React.FC<BranchFormCardProps> = ({ children }) => (
  <div className='h-3/4 rounded-md shadow-md bg-white border-border border-inherit border'>{children}</div>
);

export default BranchFormCard;
