/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FileUploadRequest } from '../models/FileUploadRequest';
import type { FinishOrderRequest } from '../models/FinishOrderRequest';
import type { OrderDetailResponse } from '../models/OrderDetailResponse';
import type { OrderSignatureRequest } from '../models/OrderSignatureRequest';
import type { UnsuccessfulOrderRequest } from '../models/UnsuccessfulOrderRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TechnicianOrderService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns void
   * @throws ApiError
   */
  public finishOrder({ requestBody }: { requestBody: FinishOrderRequest }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/technician-order',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns OrderDetailResponse
   * @throws ApiError
   */
  public orderDetail(): CancelablePromise<OrderDetailResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/technician-order',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public createSign({ formData }: { formData: OrderSignatureRequest }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/technician-order/signature',
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public testing({ id }: { id: number }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/technician-order/{id}/testing',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public unsuccessfulOrder({ requestBody }: { requestBody: UnsuccessfulOrderRequest }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/technician-order/unsuccessful',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public createFile({ formData }: { formData: FileUploadRequest }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/technician-order/file',
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }
}
