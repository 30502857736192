import React, { createContext, useContext, useState } from 'react';
import { ProductResponse } from 'src/api/types/product/ProductResponse';

interface ProductsContextProps {
  products: ProductResponse[];
  setProducts: React.Dispatch<React.SetStateAction<ProductResponse[]>>;
}

const defaultState: ProductsContextProps = {
  products: [],
  setProducts: () => {
    return;
  },
};

interface ContactsContextProviderProps {
  children: React.ReactNode;
}

export const ProductsContext = createContext<ProductsContextProps>(defaultState);

export const ProductsProvider: React.FC<ContactsContextProviderProps> = ({ children }) => {
  const [products, setProducts] = useState<ProductResponse[]>([]);

  return <ProductsContext.Provider value={{ products, setProducts }}>{children}</ProductsContext.Provider>;
};

export const useProducts = (): ProductsContextProps => useContext(ProductsContext);
