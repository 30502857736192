import React from 'react';
import { useNavigate, useLocation } from 'react-router';

import { ContactListResponse } from 'src/api';

interface ServiceContactProps {
  contact: ContactListResponse;
  isLastItem?: boolean;
}

const ServiceContact: React.FC<ServiceContactProps> = ({ isLastItem, contact }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div>
      <div
        onClick={() => {
          navigate(`/contact/${contact.id}/preview`);
        }}
        key={contact.id}
        className={`h-12  flex bg-white  rounded-br-lg  rounded-bl-lg ${
          typeof contact.id === 'number' && pathname?.match(/\d+/g)?.join('') === contact.id.toString() ? 'bg-rose' : ''
        }`}
      >
        <div
          className={`text-text cursor-pointer text-center w-full self-center pt-3 border-l border-r border-border h-12 border-b border-border pt-3 ${
            isLastItem ? 'rounded-bl-lg rounded-br-lg border-b' : ''
          }`}
        >
          {contact.companyName}
        </div>
      </div>
    </div>
  );
};

export default ServiceContact;
