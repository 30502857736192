import { useSelectContext } from '../context/selectContext';
import { SelectOption } from '../types';

type SuggestionListItemProps<Option extends SelectOption> = {
  isLast: boolean;
  option: Option;
};

export const SuggestionListItem = <Option extends SelectOption, ReturnValue>({
  isLast,
  option,
}: SuggestionListItemProps<Option>): React.ReactElement => {
  const { selected, getOptionValue, getOptionLabel, onSelect } = useSelectContext<Option, ReturnValue>();

  const isSelected = selected ? getOptionValue(selected) === getOptionValue(option) : false;
  const label = getOptionLabel(option);

  return (
    <li
      className={`mx-4 hover:text- font-semibold hover:font-extrabold cursor-pointer ${isSelected ? ' text' : ''}`}
      onClick={() => onSelect(option)}
    >
      {label}
      {isLast && <hr />}
    </li>
  );
};
