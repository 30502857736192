/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateMaterialRequest } from '../models/CreateMaterialRequest';
import type { MaterialResponse } from '../models/MaterialResponse';
import type { UpdateMaterialRequest } from '../models/UpdateMaterialRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MaterialService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns MaterialResponse
   * @throws ApiError
   */
  public getMaterialList({
    name,
    price,
    code,
    withDeleted,
  }: {
    /**
     * Material name
     */
    name?: string;
    /**
     * Material price
     */
    price?: string;
    /**
     * Material code
     */
    code?: string;
    /**
     * With deleted
     */
    withDeleted?: boolean;
  }): CancelablePromise<Array<MaterialResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/material',
      query: {
        name: name,
        price: price,
        code: code,
        withDeleted: withDeleted,
      },
    });
  }

  /**
   * @returns MaterialResponse
   * @throws ApiError
   */
  public createMaterial({ requestBody }: { requestBody: CreateMaterialRequest }): CancelablePromise<MaterialResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/material',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns MaterialResponse
   * @throws ApiError
   */
  public getMaterial({ id }: { id: number }): CancelablePromise<MaterialResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/material/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns MaterialResponse
   * @throws ApiError
   */
  public updateMaterial({
    id,
    requestBody,
  }: {
    id: number;
    requestBody: UpdateMaterialRequest;
  }): CancelablePromise<MaterialResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/material/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns MaterialResponse
   * @throws ApiError
   */
  public deleteMaterial({ id }: { id: number }): CancelablePromise<MaterialResponse> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/material/{id}',
      path: {
        id: id,
      },
    });
  }
}
