import React, { ReactNode } from 'react';

interface PreviewDataRowProps {
  label: string;
  children?: ReactNode;
}

const PreviewDataRow: React.FC<PreviewDataRowProps> = ({ children, label }) => (
  <div className='grid grid-cols-2 mb-4 mt-2'>
    <div className='font-bold text-text ml-4 mr-1'>{label}</div>
    <div className='text-lightGrey'>{children}</div>
  </div>
);

export default PreviewDataRow;
