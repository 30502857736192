import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { CreateOrderRequest, OrderListItemResponse, OrderResponse, UpdateOrderRequest, useApiClient } from '..';

export type ServiceHeaderFilter = {
  statusProp: string;
  companyProp: string;
  technicianProp: string;
};

export const ORDERS_CACHE_KEY = 'orders';

export const useOrders = (filters?: ServiceHeaderFilter): UseQueryResult<OrderListItemResponse[]> => {
  const client = useApiClient();
  return useQuery([ORDERS_CACHE_KEY, filters], () => client.orders.getOrderList(filters ?? {}));
};

export const useOrder = (id: number): UseQueryResult<OrderResponse, { id: number }> => {
  const client = useApiClient();
  return useQuery([ORDERS_CACHE_KEY, id], async () => client.orders.getOrder({ id }));
};

export const useCreateOrderMutation = (): UseMutationResult<OrderResponse, unknown, CreateOrderRequest> => {
  const queryClient = useQueryClient();
  const client = useApiClient();
  return useMutation([ORDERS_CACHE_KEY], async (body) => client.orders.create({ requestBody: body }), {
    onSuccess: () => {
      queryClient.invalidateQueries([ORDERS_CACHE_KEY]);
    },
  });
};

export const useUpdateOrderMutation = (): UseMutationResult<
  OrderResponse,
  unknown,
  { body: UpdateOrderRequest; id: number }
> => {
  const queryClient = useQueryClient();
  const client = useApiClient();
  return useMutation([ORDERS_CACHE_KEY], async ({ body, id }) => client.orders.updateData({ requestBody: body, id }), {
    onSuccess: () => {
      queryClient.invalidateQueries([ORDERS_CACHE_KEY]);
    },
  });
};
