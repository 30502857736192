import { path } from 'ramda';

const sortByNumbers = (direction: 'asc' | 'desc', valueA: number, valueB: number): number => {
  switch (direction) {
    case 'asc':
      return valueA - valueB;
    case 'desc':
      return valueB - valueA;
  }
};
const sortByStrings = (direction: 'asc' | 'desc', valueA: string, valueB: string): number => {
  switch (direction) {
    case 'asc':
      return valueA.localeCompare(valueB, 'cs');
    case 'desc':
      return valueB.localeCompare(valueA, 'cs');
  }
};

export const sortArrayOfObjects = <SortArrayItem>(
  arrayToBeSorted: SortArrayItem[],
  pathToValueToSortBy: string[],
  direction: 'asc' | 'desc',
  valueType: 'string' | 'number',
): SortArrayItem[] => {
  if (Array.isArray(arrayToBeSorted) && arrayToBeSorted.every((arrayItem) => typeof arrayItem === 'object')) {
    return arrayToBeSorted.sort((a, b) => {
      const valueOfA = path(pathToValueToSortBy, a);
      const valueOfB = path(pathToValueToSortBy, b);

      if (valueType === 'number') {
        const conditionedValueA = typeof valueOfA === 'number' ? valueOfA : 0;
        const conditionedValueB = typeof valueOfB === 'number' ? valueOfB : 0;
        return sortByNumbers(direction, conditionedValueA, conditionedValueB);
      }

      if (valueType === 'string') {
        const conditionedValueA = typeof valueOfA === 'string' ? valueOfA : '';
        const conditionedValueB = typeof valueOfB === 'string' ? valueOfB : '';
        return sortByStrings(direction, conditionedValueA, conditionedValueB);
      }

      throw new Error(`Cannot sort by these value types ${valueOfA}, ${valueOfB}`);
    });
  }

  throw new Error('Given array is not array or array doesnt include only objects.');
};
