/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContactListResponse } from '../models/ContactListResponse';
import type { ContactResponse } from '../models/ContactResponse';
import type { ContactServiceListsResponse } from '../models/ContactServiceListsResponse';
import type { CreateContactRequest } from '../models/CreateContactRequest';
import type { ProductListResponse } from '../models/ProductListResponse';
import type { UpdateContactRequest } from '../models/UpdateContactRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ContactService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns ContactResponse
   * @throws ApiError
   */
  public createContact({ requestBody }: { requestBody: CreateContactRequest }): CancelablePromise<ContactResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/contact',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ContactListResponse
   * @throws ApiError
   */
  public getContactList({
    company,
    vat,
    withDeleted,
  }: {
    /**
     * Company name
     */
    company?: string;
    /**
     * Company VAT
     */
    vat?: string;
    /**
     * With deleted
     */
    withDeleted?: boolean;
  }): CancelablePromise<ContactListResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/contact',
      query: {
        company: company,
        vat: vat,
        withDeleted: withDeleted,
      },
    });
  }

  /**
   * @returns ContactResponse
   * @throws ApiError
   */
  public getContact({ id }: { id: number }): CancelablePromise<ContactResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/contact/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns ContactResponse
   * @throws ApiError
   */
  public updateContact({
    id,
    requestBody,
  }: {
    id: number;
    requestBody: UpdateContactRequest;
  }): CancelablePromise<ContactResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/contact/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ContactResponse
   * @throws ApiError
   */
  public deleteContact({ id }: { id: number }): CancelablePromise<ContactResponse> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/contact/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns ProductListResponse
   * @throws ApiError
   */
  public getAllCompanyProducts({
    contactId,
    productName,
    withDeleted,
  }: {
    contactId: number;
    /**
     * Product name
     */
    productName?: string;
    /**
     * With deleted
     */
    withDeleted?: boolean;
  }): CancelablePromise<ProductListResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/contact/{contactId}/products',
      path: {
        contactId: contactId,
      },
      query: {
        productName: productName,
        withDeleted: withDeleted,
      },
    });
  }

  /**
   * @returns ContactServiceListsResponse
   * @throws ApiError
   */
  public getContactServiceLists({
    contactId,
    datetime,
    branchName,
    employeeName,
    productName,
    withDeleted,
  }: {
    contactId: number;
    /**
     * Order datetime
     */
    datetime?: string;
    /**
     * Branch name
     */
    branchName?: string;
    /**
     * Employee name
     */
    employeeName?: string;
    /**
     * Product name
     */
    productName?: string;
    /**
     * With deleted
     */
    withDeleted?: boolean;
  }): CancelablePromise<ContactServiceListsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/contact/{contactId}/service-lists',
      path: {
        contactId: contactId,
      },
      query: {
        datetime: datetime,
        branchName: branchName,
        employeeName: employeeName,
        productName: productName,
        withDeleted: withDeleted,
      },
    });
  }
}
