import React from 'react';
import { useNavigate, useLocation } from 'react-router';
import { ParametersResponse } from 'src/api/types/parameters/ParametersResponse';

interface ServiceParameterProps {
  parameter: ParametersResponse;

  isLastItem?: boolean;
}

const ServiceParameters: React.FC<ServiceParameterProps> = ({ isLastItem, parameter }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isCurrentlySelected = parameter.paramKey === pathname.split('/')[2];

  return (
    <div>
      <div
        onClick={() => {
          navigate(`/parameter/${parameter.paramKey}/preview`);
        }}
        key={parameter.paramKey}
        className={`h-12  flex bg-white  rounded-br-lg  rounded-bl-lg ${isCurrentlySelected ? 'bg-rose' : ''}`}
      >
        <div
          className={`text-text cursor-pointer text-center w-full truncate self-center pt-3 border-l border-border h-12 border-b border-border pt-3 ${
            isLastItem ? 'rounded-bl-lg  border-b' : ''
          }`}
        >
          {parameter.paramTitle}
        </div>
        <div
          className={`text-text cursor-pointer text-center w-full self-center pt-3 border-r border-border h-12 border-b border-border pt-3 ${
            isLastItem ? ' rounded-br-lg border-b' : ''
          }`}
        >
          {parameter.paramValue}
        </div>
      </div>
    </div>
  );
};

export default ServiceParameters;
