import React, { ReactNode, useState } from 'react';
import SortUpIcon from 'src/components/Icons/SortUpIcon';
import SortDownIcon from 'src/components/Icons/SortDownIcon';
import { SortType } from '../NotificationPage';
import { clone } from 'ramda';
import { NotificationResponse } from 'src/api';

type SortSectionType = {
  sortItems: NotificationResponse[];
  handleSort: (sortParameters: NotificationResponse[], sortObj: SortType) => void;
  path: string[];
  type: 'string' | 'number';
  children?: ReactNode;
};

type Direction = 'asc' | 'desc';

const SortSection: React.FC<SortSectionType> = ({ sortItems, handleSort, path, type, children }) => {
  const [direction, setDirection] = useState<Direction>('asc');
  return (
    <div className='flex justify-center'>
      <button
        onClick={() => {
          setDirection(direction === 'asc' ? 'desc' : 'asc');
          handleSort(clone(sortItems), { path, direction, type });
        }}
      >
        <div className='flex'>
          {children}
          {direction === 'asc' ? (
            <div className='pt-1'>
              <SortUpIcon />
            </div>
          ) : (
            <div className='pt-1'>
              <SortDownIcon />
            </div>
          )}
        </div>
      </button>
    </div>
  );
};

type ServiceHeaderProps = {
  sortItems: NotificationResponse[];
  handleSort: (sortNotification: NotificationResponse[], sortObj: SortType) => void;
};

export type ServiceHeaderFilter = {
  notification: string;
};

const NotificationHeader: React.FC<ServiceHeaderProps> = ({ sortItems, handleSort }) => {
  return (
    <div className='flex'>
      <div className='border border-border w-full h-10 text-center bg-white rounded-tl-lg rounded-tr-lg flex justify-center pt-1 pb-2'>
        <div className='pt-1'>
          <SortSection sortItems={sortItems} handleSort={handleSort} path={['notification']} type='string'>
            Zpráva
          </SortSection>
        </div>
      </div>
    </div>
  );
};

export default NotificationHeader;
