import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Route, Routes } from 'react-router-dom';
import { useOldApiClient } from 'src/api';
import TechnicianPreview from 'src/pages/TechnicianPage/TechnicianPreview';
import TechnicianForm from 'src/pages/TechnicianPage/TechnicianForm';
import { TechnicianResponse } from 'src/api/types/technician/TechnicianResponse';

const TechnicianFetch: React.FC = () => {
  const { technicianId } = useParams();
  const Client = useOldApiClient();
  const [dataInForm, setDataInForm] = useState<TechnicianResponse & { id: number }>();

  const getDataToForm = async (): Promise<void> => {
    if (technicianId) {
      const response = await Client.get(`/technician/${technicianId}`);
      setDataInForm(response.data);
    }
  };

  useEffect(() => {
    getDataToForm();
  }, [technicianId]);

  return (
    <>
      <Routes>
        <Route path='/preview' element={<TechnicianPreview data={dataInForm} />} />
        <Route
          path='/edit'
          element={
            dataInForm ? (
              <TechnicianForm
                technicianId={dataInForm.id}
                defaultValues={{ technicianEmail: dataInForm.email, technicianName: dataInForm.name }}
              />
            ) : null
          }
        />
      </Routes>
    </>
  );
};

export default TechnicianFetch;
