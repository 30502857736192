import { string, object, SchemaOf, bool, number } from 'yup';
import { emptyToNull, nullToEmptyString } from 'src/utils/schemaUtils';
import CreateContactFormSchema from 'src/pages/ContactPage/ContactForm/schema';
import CreateBranchFromSchema from 'src/pages/ContactDetailPage/BranchForm/schema';
import CreateEmployeeFromSchema from 'src/pages/ContactDetailPage/EmployeeForm/schema';
import { inputRequired } from 'src/validation/validationMessages';
import { CreateOrderRequest, CreateProductRequest } from 'src/api';

export type ServiceFormValues = CreateOrderRequest;

export type CreateProductsFormValues = CreateProductRequest;

const CreateProductOrderFormSchema: SchemaOf<CreateProductsFormValues> = object().shape({
  productName: string().defined().default(null).required(inputRequired).transform(nullToEmptyString),
  productSerialNumber: string().nullable(true).defined().default(null).transform(nullToEmptyString),
  productProducer: string().defined().default(null).required(inputRequired).transform(nullToEmptyString),
  routineServiceId: number()
    .nullable(true)
    .defined()
    .default(null)
    .transform(emptyToNull)
    .transform((val) => (isNaN(val) ? null : val)),
  branchId: number().defined().default(null),
});

const serviceFormSchema: SchemaOf<ServiceFormValues> = object().shape({
  status: string().defined(),
  technicianId: number()
    .nullable(true)
    .defined()
    .default(null)
    .transform(emptyToNull)
    .transform((val) => (isNaN(val) ? null : val)),
  datetime: string().nullable(true).defined().default(null).transform(emptyToNull),
  quarantee: bool().defined().default(null),
  invoiceNumber: string().nullable(true).defined().default(null).transform(emptyToNull),
  productDescription: string().nullable(true).defined().default(null).transform(emptyToNull),
  previousOrderId: number().nullable(true).defined().default(null).transform(emptyToNull),
  companyId: number()
    .nullable(true)
    .defined()
    .default(null)
    .transform(emptyToNull)
    .transform((val) => (isNaN(val) ? null : val)),
  branchId: number()
    .nullable(true)
    .defined()
    .default(null)
    .transform(emptyToNull)
    .transform((val) => (isNaN(val) ? null : val)),
  employeeId: number()
    .nullable(true)
    .defined()
    .default(null)
    .transform(emptyToNull)
    .transform((val) => (isNaN(val) ? null : val)),
  productId: number()
    .nullable(true)
    .defined()
    .default(null)
    .transform(emptyToNull)
    .transform((val) => (isNaN(val) ? null : val)),
  company: CreateContactFormSchema.nullable(true).defined().default(null),
  branch: CreateBranchFromSchema.nullable(true).defined().default(null),
  product: CreateProductOrderFormSchema.nullable(true).defined().default(null),
  employee: CreateEmployeeFromSchema.nullable(true).defined().default(null),
});

export default serviceFormSchema;
