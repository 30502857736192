import React, { createContext, useContext, useEffect, useState } from 'react';
import { TechnicianResponse as TechnicianType } from 'src/api/types/technician/TechnicianResponse';
import { useOldApiClient } from 'src/api';

interface TechnicianContextProps {
  technicians: TechnicianType[];
  setTechnicians: React.Dispatch<React.SetStateAction<TechnicianType[]>>;
}

const defaultState: TechnicianContextProps = {
  technicians: [],
  setTechnicians: () => {
    return;
  },
};

interface technicianContextProviderProps {
  children: React.ReactNode;
}

export const TechnicianContext = createContext<TechnicianContextProps>(defaultState);

export const TechnicianProvider: React.FC<technicianContextProviderProps> = ({ children }) => {
  const Client = useOldApiClient();
  const [technicians, setTechnicians] = useState<TechnicianType[]>([]);

  const getTechnicians = async (): Promise<void> => {
    const response = await Client.get('/technician');

    setTechnicians(response.data);
  };

  useEffect(() => {
    getTechnicians();
  }, []);

  return <TechnicianContext.Provider value={{ technicians, setTechnicians }}>{children}</TechnicianContext.Provider>;
};

export const useTechnicians = (): TechnicianContextProps => useContext(TechnicianContext);
