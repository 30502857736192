import React from 'react';
import { useNavigate, useLocation } from 'react-router';

import { TechnicianResponse } from 'src/api/types/technician/TechnicianResponse';

interface ServiceTechnicianProps {
  technician: TechnicianResponse;
  isLastItem?: boolean;
}

const ServiceTechnician: React.FC<ServiceTechnicianProps> = ({ isLastItem, technician }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div>
      <div
        onClick={() => {
          navigate(`/technician/${technician.id}/preview`);
        }}
        key={technician.id}
        className={`h-12  flex bg-white  rounded-br-lg  rounded-bl-lg ${
          typeof technician.id === 'number' && pathname?.match(/\d+/g)?.join('') === technician.id.toString()
            ? 'bg-rose'
            : ''
        }`}
      >
        <div
          className={`text-text cursor-pointer text-center w-full self-center pt-3 border-l border-border h-12 border-b border-border pt-3 ${
            isLastItem ? 'rounded-bl-lg  border-b' : ''
          }`}
        >
          {technician.email}
        </div>
        <div
          className={`text-text cursor-pointer text-center w-full self-center pt-3 border-r border-border h-12 border-b border-border pt-3 ${
            isLastItem ? ' rounded-br-lg border-b' : ''
          }`}
        >
          {technician.name}
        </div>
      </div>
    </div>
  );
};

export default ServiceTechnician;
