/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateTechnicianRequest } from '../models/CreateTechnicianRequest';
import type { TechnicianResponse } from '../models/TechnicianResponse';
import type { UpdateTechnicianRequest } from '../models/UpdateTechnicianRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TechnicianService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns TechnicianResponse
   * @throws ApiError
   */
  public getTechnicianList({
    name,
    email,
    withDeleted,
  }: {
    /**
     * Technician name
     */
    name?: string;
    /**
     * Technician email
     */
    email?: string;
    /**
     * With deleted
     */
    withDeleted?: boolean;
  }): CancelablePromise<Array<TechnicianResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/technician',
      query: {
        name: name,
        email: email,
        withDeleted: withDeleted,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public createTechnician({ requestBody }: { requestBody: CreateTechnicianRequest }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/technician',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns TechnicianResponse
   * @throws ApiError
   */
  public getTechnician({ id }: { id: number }): CancelablePromise<TechnicianResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/technician/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public updateTechnician({
    id,
    requestBody,
  }: {
    id: number;
    requestBody: UpdateTechnicianRequest;
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/technician/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public deleteTechnician({ id }: { id: number }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/technician/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public restoreTechnician({ id }: { id: number }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/technician/{id}/restore-technician',
      path: {
        id: id,
      },
    });
  }
}
