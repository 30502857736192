import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditButton from 'src/components/EditButton';
import RowButton from '../../../components/RowButton';
import { MaterialResponse } from 'src/api/types/material/MaterialResponse';
import MaterialFormCard from '../MaterialFormCard';
import { useOldApiClient } from 'src/api';
import { SubmitHandler, useForm } from 'react-hook-form';

interface PreviewMaterialFormProps {
  data?: MaterialResponse & { id: number };
}

const MaterialPreview: React.FC<PreviewMaterialFormProps> = ({ data }) => {
  const navigate = useNavigate();
  const { materialId } = useParams();

  const Client = useOldApiClient();

  const form = useForm<MaterialResponse>({
    defaultValues: data,
  });

  const MATERIAL_ROUTE = '/material';

  const { handleSubmit } = form;

  const onSubmit: SubmitHandler<MaterialResponse> = async (data) => {
    try {
      const response = await Client.delete(`material/${materialId}`, {
        data,
      });
      if (response.status === 200) {
        navigate(MATERIAL_ROUTE);
      }
    } catch (error) {
      console.log('test', error);
    }
  };

  return (
    <div className='flex-col w-2/4 md:w-full md:pl-3 ml-4'>
      <div className='flex w-11/12'>
        <div className='grid grid-row-2 mb-2 w-full'>
          <div className='flex w-full'>
            <div className='mt-4 text-4xl text-text font-bold flex w-full'>Materiál</div>

            <div className='flex flex-row items-center pt-4'>
              <EditButton onClick={() => navigate('/material')}>Zpět</EditButton>
              <RowButton onClick={() => navigate(`/material/${materialId}/edit`)}>Upravit</RowButton>
            </div>
          </div>
        </div>
      </div>
      <form className='w-11/12' onSubmit={handleSubmit(onSubmit)}>
        <MaterialFormCard>
          <div className='grid grid-cols-2 mb-4 mt-2'>
            <div className='font-bold text-text ml-4 mr-1'>Název</div>
            <div className='text-lightGrey'>{data?.materialName}</div>
          </div>
          <div className='grid grid-cols-2 mb-4 mt-2'>
            <div className='font-bold text-text ml-4 mr-1'>Cena</div>
            <div className='text-lightGrey'> {data?.materialValue} </div>
          </div>
          <div className='grid grid-cols-2 mb-4 mt-2'>
            <div className='font-bold text-text ml-4 mr-1'>Kód</div>
            <div className='text-lightGrey'> {data?.materialCode} </div>
          </div>
        </MaterialFormCard>
        <div className='mt-5 grid place-items-end'>
          <div className='flex-row mr-4 mb-6'>
            <RowButton type='submit'>Odstranit</RowButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MaterialPreview;
