import React, { useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import HorizontalRow from 'src/components/HorizontalRow';
import EditButton from '../../../components/EditButton';
import ServiceFormCard from '../ServiceFormCard';
import { useTechnicians } from 'src/store/technicianContext';
import RowButton from 'src/components/RowButton';
import DocumentIcon from 'src/components/Icons/DocumentIcon';
import PreviewDataRow from 'src/components/PreviewDataRow';
import { OrderResponse } from 'src/api';
import { useRoutineServices } from 'src/api/hooks/useRoutineServices';

interface PreviewServiceFormProps {
  data?: OrderResponse & { id: number } & { orderUrl: string };
}

const PreviewServiceForm: React.FC<PreviewServiceFormProps> = ({ data }) => {
  const { orderId } = useParams();
  const technicians = useTechnicians();
  const navigate = useNavigate();
  const routineServices = useRoutineServices();

  const routineServiceName = useMemo(() => {
    return routineServices.data?.find((routineService) => routineService.id == data?.product.routineServiceId)
      ?.routineName;
  }, [routineServices.data, data?.product.routineServiceId]);

  const handleCloseForm = (): void => {
    navigate('/');
  };

  const date = data?.datetime && format(new Date(data?.datetime), 'dd.MM.yyyy HH:mm');

  return (
    <div className='flex-col w-2/5 md:w-full md:pl-3'>
      <div className='flex w-11/12'>
        <div className='grid grid-row-2 mb-2 w-full'>
          <div className='flex w-full'>
            <div className='mt-4 text-4xl text-text font-bold flex w-10/12 ml-4'>
              Zakázka
              {data?.id ? ` #${data.id}` : ''}{' '}
            </div>
            {data?.id ? (
              <div className='flex flex-row items-center pt-4'>
                <EditButton onClick={() => handleCloseForm()}>Zpět</EditButton>
                {/*TODO: přidat ID statusu */}
                {data.status === 'Dokončeno' || data.status === 'Vyfakturováno' ? (
                  ''
                ) : (
                  <RowButton onClick={() => navigate(`/order/${orderId}/edit`)}>Upravit</RowButton>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <form className='lg:ml-4 ntb:ml-4 lg:w-11/12 ntb:w-11/12 md:mr-5 '>
        <ServiceFormCard>
          <div className='flex space-x-44 mb-2 mt-4'>
            <div className='flex font-bold text-text text-2xl ml-4'>
              Zakázka
              {data?.status !== 'Nový' ? (
                <a href={`${data?.orderUrl}`} target='blank' className='cursor-pointer ml-2'>
                  <DocumentIcon />
                </a>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className='text-lightGrey text-sm ml-4'>Základní informace o zakázce</div>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4'>
            <div className='font-bold text-text ml-4 mr-6'>Status</div>
            <div className='text-lightGrey'> {data?.status}</div>
          </div>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4'>
            <div className='font-bold text-text ml-4 mr-3'>Technik</div>
            <div className='text-lightGrey'>
              {technicians.technicians.find((item) => item.id === data?.technicianId)?.name}
            </div>
          </div>
          <HorizontalRow />
          <PreviewDataRow label='datum'>{date}</PreviewDataRow>
          <HorizontalRow />
          <div className='font-bold text-text text-2xl ml-4'>Zákazník</div>

          <div className='text-lightGrey text-sm ml-4 mb-2'>Kontakt na zákazníka žádající o zakázku</div>
          <HorizontalRow />
          <PreviewDataRow label='Název společnosti'>{data?.company?.companyName}</PreviewDataRow>
          <HorizontalRow />
          <PreviewDataRow label='IČO'>{data?.company?.vat}</PreviewDataRow>
          <HorizontalRow />
          <PreviewDataRow label='DIČ'>{data?.company?.in}</PreviewDataRow>
          <HorizontalRow />
          <PreviewDataRow label='Město'>{data?.company?.city}</PreviewDataRow>
          <HorizontalRow />
          <PreviewDataRow label='Ulice'>{data?.company?.street}</PreviewDataRow>
          <HorizontalRow />
          <PreviewDataRow label='PSČ'>{data?.company?.zip}</PreviewDataRow>
          <HorizontalRow />
          <PreviewDataRow label='Telefonní kontakt'>{data?.company?.phoneNumber}</PreviewDataRow>
          <HorizontalRow />
          <PreviewDataRow label='Email'>{data?.company?.email}</PreviewDataRow>
          <HorizontalRow />
          <PreviewDataRow label='Zasílání notifikací'>
            {data?.company.notification === true ? 'ano' : 'ne'}
          </PreviewDataRow>
          <HorizontalRow />

          <div className='font-bold text-text text-2xl ml-4'>Pobočka</div>

          <div className='text-lightGrey text-sm ml-4 mb-2'>Informace o pobočce</div>

          <HorizontalRow />
          <PreviewDataRow label='Název pobočky'>{data?.branch?.name}</PreviewDataRow>
          <HorizontalRow />
          <PreviewDataRow label='Město'>{data?.branch?.city}</PreviewDataRow>
          <HorizontalRow />
          <PreviewDataRow label='Ulice'>{data?.branch?.street}</PreviewDataRow>
          <HorizontalRow />
          <PreviewDataRow label='PSČ'>{data?.branch?.zip}</PreviewDataRow>
          <HorizontalRow />
          <PreviewDataRow label='IČO pobočky'>{data?.branch?.vat}</PreviewDataRow>
          <HorizontalRow />
          <PreviewDataRow label='Kontaktní osoba'>{data?.branch?.contactPerson}</PreviewDataRow>
          <HorizontalRow />
          <PreviewDataRow label='Telefonní kontakt'>{data?.branch?.phoneNumber}</PreviewDataRow>
          <HorizontalRow />
          <PreviewDataRow label='Email'>{data?.branch?.email}</PreviewDataRow>
          <HorizontalRow />
          <div className='font-bold text-text text-2xl ml-4'>Osoba</div>
          <div className='text-lightGrey text-sm ml-4'>Informace o osobě</div>
          <HorizontalRow />
          <PreviewDataRow label='Jméno'>{data?.employee?.name}</PreviewDataRow>
          <HorizontalRow />
          <PreviewDataRow label='Telefonní kontakt'>{data?.employee?.phoneNumber}</PreviewDataRow>
          <HorizontalRow />
          <PreviewDataRow label='Email'>{data?.employee?.email}</PreviewDataRow>
          <HorizontalRow />

          <div className='font-bold text-text text-2xl ml-4'>Produkt</div>

          <div className='text-lightGrey text-sm ml-4'>Informace o produktu</div>
          <HorizontalRow />

          <PreviewDataRow label='Výrobce'>{data?.product?.productProducer}</PreviewDataRow>
          <HorizontalRow />
          <PreviewDataRow label='Název'>{data?.product?.productName}</PreviewDataRow>
          <HorizontalRow />

          <PreviewDataRow label='Sériové číslo'>{data?.product?.productSerialNumber}</PreviewDataRow>
          <HorizontalRow />

          <PreviewDataRow label='Pravidelný servis'>{routineServiceName}</PreviewDataRow>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4'>
            <div className='font-bold text-text ml-4 mr-2'>Číslo faktury</div>
            <div className='text-lightGrey'> {data?.invoiceNumber}</div>
          </div>
          <HorizontalRow />
          <div className='grid grid-cols-2 mb-4'>
            <div className='font-bold text-text ml-4'>Záruční oprava</div>
            <div className='text-lightGrey'> {data?.quarantee === true ? 'ano' : 'ne'}</div>
          </div>
          <HorizontalRow />

          <div className='grid grid-cols-2 mb-4'>
            <div className='font-bold text-text ml-4 mr-1 mb-10'>Popis závady</div>
            <div className='text-lightGrey'> {data?.productDescription}</div>
          </div>
        </ServiceFormCard>

        <div className='mt-5 grid place-items-end mr-20'>
          <div className='flex-row mr-4 mb-6'>
            <EditButton onClick={() => handleCloseForm()}>Zpět</EditButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PreviewServiceForm;
