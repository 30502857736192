import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HorizontalRow from 'src/components/HorizontalRow';
import EditButton from 'src/components/EditButton';
import RowButton from '../../../components/RowButton';
import BranchFormCard from '../BranchFormCard';
import Textfield from 'src/components/Textfield';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useOldApiClient } from 'src/api';
import CreateEmployeeFromSchema, { CreateEmployeeFormValues } from './schema';
import { CreateEmployeeRequest } from 'src/api/types/employee/CreateEmployeeRequest';
import DataRow from 'src/components/DataRow';

interface EmployeeFormProps {
  defaultValues?: Partial<CreateEmployeeRequest>;
  employeeId?: number;
}

const EmployeeForm: React.FC<EmployeeFormProps> = ({ defaultValues, employeeId }) => {
  const navigate = useNavigate();
  const { contactId } = useParams();

  const Client = useOldApiClient();

  const form = useForm<CreateEmployeeFormValues>({
    resolver: yupResolver(CreateEmployeeFromSchema),
    defaultValues: defaultValues,
  });
  const { handleSubmit, control } = form;

  const onSubmit: SubmitHandler<CreateEmployeeRequest> = async (data) => {
    try {
      if (employeeId) {
        const response = await Client.put(`/contact/${contactId}/employee/${employeeId}`, data);
        if (response.status === 200) {
          navigate(`/contact-detail/${contactId}`);
        }
      } else {
        const response = await Client.post(`/contact/${contactId}/employee`, data);
        if (response.status === 201) {
          navigate(`/contact-detail/${contactId}`);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='flex-col w-full mt-2 md:w-full md:pl-3 ntb:pl-10 lg:ml-4'>
      <div className='ml-2 mt-2 text-4xl text-text font-bold'>{employeeId ? 'Editace osoby' : ' Nová osoba'}</div>
      <FormProvider {...form}>
        <form className='lg:w-11/12 ntb:w-11/12 md:mr-5 flex-col' onSubmit={handleSubmit(onSubmit)}>
          <BranchFormCard>
            <div className='mt-2'>
              <DataRow label='Jméno'>
                <Controller
                  control={control}
                  name='name'
                  render={({ field, fieldState }) => <Textfield {...field} error={fieldState.error?.message} />}
                />
              </DataRow>
              <HorizontalRow />
            </div>

            <DataRow label='Telefonní číslo'>
              <Controller
                control={control}
                name='phoneNumber'
                render={({ field, fieldState }) => <Textfield {...field} error={fieldState.error?.message} />}
              />
            </DataRow>
            <HorizontalRow />

            <DataRow label='Email'>
              <Controller
                control={control}
                name='email'
                render={({ field, fieldState }) => <Textfield {...field} error={fieldState.error?.message} />}
              />
            </DataRow>
          </BranchFormCard>
          <div className='mt-5 grid place-items-end'>
            <div className='flex-row mr-4 mb-6'>
              <EditButton onClick={() => navigate(`/contact-detail/${contactId}`)}>Zavřít</EditButton>
              <RowButton type='submit'>Uložit změny</RowButton>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default EmployeeForm;
