import { CreateBranchRequest } from 'src/api';
import { emptyToNull } from 'src/utils/schemaUtils';
import { inputRequired } from 'src/validation/validationMessages';
import { object, SchemaOf, string } from 'yup';

export type CreateBranchFormValues = CreateBranchRequest;

const CreateBranchFromSchema: SchemaOf<CreateBranchFormValues> = object().shape({
  name: string().required(inputRequired),
  city: string().required(inputRequired),
  street: string().required(inputRequired),
  zip: string().required(inputRequired),
  phoneNumber: string().required(inputRequired),
  email: string().email().nullable(true).transform(emptyToNull).defined().default(null),
  vat: string().required(inputRequired),
  contactPerson: string().required(inputRequired),
});

export default CreateBranchFromSchema;
