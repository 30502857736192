import { pickAll } from 'ramda';
import { useCallback, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ProductResponse, useOldApiClient } from 'src/api';
import { ServiceFormValues } from './schema';

export const ProductsWatcher: React.FC = () => {
  const Client = useOldApiClient();
  const { setValue, control } = useFormContext<ServiceFormValues>();
  const [productSelect, companyId, branchId] = useWatch({ name: ['productId', 'branchId', 'companyId'], control });

  const getFillProductData = async (
    branchId: number,
    companyId: number,
    productId: number,
  ): Promise<ProductResponse> => {
    const fillProductData = await Client.get(`/contact/${companyId}/branch/${branchId}/product/${productId}`);
    return fillProductData.data;
  };

  const setProductInfo = useCallback(
    (data?: ProductResponse) => {
      const fields = ['productName', 'productProducer', 'productSerialNumber', 'routineServiceId'] as const;

      const obj = pickAll<ProductResponse, Pick<ProductResponse, typeof fields[number]>>(
        fields,
        data ?? ({} as ProductResponse),
      );

      setValue('productId', data?.id ?? null);

      Object.keys(obj).forEach((key) => {
        const _key = key as keyof typeof obj;
        setValue(`product.${_key}`, obj[_key] ?? null);
      });
    },
    [setValue],
  );

  const fillProductData = (companyId: number, branchId: number, productId: number): void => {
    getFillProductData(companyId, branchId, productId).then((data) => {
      setProductInfo(data);
    });
  };

  useEffect(() => {
    if (productSelect != null && companyId != null && branchId != null) {
      fillProductData(companyId, branchId, productSelect);
    }
  }, [productSelect, companyId, branchId]);

  return null;
};
