import React from 'react';
import { useNavigate } from 'react-router-dom';

import EditButton from 'src/components/EditButton';
import RowButton from '../../../components/RowButton';
import PaymentMethodFormCard from '../PaymentMethodFormCard';
import Textfield from 'src/components/Textfield';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { CreatePaymentMethodResponse } from 'src/api/types/paymentMethods/CreatePaymentMethodRespons';

import { useOldApiClient } from 'src/api';

interface PaymentMethodFormProps {
  defaultValues?: Partial<CreatePaymentMethodResponse>;
  paymentId?: number;
}

const PaymentMethodForm: React.FC<PaymentMethodFormProps> = ({ defaultValues, paymentId }) => {
  const navigate = useNavigate();
  const Client = useOldApiClient();

  const form = useForm<CreatePaymentMethodResponse>({
    defaultValues: defaultValues,
  });
  const { handleSubmit, control } = form;

  const onSubmit: SubmitHandler<CreatePaymentMethodResponse> = async (data) => {
    try {
      if (paymentId) {
        const response = await Client.put(`payment-method/${paymentId}`, {
          paymentMethodName: data.paymentMethodName,
        });
        if (response.status === 200) {
          navigate('/payment-method');
        }
      } else {
        const response = await Client.post('payment-method', {
          paymentMethodName: data.paymentMethodName,
        });
        if (response.status === 201) {
          navigate('/payment-method');
        }
      }
    } catch (error) {
      console.log('test', error);
    }
  };

  return (
    <div className='flex-col w-2/4 md:w-full md:pl-3 ntb:ml-4 lg:ml-4 ntb:ml-4'>
      <div className='mt-2 mb-4 text-4xl text-text font-bold'>Platební metoda</div>
      <form className='lg:w-11/12 mt-2 ntb:w-11/12 md:mr-5 flex-col' onSubmit={handleSubmit(onSubmit)}>
        <PaymentMethodFormCard>
          <div className='grid grid-cols-2 mb-4 mt-4'>
            <div className='font-bold text-text ml-4'>Platební metoda</div>
            <Controller control={control} name='paymentMethodName' render={({ field }) => <Textfield {...field} />} />
          </div>
        </PaymentMethodFormCard>
        <div className='mt-5 grid place-items-end'>
          <div className='flex-row mr-4 mb-6'>
            <EditButton onClick={() => navigate('/payment-method')}>Zavřít</EditButton>
            <RowButton type='submit'>Uložit změny</RowButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PaymentMethodForm;
