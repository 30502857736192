import React from 'react';
import { useNavigate, useLocation } from 'react-router';

import { UserListResponse } from 'src/api/types/user/UserListResponse';

interface ServiceUserProps {
  user: UserListResponse;
  isLastItem?: boolean;
}

const ServiceUser: React.FC<ServiceUserProps> = ({ isLastItem, user }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div>
      <div
        onClick={() => {
          navigate(`/user/${user.id}/preview`);
        }}
        key={user.id}
        className={`h-12  flex bg-white  rounded-br-lg  rounded-bl-lg ${
          typeof user.id === 'number' && pathname?.match(/\d+/g)?.join('') === user.id.toString() ? 'bg-rose' : ''
        }`}
      >
        <div
          className={`text-text cursor-pointer text-center w-full self-center pt-3 border-l border-r border-border h-12 border-b border-border pt-3 ${
            isLastItem ? 'rounded-bl-lg rounded-br-lg border-b' : ''
          }`}
        >
          {user.userEmail}
        </div>
      </div>
    </div>
  );
};

export default ServiceUser;
