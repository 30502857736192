import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ContactListResponse, useApiClient } from '..';

export type ServiceHeaderFilter = {
  company: string;
};

export const useContacts = (filters?: ServiceHeaderFilter): UseQueryResult<ContactListResponse[]> => {
  const client = useApiClient();
  return useQuery(['contacts', filters], () => client.contact.getContactList(filters ?? {}));
};
