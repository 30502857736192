import React from 'react';
import { FieldProps } from 'src/types/commonTypes';

export type CheckboxProps = FieldProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement, boolean | null>;

const Checkbox: React.ForwardRefRenderFunction<HTMLInputElement, CheckboxProps> = (props, ref) => {
  const { value, checked, ...rest } = props;

  return (
    <input
      type='checkbox'
      className='w-5 h-5 rounded-md shadow-md border-2 border-gray appearance-none checked:bg-primary'
      {...rest}
      checked={value ?? checked}
      ref={ref}
    ></input>
  );
};

export default React.forwardRef(Checkbox);
