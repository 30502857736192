import React from 'react';
import SidePanel from '../SidePanel';

type LayoutProps = {
  children: React.ReactNode;
};

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className='flex'>
      <SidePanel />
      {children}
    </div>
  );
};
