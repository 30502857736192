import React from 'react';
import { Outlet, Route, Routes, useParams } from 'react-router-dom';
import { useOrder } from 'src/api/hooks/useOrders';
import PreviewServiceForm from 'src/pages/HomePage/PreviewServiceForm';
import ServiceForm from 'src/pages/HomePage/ServiceForm';

type ServicePageParams = {
  orderId: string;
};

const ServiceFetch: React.FC = () => {
  const { orderId } = useParams<ServicePageParams>();
  const { data } = useOrder(Number(orderId));

  return (
    <>
      <Outlet />
      <Routes>
        <Route path='/preview' element={<PreviewServiceForm data={data} />} />
        <Route
          path='/edit'
          element={
            data ? (
              <ServiceForm serviceNumber={Number(orderId)} data={{ ...data, status: data.status ?? undefined }} />
            ) : null
          }
        />
      </Routes>
    </>
  );
};

export default ServiceFetch;
