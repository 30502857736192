import { string, object, SchemaOf } from 'yup';
import { emptyToNull } from 'src/utils/schemaUtils';
import { CreateUserRequest } from 'src/api/types/user/CreateUserRequest';

export type CreateUserFormValues = CreateUserRequest;

const CreateUserFormSchema: SchemaOf<CreateUserFormValues> = object().shape({
  email: string().email().defined().default(null).transform(emptyToNull),
});

export default CreateUserFormSchema;
