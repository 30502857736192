import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ServiceFormTechnicianValues } from './technicianSchema';
import { ParametersResponse } from 'src/api/types/parameters/ParametersResponse';
import { useOldApiClient } from 'src/api';

export const PriceWatcher: React.FC = () => {
  const Client = useOldApiClient();
  const [parameters, setParameters] = useState<ParametersResponse[]>([]);
  const { setValue, control } = useFormContext<ServiceFormTechnicianValues>();

  const getParameters = async (): Promise<void> => {
    const response = await Client.get('/parameters');

    setParameters(response.data);
  };

  useEffect(() => {
    getParameters();
  }, []);

  const findServiceDuration =
    parameters.find((parameter) => parameter.paramKey === 'serviceDurationRate')?.paramValue || '';

  const findServiceMileage =
    parameters.find((parameter) => parameter.paramKey === 'serviceMileageRate')?.paramValue || '';

  const findServiceTimeOnRoad =
    parameters.find((parameter) => parameter.paramKey === 'serviceTimeOnRoadRate')?.paramValue || '';

  const serviceDuration = useWatch({ name: 'serviceDuration', control }) as unknown as string;
  const serviceMileage = useWatch({ name: 'serviceMileage', control }) as unknown as string;
  const serviceTimeOnRoad = useWatch({ name: 'serviceTimeOnRoad', control }) as unknown as string;
  const materials = useWatch({ name: 'materials', control });
  const materialsSum = materials.reduce(
    (prev, current) =>
      current?.price && current?.quantity
        ? prev +
          parseFloat(current.price.toString().replace(',', '.').replace(/\s/g, '')) *
            parseFloat(current.quantity.toString().replace(',', '.').replace(/\s/g, ''))
        : prev,
    0,
  );

  const finalPrice =
    (serviceDuration && serviceDuration !== '' ? parseFloat(serviceDuration.toString().replace(',', '.')) : 0) *
      parseFloat(findServiceDuration) +
    (serviceMileage && serviceMileage !== '' ? parseFloat(serviceMileage.toString().replace(',', '.')) : 0) *
      parseFloat(findServiceMileage) +
    (serviceTimeOnRoad && serviceTimeOnRoad !== '' ? parseFloat(serviceTimeOnRoad.toString().replace(',', '.')) : 0) *
      parseFloat(findServiceTimeOnRoad) +
    materialsSum;

  useEffect(() => {
    setValue('serviceTotalPrice', finalPrice);
  }, [finalPrice]);

  return null;
};
