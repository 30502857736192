import React, { ReactNode } from 'react';

type ProductFormCardProps = {
  children?: ReactNode;
};

const ProductFormCard: React.FC<ProductFormCardProps> = ({ children }) => (
  <div className='h-3/4 rounded-md shadow-md bg-white border-border border-inherit border'>{children}</div>
);

export default ProductFormCard;
