import { CreateRoutineServicesRequest } from 'src/api';
import { inputRequired } from 'src/validation/validationMessages';

import { object, SchemaOf, string, number } from 'yup';

export type ServiceRoutineFormValues = CreateRoutineServicesRequest;

const ServiceRoutineFormValues: SchemaOf<ServiceRoutineFormValues> = object().shape({
  routineValue: number().defined().default(null).required(inputRequired),
  routineName: string().defined().default(null).required(inputRequired),
});

export default ServiceRoutineFormValues;
