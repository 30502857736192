import React, { ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { useOldApiClient } from 'src/api';
import { ContactResponse } from 'src/api/types/contact/ContactResponse';

import HorizontalRow from 'src/components/HorizontalRow';

type ContactFormCardProps = {
  children?: ReactNode;
};

const ContactDetailPageCard: React.FC<ContactFormCardProps> = () => {
  const { contactId } = useParams();
  const Client = useOldApiClient();
  const [data, setData] = useState<ContactResponse & { id: number }>();

  const getDataToForm = async (): Promise<void> => {
    const response = await Client.get(`/contact/${contactId}`);
    setData(response.data);
  };

  useEffect(() => {
    getDataToForm();
  }, [contactId]);

  return (
    <>
      <div className='mt-2 text-4xl text-text font-bold flex lg:w-6/12 ntb:w-6/12 ml-2 mb-2'>{data?.companyName}</div>

      <div className='w-6/12 md:w-11/12 mb-2 rounded-md shadow-md bg-white border-border border-inherit border ml-2'>
        <div className='grid grid-cols-4 mb-2 mt-2'>
          <div className='font-bold text-text ml-4 mr-1'>IČO</div>
          <div className='text-lightGrey'>{data?.vat}</div>
          <div className='font-bold text-text ml-4 mr-1'>Telefonní číslo</div>
          <div className='text-lightGrey'>{data?.phoneNumber}</div>
        </div>
        <HorizontalRow />
        <div className='grid grid-cols-4 mb-2 mt-2'>
          <div className='font-bold text-text ml-4 mr-1'>Název společnosti</div>
          <div className='text-lightGrey'>{data?.companyName}</div>
          <div className='font-bold text-text ml-4 mr-1'>Město</div>
          <div className='text-lightGrey'>{data?.city}</div>
        </div>
        <HorizontalRow />
        <div className='grid grid-cols-4 mb-2 mt-2'>
          <div className='font-bold text-text ml-4 mr-1'>Ulice</div>
          <div className='text-lightGrey'>{data?.street}</div>
          <div className='font-bold text-text ml-4 mr-1'>PSČ</div>
          <div className='text-lightGrey'>{data?.zip}</div>
        </div>
        <HorizontalRow />
        <div className='grid grid-cols-4 mb-2 mt-2'>
          <div className='font-bold text-text ml-4 mr-1'>Email</div>
          <div className='text-lightGrey'>{data?.email}</div>
        </div>
      </div>
    </>
  );
};

export default ContactDetailPageCard;
