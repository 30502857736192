import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserResponse } from 'src/api/types/user/UserResponse';
import EditButton from 'src/components/EditButton';
import RowButton from '../../../components/RowButton';
import UserFormCard from '../UserFormCard';
interface PreviewUserFormProps {
  data?: UserResponse & { id: number };
}
const PreviewUserForm: React.FC<PreviewUserFormProps> = ({ data }) => {
  const { userId } = useParams();
  const navigate = useNavigate();

  return (
    <div className='flex-col w-2/4 md:w-full md:pl-3 ml-4'>
      <div className='flex w-11/12'>
        <div className='grid grid-row-2 mb-4 w-full'>
          <div className='flex w-full'>
            <div className='mt-2 text-4xl text-text font-bold flex w-full'>Uživatel</div>

            <div className='flex flex-row items-center pt-2'>
              <EditButton onClick={() => navigate('/user')}>Zpět</EditButton>
              <RowButton onClick={() => navigate(`/user/${userId}/edit`)}>Upravit</RowButton>
            </div>
          </div>
        </div>
      </div>
      <form className='w-11/12'>
        <UserFormCard>
          <div className='grid grid-cols-2 mb-4 mt-2'>
            <div className='font-bold text-text ml-4 mr-1'>Email</div>
            <div className='text-lightGrey'>{data?.userEmail}</div>
          </div>
          <div className='grid grid-cols-2 mb-4 mt-2'>
            <div className='font-bold text-text ml-4 mr-1'>Neaktivní uživatel</div>
            <div className='text-lightGrey'>{data?.deletedAt === null ? 'ne' : 'ano'}</div>
          </div>
        </UserFormCard>
      </form>
    </div>
  );
};

export default PreviewUserForm;
