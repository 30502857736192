import React from 'react';
import { PaymentMethodResponse } from 'src/api/types/paymentMethods/PaymentMethodResponse';

import ServicePaymentMethod from 'src/components/ServicePaymentMethod';

type PaymentMethodProps = {
  paymentMethods: PaymentMethodResponse[];
};

const PaymentMethodCard: React.FC<PaymentMethodProps> = ({ paymentMethods }) => {
  return (
    <>
      {paymentMethods.map((paymentMethod, index) => (
        <ServicePaymentMethod
          key={paymentMethod.id}
          paymentMethod={paymentMethod}
          isLastItem={paymentMethods.length - 1 === index}
        />
      ))}
    </>
  );
};

export default PaymentMethodCard;
