import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useOldApiClient } from 'src/api';
import { BranchResponse } from 'src/api/types/branches/BranchResponse';
import EditButton from 'src/components/EditButton';
import HorizontalRow from 'src/components/HorizontalRow';
import PreviewDataRow from 'src/components/PreviewDataRow';
import RowButton from '../../../components/RowButton';
import BranchFormCard from '../BranchFormCard';

interface PreviewBranchFormProps {
  data?: BranchResponse & { id: number };
}
const BranchPreview: React.FC<PreviewBranchFormProps> = ({ data }) => {
  const { contactId, branchId } = useParams();
  const Client = useOldApiClient();
  const navigate = useNavigate();

  const form = useForm<BranchResponse>({
    defaultValues: data,
  });

  const { handleSubmit } = form;

  const onSubmit: SubmitHandler<BranchResponse> = async (data) => {
    try {
      const response = await Client.delete(`contact/${contactId}/branch/${branchId}`, {
        data,
      });
      if (response.status === 200) {
        navigate(`/contact-detail/${contactId}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='flex-col md:pl-3 ml-4'>
      <div className='flex w-11/12'>
        <div className='grid grid-row-2 mb-2 w-full'>
          <div className='flex w-full'>
            <div className='mt-2 text-4xl text-text font-bold flex w-full'>Detail pobočky</div>

            <div className='flex flex-row items-center pt-2'>
              <EditButton onClick={() => navigate(`/contact-detail/${contactId}`)}>Zpět</EditButton>
              <RowButton onClick={() => navigate(`/contact-detail/${contactId}/branch/${branchId}/edit`)}>
                Upravit
              </RowButton>
            </div>
          </div>
        </div>
      </div>
      <form className='w-11/12' onSubmit={handleSubmit(onSubmit)}>
        <BranchFormCard>
          <PreviewDataRow label='Název pobočky'>{data?.name}</PreviewDataRow>
          <HorizontalRow />
          <PreviewDataRow label='IČO pobočky'>{data?.vat}</PreviewDataRow>
          <HorizontalRow />
          <PreviewDataRow label='Město'>{data?.city}</PreviewDataRow>
          <HorizontalRow />
          <PreviewDataRow label='Ulice'>{data?.street}</PreviewDataRow>
          <HorizontalRow />
          <PreviewDataRow label='PSČ'>{data?.zip}</PreviewDataRow>
          <HorizontalRow />
          <PreviewDataRow label='Telefonní číslo'>{data?.phoneNumber}</PreviewDataRow>
          <HorizontalRow />
          <PreviewDataRow label='Email'>{data?.email}</PreviewDataRow>
          <HorizontalRow />
          <PreviewDataRow label='Kontaktní osoba'>{data?.contactPerson}</PreviewDataRow>
        </BranchFormCard>
        <div className='flex justify-end mt-4'>
          <RowButton type='submit'>Odstranit</RowButton>
        </div>
      </form>
    </div>
  );
};

export default BranchPreview;
