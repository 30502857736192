import React from 'react';
import Spinner from '../Spinner';

export type TechnicianRowButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & { loading?: boolean };

const TechnicianRowButton = React.forwardRef((props: TechnicianRowButtonProps, ref: React.Ref<HTMLButtonElement>) => (
  <button className='bg-primary text-white text-xs font-medium w-48 h-9 rounded-md' ref={ref} {...props}>
    {props.loading ? (
      <>
        <Spinner />
        Načítání...
      </>
    ) : (
      props.children
    )}
  </button>
));

TechnicianRowButton.displayName = 'TechnicianRowButton';

export default TechnicianRowButton;
