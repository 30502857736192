import React from 'react';
import { ProductListResponse } from 'src/api';

import ServiceProducts from 'src/components/ServiceProducts';

type ProductsProps = {
  products: ProductListResponse[];
};

const ProductsCard: React.FC<ProductsProps> = ({ products }) => {
  return (
    <>
      {products.map((product, index) => (
        <ServiceProducts key={product.id} product={product} isLastItem={products.length - 1 === index} />
      ))}
    </>
  );
};

export default ProductsCard;
