import React, { ReactNode, useState } from 'react';
import SortUpIcon from 'src/components/Icons/SortUpIcon';
import SortDownIcon from 'src/components/Icons/SortDownIcon';
import { clone } from 'ramda';
import { SortType } from '../PaymentMethodPage';
import { PaymentMethodResponse } from 'src/api/types/paymentMethods/PaymentMethodResponse';
import { Controller, useForm } from 'react-hook-form';
import SortingTextfield from 'src/components/FilterTextfield';

type SortSectionType = {
  sortPaymentMethods: PaymentMethodResponse[];
  handleSort: (sortContacts: PaymentMethodResponse[], sortObj: SortType) => void;
  path: string[];
  type: 'string';
  children?: ReactNode;
};

type Direction = 'asc' | 'desc';

const SortSection: React.FC<SortSectionType> = ({ sortPaymentMethods, handleSort, path, type, children }) => {
  const [direction, setDirection] = useState<Direction>('asc');
  return (
    <div className='flex justify-center'>
      <button
        onClick={() => {
          setDirection(direction === 'asc' ? 'desc' : 'asc');
          handleSort(clone(sortPaymentMethods), { path, direction, type });
        }}
      >
        <div className='flex  lg:mr-6'>
          {children}
          {direction === 'asc' ? (
            <div className='pt-1'>
              <SortUpIcon />
            </div>
          ) : (
            <div className='pt-1'>
              <SortDownIcon />
            </div>
          )}
        </div>
      </button>
    </div>
  );
};

type ServiceHeaderProps = {
  sortPaymentMethods: PaymentMethodResponse[];
  handleSort: (sortPaymentMethods: PaymentMethodResponse[], sortObj: SortType) => void;
  isFilterOpen: boolean;
  handleFilter: (queryString: ServiceHeaderFilter) => void;
};

export type ServiceHeaderFilter = {
  pm: string;
};

const serviceHeaderFilterDefaultValues = {
  pm: '',
};

const PaymentMethodHeader: React.FC<ServiceHeaderProps> = ({
  sortPaymentMethods,
  handleSort,
  isFilterOpen,
  handleFilter,
}) => {
  const { control, getValues, reset } = useForm<ServiceHeaderFilter>({
    defaultValues: serviceHeaderFilterDefaultValues,
  });

  const formHandleBlur = (values: ServiceHeaderFilter): void => {
    handleFilter(values);
  };

  React.useEffect(() => {
    reset();
    handleFilter(serviceHeaderFilterDefaultValues);
  }, [isFilterOpen]);
  return (
    <div className='flex'>
      <div
        className={`border border-border w-full align-center  bg-white ${
          isFilterOpen ? 'h-20' : 'h-10'
        } rounded-t-lg pt-2`}
      >
        <SortSection sortPaymentMethods={sortPaymentMethods} handleSort={handleSort} path={['name']} type='string'>
          Platební metoda
        </SortSection>
        <div className='flex lg:ml-16'>
          {isFilterOpen && (
            <Controller
              name='pm'
              control={control}
              render={({ field }) => {
                const onBlur = (): void => {
                  field.onBlur();
                  formHandleBlur(getValues());
                };
                const handleKeyDown = (event: { key: string }): void => {
                  if (event.key === 'Enter') {
                    formHandleBlur(getValues());
                  }
                };
                return <SortingTextfield {...field} onBlur={onBlur} onKeyDown={handleKeyDown} />;
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentMethodHeader;
