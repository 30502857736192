import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditButton from 'src/components/EditButton';
import RowButton from '../../../components/RowButton';
import UserFormCard from '../UserFormCard';
import Checkbox from 'src/components/Checkbox';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { useOldApiClient } from 'src/api';

interface PreviewUserFormProps {
  data?: { id: number; isDeleted: boolean; userEmail: string };
}

const UserEditForm: React.FC<PreviewUserFormProps> = ({ data }) => {
  const { userId } = useParams();
  const Client = useOldApiClient();
  const navigate = useNavigate();

  const form = useForm<{ isDeleted: boolean }>({
    defaultValues: {
      isDeleted: data?.isDeleted,
    },
  });

  const { handleSubmit, control } = form;

  const onSubmit: SubmitHandler<{ isDeleted: boolean }> = async (data) => {
    try {
      if (data.isDeleted) {
        const response = await Client.delete(`/user/${userId}`);
        if (response.status === 200) {
          navigate('/user');
        }
      } else {
        const response = await Client.put(`/user/${userId}/restore-user`);
        if (response.status === 200) {
          navigate('/user');
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='flex-col w-2/4 md:w-full md:pl-3 ml-4'>
      <div className='flex w-11/12'>
        <div className='grid grid-row-2 mb-4 w-full'>
          <div className='flex w-full'>
            <div className='mt-2 text-4xl text-text font-bold flex w-full'>Uživatel</div>
          </div>
        </div>
      </div>
      <FormProvider {...form}>
        <form className='w-11/12' onSubmit={handleSubmit(onSubmit)}>
          <UserFormCard>
            <div className='grid grid-cols-2 mb-2 mt-2'>
              <div className='font-bold text-text ml-4 mb-2'>Email</div>
              <div className='text-lightGrey'> {data?.userEmail} </div>
            </div>
            <div className='grid grid-cols-2 mb-2 mt-2'>
              <div className='font-bold text-text ml-4 mb-2'>Neaktivní uživatel</div>
              <div className='text-lightGrey'>
                <Controller control={control} name='isDeleted' render={({ field }) => <Checkbox {...field} />} />
              </div>
            </div>
          </UserFormCard>
          <div className='mt-5 grid place-items-end'>
            <div className='flex-row mr-4 mb-6'>
              <EditButton onClick={() => navigate('/user')}>Zavřít</EditButton>
              <RowButton type='submit'>Uložit změny</RowButton>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default UserEditForm;
