/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateOrderRequest } from '../models/CreateOrderRequest';
import type { OrderListItemResponse } from '../models/OrderListItemResponse';
import type { OrderResponse } from '../models/OrderResponse';
import type { UpdateOrderRequest } from '../models/UpdateOrderRequest';
import type { UpdateOrderStatusRequest } from '../models/UpdateOrderStatusRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrdersService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns OrderResponse
   * @throws ApiError
   */
  public getOrder({ id }: { id: number }): CancelablePromise<OrderResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/orders/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns OrderResponse
   * @throws ApiError
   */
  public updateData({
    id,
    requestBody,
  }: {
    id: number;
    requestBody: UpdateOrderRequest;
  }): CancelablePromise<OrderResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/orders/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns OrderListItemResponse
   * @throws ApiError
   */
  public getOrderList({
    statusProp,
    technicianProp,
    companyProp,
    withDeleted,
  }: {
    statusProp?: string;
    technicianProp?: string;
    companyProp?: string;
    /**
     * With deleted
     */
    withDeleted?: boolean;
  }): CancelablePromise<Array<OrderListItemResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/orders',
      query: {
        statusProp: statusProp,
        technicianProp: technicianProp,
        companyProp: companyProp,
        withDeleted: withDeleted,
      },
    });
  }

  /**
   * @returns OrderResponse
   * @throws ApiError
   */
  public create({ requestBody }: { requestBody: CreateOrderRequest }): CancelablePromise<OrderResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/orders',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public invoiceOrder({
    id,
    requestBody,
  }: {
    id: number;
    requestBody: UpdateOrderStatusRequest;
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/orders/{id}/actions/invoice',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public getPdfFile({ orderId }: { orderId: number }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/orders/{orderId}/service-list',
      path: {
        orderId: orderId,
      },
    });
  }
}
