import React from 'react';
import { useNavigate, useLocation } from 'react-router';
import { NotificationResponse } from 'src/api/types/notification/NotificationResponse';

interface ServiceUserProps {
  notification: NotificationResponse;
  isLastItem?: boolean;
}

const ServiceNotification: React.FC<ServiceUserProps> = ({ isLastItem, notification }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div>
      <div
        onClick={() => {
          navigate(`/notification/${notification.id}/preview`);
        }}
        key={notification.id}
        className={`h-12  flex bg-white  rounded-br-lg  rounded-bl-lg ${
          typeof notification.id === 'number' && pathname?.match(/\d+/g)?.join('') === notification.id.toString()
            ? 'bg-rose'
            : ''
        }`}
      >
        <div
          className={`text-text cursor-pointer text-center w-full truncate self-center pt-3 border-l border border-border h-12 border-b border-border pt-3 ${
            isLastItem ? 'rounded-bl-lg rounded-br-lg border-b' : ''
          }`}
        >
          {notification.text}
        </div>
      </div>
    </div>
  );
};

export default ServiceNotification;
