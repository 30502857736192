/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AuthService } from './services/AuthService';
import { BranchService } from './services/BranchService';
import { ContactService } from './services/ContactService';
import { EmployeeService } from './services/EmployeeService';
import { FileService } from './services/FileService';
import { LicencesService } from './services/LicencesService';
import { MaterialService } from './services/MaterialService';
import { NotificationSettingsService } from './services/NotificationSettingsService';
import { OrdersService } from './services/OrdersService';
import { ParametersService } from './services/ParametersService';
import { PaymentMethodService } from './services/PaymentMethodService';
import { ProductService } from './services/ProductService';
import { RoutineServicesService } from './services/RoutineServicesService';
import { StatusService } from './services/StatusService';
import { TechnicianService } from './services/TechnicianService';
import { TechnicianOrderService } from './services/TechnicianOrderService';
import { UserService } from './services/UserService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class AppClient {
  public readonly auth: AuthService;
  public readonly branch: BranchService;
  public readonly contact: ContactService;
  public readonly employee: EmployeeService;
  public readonly file: FileService;
  public readonly licences: LicencesService;
  public readonly material: MaterialService;
  public readonly notificationSettings: NotificationSettingsService;
  public readonly orders: OrdersService;
  public readonly parameters: ParametersService;
  public readonly paymentMethod: PaymentMethodService;
  public readonly product: ProductService;
  public readonly routineServices: RoutineServicesService;
  public readonly status: StatusService;
  public readonly technician: TechnicianService;
  public readonly technicianOrder: TechnicianOrderService;
  public readonly user: UserService;

  public readonly request: BaseHttpRequest;

  constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? '',
      VERSION: config?.VERSION ?? '1',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.auth = new AuthService(this.request);
    this.branch = new BranchService(this.request);
    this.contact = new ContactService(this.request);
    this.employee = new EmployeeService(this.request);
    this.file = new FileService(this.request);
    this.licences = new LicencesService(this.request);
    this.material = new MaterialService(this.request);
    this.notificationSettings = new NotificationSettingsService(this.request);
    this.orders = new OrdersService(this.request);
    this.parameters = new ParametersService(this.request);
    this.paymentMethod = new PaymentMethodService(this.request);
    this.product = new ProductService(this.request);
    this.routineServices = new RoutineServicesService(this.request);
    this.status = new StatusService(this.request);
    this.technician = new TechnicianService(this.request);
    this.technicianOrder = new TechnicianOrderService(this.request);
    this.user = new UserService(this.request);
  }
}
