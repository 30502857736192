import React from 'react';
import { EmployeeResponse } from 'src/api/types/employee/EmployeeResponse';
import ServiceEmployee from 'src/components/ServiceEmployee';

type EmployeesProps = {
  employees: EmployeeResponse[];
};

const EmployeesCard: React.FC<EmployeesProps> = ({ employees }) => {
  return (
    <>
      {employees.map((employee, index) => (
        <ServiceEmployee key={employee.id} employee={employee} isLastItem={employees.length - 1 === index} />
      ))}
    </>
  );
};

export default EmployeesCard;
