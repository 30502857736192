import { pickAll } from 'ramda';
import { useCallback, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useOldApiClient } from 'src/api';
import { ContactResponse } from 'src/api/types/contact/ContactResponse';
import { useBranches } from 'src/store/branchesContext';
import { useEmployees } from 'src/store/employeesContext';
import { ServiceFormValues } from './schema';

export const ContactWatcher: React.FC = () => {
  const Client = useOldApiClient();
  const { setValue, control } = useFormContext<ServiceFormValues>();
  const { setBranches } = useBranches();
  const { setEmployees } = useEmployees();
  const [companyId] = useWatch({ name: ['companyId', 'company.vat'], control });

  const getFillBranchData = async (companyId: number): Promise<void> => {
    const fillBranchData = await Client.get(`/contact/${companyId}/branch`);
    setBranches(fillBranchData.data);
  };

  const getFillEmployeeData = async (companyId: number): Promise<void> => {
    const fillEmployeeData = await Client.get(`/contact/${companyId}/employee`);
    setEmployees(fillEmployeeData.data);
  };

  const getContactDetail = async (companyId: number): Promise<ContactResponse> => {
    const { data } = await Client.get<ContactResponse>(`/contact/${companyId}`);
    return data;
  };

  const setContactInfo = useCallback(
    async (contact?: ContactResponse) => {
      const fields = [
        'companyName',
        'email',
        'street',
        'vat',
        'zip',
        'city',
        'phoneNumber',
        'in',
        'notification',
      ] as const;

      const obj = pickAll<ContactResponse, Pick<ContactResponse, typeof fields[number]>>(
        fields,
        contact ?? ({} as ContactResponse),
      );

      Object.keys(obj).forEach((key) => {
        const _key = key as keyof typeof obj;
        setValue(`company.${_key}`, obj[_key]);
      });
    },
    [setValue],
  );

  const getAndSetContact = async (companyId: number): Promise<void> => {
    const data = await getContactDetail(companyId);
    setContactInfo(data);
  };

  useEffect(() => {
    if (companyId != null) {
      getAndSetContact(companyId);
      getFillBranchData(companyId);
      getFillEmployeeData(companyId);
    } else {
      setContactInfo();
      setBranches([]);
      setEmployees([]);
    }
  }, [companyId]);

  return null;
};
