import React, { useContext } from 'react';
import { SelectContextType, SelectOption } from '../types';

const SelectContext = React.createContext<SelectContextType | null>(null);

export const SelectContextProvider = <Option extends SelectOption, ReturnValue>({
  children,
  ...rest
}: SelectContextType<Option, ReturnValue> & { children?: React.ReactNode }): React.ReactElement => (
  <SelectContext.Provider value={rest as SelectContextType}>{children}</SelectContext.Provider>
);

export const useSelectContext = <
  Option extends SelectOption = SelectOption,
  ReturnValue = unknown,
>(): SelectContextType<Option, ReturnValue> => {
  const ctx = useContext(SelectContext);
  if (!ctx) throw new Error('Missing Select context provider');
  return ctx as SelectContextType<Option, ReturnValue>;
};
