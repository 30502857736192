import { clone } from 'ramda';
import React, { useState } from 'react';
import { useLocation, Route, Routes, Outlet, Navigate } from 'react-router-dom';
import { NotificationResponse } from 'src/api';
import { useNotifications } from 'src/api/hooks/useNotificationSettings';
import NotificationFetch from 'src/components/NotificationFetch';
import { Modules, useLicenses } from 'src/store/licenseContext';
import { StatusProvider } from 'src/store/statusContext';
import { sortArrayOfObjects } from 'src/utils/sortArrayOfObjects';
import NotificationCard from './NotificationCard';
import NotificationHeader from './NotificationHeader';

const getHeightOfFormByPathname = (pathname: string): string => {
  if (pathname.endsWith('/new') || pathname.endsWith('/edit')) {
    return 'lg:h-[calc(100vh-8.5rem)] ntb:h-[calc(100vh-8.8rem)]';
  }
  if (pathname.endsWith('/preview')) {
    return 'lg:h-[calc(100vh-8.5rem)] ntb:h-[calc(100vh-8.8rem)]';
  }
  return 'lg:h-[calc(100vh-8.8rem)] ntb:h-[calc(100vh-8.8rem)]';
};

export type SortType = {
  path: string[];
  direction: 'asc' | 'desc';
  type: 'number' | 'string';
};

const NotificationPage: React.FC = () => {
  const { pathname } = useLocation();
  const { getIsModuleValid } = useLicenses();
  const [currentSort, setCurrentSort] = useState<SortType>({ path: ['id'], type: 'number', direction: 'desc' });

  const { data } = useNotifications();

  const sortedItems = sortArrayOfObjects(clone(data ?? []), currentSort.path, currentSort.direction, currentSort.type);

  const handleSort = (newItems: NotificationResponse[], newSortObj: SortType): void => {
    setCurrentSort(newSortObj);
  };

  return (
    <>
      <StatusProvider>
        {!getIsModuleValid(Modules.SERVICES) && <Navigate to='/user' />}
        <div className='flex lg:w-full ntb:w-full md:flex-col md:w-full'>
          <div className='flex-col w-2/4 md:w-full'>
            <div className='flex justify-between'>
              <div className='flex items-baseline'>
                <div className='ml-6 mt-2 text-4xl text-text font-bold flex mr-4 '>Notifikace</div>
              </div>
            </div>
            <div className='lg:ml-6 lg:mt-4 ntb:ml-6 ntb:mt-4 ntb:ml-6 ntb:mt-4 md:w-full md:p-3'>
              <div className='h-full'>
                <NotificationHeader sortItems={sortedItems} handleSort={handleSort} />
                <div className={`${getHeightOfFormByPathname(pathname)} md:h-[30rem] overflow-y-scroll rounded-b-lg`}>
                  <NotificationCard notifications={sortedItems} />
                </div>
              </div>
            </div>
          </div>

          <Outlet />

          <Routes>
            <Route path='/:notificationId/*' element={<NotificationFetch />} />
          </Routes>
        </div>
      </StatusProvider>
    </>
  );
};

export default NotificationPage;
