import { UpdateTechnicianRequest } from 'src/api/types/technician/UpdateTechnicianRequest';
import { emptyToNull } from 'src/utils/schemaUtils';
import { object, SchemaOf, string } from 'yup';

export type UpdateTechnicianFormValues = UpdateTechnicianRequest;

const UpdateTechnicianFormSchema: SchemaOf<UpdateTechnicianFormValues> = object().shape({
  technicianName: string().defined().default(null).transform(emptyToNull),
});

export default UpdateTechnicianFormSchema;
