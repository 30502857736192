import React from 'react';
import { ProductServiceHistory } from 'src/api';
import ServiceHistoryProducts from 'src/components/ServiceHistoryProducts';

type HistoryServiceProductProps = {
  historyServices: ProductServiceHistory[];
};

const HistoryServiceProductCard: React.FC<HistoryServiceProductProps> = ({ historyServices }) => {
  return (
    <>
      {historyServices.map((historyService, index) => (
        <ServiceHistoryProducts
          key={historyService.serviceFileId}
          historyServices={historyService}
          isLastItem={historyServices.length - 1 === index}
        />
      ))}
    </>
  );
};

export default HistoryServiceProductCard;
