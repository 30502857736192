import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import CameraIcon from '../Icons/CameraIcon';
import PhotoButton from '../PhotoButton';

type UploaderProps = {
  onUpload: (file: File) => Promise<void>;
  onUploaderStateChange: (isUploading: boolean) => void;
};

const Uploader: React.FC<UploaderProps> = ({ onUpload, onUploaderStateChange }) => {
  const [isUploading, setIsUploading] = useState(false);

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const uploadingFiles = useRef<Set<File>>(new Set());

  const uploadHandler = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (!event.target.files) {
      return;
    }

    Object.values(event.target.files).forEach(async (file) => {
      setIsUploading(true);
      uploadingFiles.current.add(file);
      try {
        await onUpload(file);
      } finally {
        uploadingFiles.current.delete(file);
        if (uploadingFiles.current.size === 0) {
          setIsUploading(false);
        }
      }
    });
    event.currentTarget.value = '';
  };

  const handleUploadPhotoClick = (): void => {
    hiddenFileInput?.current?.click();
  };

  useEffect(() => {
    onUploaderStateChange(isUploading);
  }, [isUploading]);

  return (
    <>
      <input
        ref={hiddenFileInput}
        accept='.png, .jpg, .jpeg'
        type='file'
        className='hidden'
        onChange={uploadHandler}
        multiple
      />
      <PhotoButton type='button' onClick={() => handleUploadPhotoClick()}>
        <div className='flex justify-center items-center'>
          <div className='mr-2'> Nahrát fotku </div>
          <CameraIcon />
        </div>
      </PhotoButton>
    </>
  );
};

Uploader.displayName = 'Uploader';

export default Uploader;
