import React from 'react';
import { FieldProps } from 'src/types/commonTypes';

export type CashFieldProps = FieldProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement, number | null>;

const CashField: React.ForwardRefRenderFunction<HTMLInputElement, CashFieldProps> = (props, ref) => {
  const { value, error, ...rest } = props;

  return (
    <div className='flex flex-col'>
      <input
        className='h-9 rounded-md border-2 border-border md:ml-2 pl-2'
        value={
          value
            ?.toLocaleString()
            .replace(/\s/g, '')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') ?? ''
        }
        {...rest}
        ref={ref}
      ></input>
      {error && (
        <p>
          <span className='text-primary'>{error}</span>
        </p>
      )}
    </div>
  );
};

export default React.forwardRef(CashField);
