import { CreateEmployeeRequest } from 'src/api/types/employee/CreateEmployeeRequest';
import { inputRequired } from 'src/validation/validationMessages';
import { object, SchemaOf, string } from 'yup';

export type CreateEmployeeFormValues = CreateEmployeeRequest;

const CreateEmployeeFromSchema: SchemaOf<CreateEmployeeFormValues> = object().shape({
  name: string().required(inputRequired),
  email: string().email().required(inputRequired),
  phoneNumber: string().required(inputRequired),
});

export default CreateEmployeeFromSchema;
